import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import simulatorService from '~/services/simulator-v2/simulator.service'

export default function useProject(id: string) {
  return useQuery({
    queryKey: queryKeys.PROJECT(id),

    queryFn: async () => {
      const response = await simulatorService.project(id)
      return response.data
    },
  })
}
