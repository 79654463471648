<script setup lang="ts">
const props = defineProps<{
  solfacilData: {
    document: string
    corporate_name: string
    municipal_registration: string
    main_activity: string
    address: {
      zipcode: string
      street: string
      number: string
      complement: string
      neighborhood: string
      city: string
      uf: string
    }
    email: string
  }
  commissionValue: number
}>()
const { t } = useI18n()
const { formatMoney } = useMoney()
function formattedAdress() {
  return `${props.solfacilData.address.street}, ${props.solfacilData.address.number} - ${props.solfacilData.address.complement} - CEP: ${props.solfacilData.address.zipcode} - Bairro ${props.solfacilData.address.neighborhood}, ${props.solfacilData.address.city}, ${props.solfacilData.address.uf}`
}
</script>

<template>
  <div class="bg-neutral-high-light p-4 mt-6 rounded-md">
    <div class="mb-6">
      <h4 class="text-2xs text-neutral-low-pure font-highlight">
        {{ t('receiptModel.infoSolfacil.title') }}
      </h4>
      <p class="text-3xs text-neutral-low-light !m-0">
        {{ t('receiptModel.infoSolfacil.subtitle') }}
      </p>
    </div>
    <div class="flex gap-4 flex-col">
      <div class="flex flex-col md:site:flex-row gap-2 md:site:gap-4">
        <div>
          <small class="block text-micro text-neutral-low-light">{{ t('receiptModel.infoSolfacil.labelValue') }}</small>
          <span class="text-3xs text-neutral-low-pure break-words">{{ formatMoney(props.commissionValue) }}</span>
        </div>
        <div>
          <small class="block text-micro text-neutral-low-light">{{ t('receiptModel.infoSolfacil.labelCnpj') }}</small>
          <span class="text-3xs text-neutral-low-pure break-words">{{ props.solfacilData.document }}</span>
        </div>
        <div>
          <small class="block text-micro text-neutral-low-light">{{ t('receiptModel.infoSolfacil.labelCorporateReason')
          }}</small>
          <span class="text-3xs text-neutral-low-pure break-words">{{ props.solfacilData.corporate_name }}</span>
        </div>
      </div>
      <div class="flex flex-col md:site:flex-row gap-2 md:site:gap-4">
        <div>
          <small class="block text-micro text-neutral-low-light">{{
            t('receiptModel.infoSolfacil.labelMunicipalRegistration') }}</small>
          <span class="text-3xs text-neutral-low-pure break-words">{{ props.solfacilData.municipal_registration
          }}</span>
        </div>
        <div>
          <small class="block text-micro text-neutral-low-light">{{ t('receiptModel.infoSolfacil.labelMainActivity')
          }}</small>
          <span class="text-3xs text-neutral-low-pure break-words">{{ props.solfacilData.main_activity }}</span>
        </div>
      </div>
      <div class="flex flex-col md:site:flex-row gap-2 md:site:gap-4">
        <div>
          <small class="block text-micro text-neutral-low-light">{{ t('receiptModel.infoSolfacil.labelAddress')
          }}</small>
          <span class="text-3xs text-neutral-low-pure break-words">{{ formattedAdress() }}</span>
        </div>
        <div>
          <small class="block text-micro text-neutral-low-light">{{ t('receiptModel.infoSolfacil.labelEmail') }}</small>
          <span class="text-3xs text-neutral-low-pure break-words">{{ props.solfacilData.email }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
