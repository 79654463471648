export default {

  /**
   *  Format a number
   *
   * @param value - The number to be formatted
   * @returns {string} percentage
   */
  percentage: (value: number | string): string => {
    return Number(value).toFixed(2).replace('.', ',')
  },
}
