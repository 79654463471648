app:
  name: Financing
  next: Next
  advance: Advance
  advance_and_update: Update and advance
  share: Share
  whatsapp: WhatsApp
  send: Send
  save: Save
header:
  welcome: Welcome
  exit: Exit
  bnpl:
    steps:
      simulation: Simulation
      formalization: Formalization
      external: External
    status:
      IN_PROGRESS:
        type: warning
        text: In progress
      EXPIRED:
        type: neutral
        text: Expired
      DONE:
        type: positive
        text: Done
simulation:
  next: View installments
  load_again: Load again
  founded: Simulations found
  golist: Go to list
  list: Simulations
  subtitles: Access your saved simulations
  new: New simulation
  project_type: Financing lines
  client_data: Client data
  cpf_use_terms: >-
    I declare that my client has authorized the consultation of their CPF or
    CNPJ in credit protection agencies.
  continue_with_value: Continue with approved amount
  financing_options: Financing options
  details:
    info_title: Simulation data for visualization purposes only.
    info_description: >-
      You will be able to review your simulation and change data during the
      contract signing stage.
  conclude: Conclude simulation
  info_risk:
    essential_profile_title: The client was approved in the simulation
    essential_profile_description: >-
      Attention! Project approved for credit, but in order to formalize this new
      project, it is necessary to prove the installation of other projects
      already formalized
  bnpl:
    solfacilInstallment: Solfacil Installment
    identity: id
    installment_title: |-
      Installment |
      Installments
    conditions_installment: >-
      Check out our options for the one that best fits your client's profile. It
      is possible to simulate the value of the project kit, and define it in the
      next stage, formalization.
    project_value: Project value
    due_date: Maturity
    invoice_due_date: Invoice due date
    kit_value_helper: Updated after kit selection
    kit_value: Kit value
    number_order: N° order
    service_value_helper: Project value minus kit and fee
    service_value: Service value
    installment_amount: Installment value
    initial_amount: Prohibited
    receive_amount: Will receive
    customer_pays_fee: Installment with fixed service
    store_pays_fee: Installment with fixed project
    customer_pays_fee_description: You pass on the fee to your client, increasing the value of the project.
    store_pays_fee_description: You pay the fee, maintaining the value of the project.
    id:
      summary:
        title:
          summary: Summary
          service: Fixed service
        installment:
          title: Installment
          prohibited: Prohibited
          cet: CET
          am: a.m.
        enterprise:
          title: For your company
          total: Total project value
          kit: Store Kit
          tax: Advance fee
          value: Amount to receive
        client:
          title: For your client
          total: Total project value
          prohibited: Prohibited
          installments: Installments
          of: of
  modal:
    title: New formalization platform!
    altImg: Funding Screen
    info:
      proposal:
        title: Send your proposal in up to 2 minutes!
        description: Fill out everything at once and wait for our analysis.
      projects:
        title: Make your projects whenever and wherever you want!
        description: 'Cell phone, computer or tablet: you choose how and where.'
      payments:
        title: Real-time payments
        description: After approval, payments 24 hours a day, 7 days a week.
    button:
      old: Continue with the old platform
      new: Continue with the new platform!
  notFound:
    title: Simulation not found
    subtitle: >-
      It seems that the simulation you are looking for does not exist. Please
      check the data and try again.
  errorProject:
    title: Error to load list
    subtitle: >-
      It seems that there was an error loading the list of simulations. Please
      try again later.
  status_filter:
    done: Approved
    pending: Pending
    reproved: Reproved
revision:
  title: Simulation review
  subtitle: >-
    Review and change all simulation data, including values, as long as they
    comply with our policies.
  subtitle_complement: Otherwise, it will be necessary to perform a new simulation.
buttons:
  back: Back
loading:
  messages: >-
    We're almost there, harnessing solar energy to power our calculations.
    Sunbeams are arriving, charging with the strength of the sun.
list:
  days: '- | {count} day | {count} days'
installments:
  with_combo: Installments with Easy Combo
  with_combo_description: >-
    The cheapest installment for your customer. The kit must be purchased at the
    Solfácil Store.
  with_combo_comission: Installments with Easy Combo and commission
  with_combo_comission_description: >-
    Best value for money for you and your client. The kit must be purchased at
    the Solfácil Store.
  without_combo: Installments without Easy Combo
  without_combo_description: >-
    Most expensive option for your client. The kit can be purchased outside the
    Solfácil Store.
form:
  alert_required_fields: Please fill in all required fields.
  file_required: File submission required.
  invalid_file_type: |
    Invalid file type. Accepted file types are: {types}
  required: Required field
  error: Registration not completed
customer:
  header:
    message:
      error: An error occurred while obtaining the formalization status.
solfacilPoints:
  label: Pontos Solfácil Mais
  phrases:
    one: 'How about adding to your financing:'
    two: Let's do the project better? How about adding
    three: Good project! How about adding {string}? Very good!
    four: Formalizing this project will bring you many benefits! Very good!
toastKitSelected:
  title: Selected Kit
  description: >-
    If available at the time of formalization, the kit will be invoiced
    automatically for your convenience.
receiptModel:
  tags:
    inAnalysis: In analysis
    approved: Approved
    denied: Rejected
    inProgress: In progress
    pendingChange: Pending invoice
    selected: Selected
    transferInProgress: Transfer in progress
subscriptionEnergy:
  title: Your customer can still have solar energy!
  subtitle: >-
    Refer your customer to have solar energy by subscription with up to 20% off
    on the electricity bill! And earn a commission if they accept!
  recommend_customer: Refer customer
  alt_img: Image Subscription Solar Energy
  title_img: Subscription Solar Energy
project_data:
  status:
    pending: Pending
    selected: Selected
    in_progress: In progress
  errors:
    string_overload_max: >-
      Excessive power in the selected inverter(s). Reduce the quantity or choose
      lower power models.
    string_overload_min: >-
      Insufficient power in the selected inverter(s). Add another inverter or
      choose higher power models.
    microinverter_overload_max: >-
      Excessive power in the selected microinverters. Increase the quantity or
      choose lower power models.
    microinverter_overload_min: >-
      Insufficient power in the selected microinverters. Reduce the quantity of
      microinverters in the project or choose a higher power model.
    modules_overload: >-
      The selected modules do not seem to fit the project specifications. Please
      review the quantity or power of the modules.
financing:
  boxSearch:
    placeholder: Search by Name, CPF/CNPJ or ID
  filters:
    clear: |-
      Clear filter |
      Clear filters
    title: 'Filter projects by:'
    steps: Steps
    status: Status
  no_data: No data found
  notFound:
    subtitle: >-
      Search for another Name, CPF, CNPJ or project ID to find the project you
      are looking for.

      If it is correct, you can search for the project in the old list.
  list:
    step:
      canceled: Canceled
      concluded: Completed
      expired: Expired
      formalization: Formalization
      installation: Installation
      simulation: Simulation
    status:
      canceled: Canceled
      concluded: Completed
      expired: Expired
      kit_pending: Pendant kit
      pendency: Pending
      pending: In progress
      pending_customer: Pending customer
      pending_receipt: pending invoice
      reproved: Failed
      send_contract: Send contract
      under_analysis: Under review
