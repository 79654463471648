import type { FormalizationStatus } from './financing-flow/Financing'

export default {
  formalizationIsApproved(data: FormalizationStatus) {
    return data.registration === 'approved'
    && data.documentation === 'approved'
    && data.hardware === 'submitted'
    && (data.receipt_model === 'submitted' || data.receipt_model === 'approved')
    && data.contract === 'approved'
  },
}
