interface IStoreMarketFinancing {
  power: string
  region?: string
}

export default {
  /**
   *  Create an object to send to the store market financing
   *
   * @param power - The power of project
   * @param region - The region of project
   * @returns {string} Object with power or/and region
   */
  createObject: (power: string, region?: string): IStoreMarketFinancing => {
    const object: IStoreMarketFinancing = {
      power,
    }

    if (region)
      object.region = region

    return object
  },
}
