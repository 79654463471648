import { defineStore } from 'pinia'

export const useLevelBlackStore = defineStore('level-black', () => {
  const isLevelBlack = ref(false)

  function setLevelBlack(level: boolean) {
    isLevelBlack.value = level
  }

  return { isLevelBlack, setLevelBlack }
})
