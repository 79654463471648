<script setup lang="ts">
import { useField, useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import * as zod from 'zod'
import { useToast } from '@solfacil/girassol'
import JSZip from 'jszip'
import type { FileStatusType, ResponseInstallation, SectionDocument, StatusInstallation } from '~/utils/installation/Installation'
import InstallationService from '~/services/installation/Installation'
import DownloadIcon from '~icons/material-symbols/download-rounded'
import IOpenInNew from '~icons/material-symbols/open-in-new'
import FinancingFlow from '~/services/financingFlow/FinancingFlow'
import type { FinancingFlowResponse } from '~/utils/financing-flow/Financing'

const installationService = new InstallationService(useApi('installation'))
const { t } = useI18n()
const loading = ref(false)
const installationData = ref({} as ResponseInstallation)
const statusInstallation = ref<StatusInstallation>({})
const router = useRouter()
const { track } = useMixpanel()

const { createErrorToast } = useToast()

const id = String(router.currentRoute.value.params.id)
const financingFlow = new FinancingFlow(useApi('financingFlow'))
const financing = ref({} as FinancingFlowResponse)

onUpdated(() => {
  setErrorForm()
})

onMounted(async () => {
  await initialInstallation()
  track('installation_page_view', { trigger: 'Ver página de instalação do equipamento' })

  financing.value = await financingFlow.getFinancingFlow(id)
})

const initialValues = {
  uploadInverters: null,
  uploadInstallation: null,
  uploadAmpera: null,
  additionalComments: '',
}

const MAX_FILE_SIZE = 20 * 1024 * 1024 // 20MB

const isStatusWaiting = computed(() => installationData?.value?.status?.slug === 'waiting')
const isStatusSuccess = computed(() => installationData?.value?.status?.slug === 'success')
const isStatusError = computed(() => installationData?.value?.status?.slug === 'error')
const isStatusProcessing = computed(() => installationData?.value?.status?.slug === 'processing')

const sectionLabels = {
  installation: t('installationPage.form.installationField.label'),
  inverters: t('installationPage.form.invertersField.label'),
  ampera: t('installationPage.form.installedField.label'),
}
const errorMessages = computed(() => {
  if (!installationData.value?.sections)
    return []

  return installationData.value.sections
    .filter(section => section.status.slug === 'reproved' && section.status.description)
    .map(section => ({ type: sectionLabels[section.type], description: section.status.description }))
})

function validateInputFile(field: any, type: string) {
  const filteredSections = installationData.value?.sections?.filter(section => (section.type === type && section?.status?.slug !== 'reproved'))

  if (!isStatusWaiting.value)
    return !!filteredSections?.length || (field !== null && field?.length > 0)
  else
    return (field !== null && field?.length > 0)
}

function validateSize(field: any, type: string) {
  const filteredSections = installationData.value?.sections?.filter(section => (section.type === type && section?.status?.slug !== 'reproved'))
  const sizeIsValid = (field !== null && field?.every((file: { size: number }) => file?.size > 0 && file?.size <= MAX_FILE_SIZE))

  if (!isStatusWaiting.value)
    return !!filteredSections?.length || sizeIsValid
  else
    return sizeIsValid
}

const validationSchema = computed(() => {
  if (installationData?.value?.has_ampera) {
    return toTypedSchema(
      zod.object({
        uploadInstallation: zod
          .any()
          .refine(uploadInstallation => validateInputFile(uploadInstallation, 'installation'), 'É necessário anexar um arquivo.')
          .refine(uploadInstallation => validateSize(uploadInstallation, 'installation'), 'Todos os arquivos devem ter tamanho entre 1B e 20MB.'),
        uploadInverters: zod
          .any()
          .refine(uploadInverters => validateInputFile(uploadInverters, 'inverters'), 'É necessário anexar um arquivo.')
          .refine(uploadInverters => validateSize(uploadInverters, 'inverters'), 'Todos os arquivos devem ter tamanho entre 1B e 20MB.'),
        uploadAmpera: zod
          .any()
          .refine(uploadAmpera => validateInputFile(uploadAmpera, 'ampera'), 'É necessário anexar um arquivo.')
          .refine(uploadAmpera => validateSize(uploadAmpera, 'ampera'), 'Todos os arquivos devem ter tamanho entre 1B e 20MB.'),
        additionalComments: zod.string().optional(),
      }),
    )
  }
  else {
    return toTypedSchema(
      zod.object({
        uploadInstallation: zod
          .any()
          .refine(uploadInstallation => validateInputFile(uploadInstallation, 'installation'), 'É necessário anexar um arquivo.')
          .refine(uploadInstallation => validateSize(uploadInstallation, 'installation'), 'Todos os arquivos devem ter tamanho entre 1B e 20MB.'),
        uploadInverters: zod
          .any()
          .refine(uploadInverters => validateInputFile(uploadInverters, 'inverters'), 'É necessário anexar um arquivo.')
          .refine(uploadInverters => validateSize(uploadInverters, 'inverters'), 'Todos os arquivos devem ter tamanho entre 1B e 2MB.'),
        additionalComments: zod.string().optional(),
      }),
    )
  }
})

const { handleSubmit, validate, setFieldError, setFieldValue } = useForm({
  validationSchema,
  initialValues,
})

const showEmptyState = ref(false)

async function initialInstallation() {
  try {
    loading.value = true

    const response = await installationService.get_installation_hardware(id)
    if (response.data)
      installationData.value = response.data

    loading.value = false
    showEmptyState.value = false

    if (installationData.value.comment && installationData.value.comment !== 'undefined')
      setFieldAdditionalComments()
  }
  catch {
    showEmptyState.value = true
    loading.value = false
  }

  if (installationData.value && !loading.value && !showEmptyState.value) {
    const aboutInstallation = {
      has_ampera: installationData.value.has_ampera,
      has_ampera_activated: installationData.value.has_ampera_activated,
      has_minimal_projects: installationData.value.has_minimal_projects,
    }
    statusInstallation.value = formattingInstallationInformations(
      installationData?.value?.status?.slug,
      installationData.value?.concluded_at,
      aboutInstallation,
    )
  }
}

function setErrorForm() {
  if (installationData?.value?.sections?.length) {
    for (let section = 0; section < installationData.value.sections.length; section++) {
      if (installationData.value?.sections[section]?.status?.slug === 'reproved') {
        switch (installationData.value.sections[section].type) {
          case 'installation':
            setFieldError('uploadInstallation', installationData.value.sections[section].status.description)
            break
          case 'inverters':
            setFieldError('uploadInverters', installationData.value.sections[section].status.description)
            break
          case 'ampera':
            setFieldError('uploadAmpera' as any, installationData.value.sections[section].status.description)
            break
        }
      }
    }
  }
}

function setFieldAdditionalComments() {
  setFieldValue('additionalComments', installationData.value.comment)
}

function formattingInstallationInformations(status: FileStatusType, date: string, aboutInstallation: { has_ampera: boolean; has_ampera_activated: boolean; has_minimal_projects: boolean }) {
  let nameStatus = ''

  if (status !== 'waiting') { nameStatus = status }
  else {
    if (aboutInstallation.has_ampera && !aboutInstallation.has_minimal_projects && !aboutInstallation.has_ampera_activated)
      nameStatus = 'waitingMultiples'

    else if (aboutInstallation.has_ampera && aboutInstallation.has_minimal_projects && !aboutInstallation.has_ampera_activated)
      nameStatus = 'waiting'

    else if (!aboutInstallation.has_ampera && !aboutInstallation.has_minimal_projects && !aboutInstallation.has_ampera_activated)
      nameStatus = 'waitingWihtoutAmperaMultiples'

    else if (!aboutInstallation.has_ampera && aboutInstallation.has_minimal_projects && !aboutInstallation.has_ampera_activated)
      nameStatus = 'waitingWihtoutAmpera'

    else if (aboutInstallation.has_ampera_activated)
      nameStatus = 'waitingAmperaActivated'
  }

  const OPTIONS = {
    [nameStatus]: {
      img: {
        name: t(`installationPage.${nameStatus}.img.name`),
        alt: t(`installationPage.${nameStatus}.img.alt`),
        title: t(`installationPage.${nameStatus}.img.title`),
      },
      title: t(`installationPage.${nameStatus}.title`),
      subtitle: nameStatus !== 'success' ? t(`installationPage.${nameStatus}.subtitle`) : formatSubtitle(nameStatus, date),
    },
  }

  return OPTIONS[nameStatus]
}

function getInstallationCompletionDate(date: string) {
  const dateObject = new Date(date)

  const day = dateObject.getDate().toString().padStart(2, '0')
  const month = (dateObject.getMonth() + 1).toString().padStart(2, '0')
  const year = dateObject.getFullYear()

  const formattedDate = `${day}/${month}/${year}`

  return formattedDate
}

function getInstallationCompletionHours(date: string) {
  const dateObject = new Date(date)

  const hours = dateObject.getHours().toString().padStart(2, '0')
  const minutes = dateObject.getMinutes().toString().padStart(2, '0')

  const formattedTime = `${hours}h${minutes}`

  return formattedTime
}

function formatSubtitle(status: FileStatusType, date: string) {
  if (!date)
    return

  const getDate = getInstallationCompletionDate(date)
  const getHour = getInstallationCompletionHours(date)

  return t(`installationPage.${status}.subtitle`, { date: getDate, hour: getHour })
}

function openHelp() {
  track('installation_page_button_help', { trigger: 'Clique no botão sobre dúvidas na ativação' })

  window.open('https://www.youtube.com/watch?v=sw3iOeBRbcs', '_blank')
}

const loadingSendAnalysis = ref(false)

const sendAnalysis = handleSubmit(async (event: any) => {
  loadingSendAnalysis.value = true
  track('installation_page_button_send_analysis', { trigger: 'Clique no botão de enviar para análise' })

  const formData = new FormData()

  event.uploadAmpera?.forEach((file: File) => {
    formData.append('files_ampera', file, file.name)
  })

  event.uploadInstallation?.forEach((file: File) => {
    formData.append('files_installation', file, file.name)
  })

  event.uploadInverters?.forEach((file: File) => {
    formData.append('files_inverters', file, file.name)
  })

  const { valid } = await validate()

  if (valid) {
    try {
      const response = await installationService.post_send_installation_hardware(installationData.value.id, formData, event.additionalComments)
      if (response.data)
        await initialInstallation()

      loadingSendAnalysis.value = false
    }
    catch {
      loadingSendAnalysis.value = false
      createErrorToast('Não foi possível concluir o processamento e envio dos arquivos. Por favor, tente novamente.')
    }
    finally {
      loadingSendAnalysis.value = false
    }
  }
})

const openForm = ref(false)

function followManuallyOpenForm() {
  track('installation_page_button_follow_manually', { trigger: 'Clique no botão de seguir manualmente' })

  openForm.value = true
}

function getFileTypeIcon(filetype: string) {
  return filetype.split('.')[1]
}

function getSectionData(section: SectionDocument) {
  const documents = section.documents
  return {
    'file-type-icon': documents.map((doc) => {
      return getFileTypeIcon(doc.filename)
    }),
    'file-name': documents.map(doc => doc.filename),
    'status-type-document': section.status?.slug,
  }
}

function getSectionsByType(type: string) {
  return installationData?.value?.sections?.filter(section => section.type === type)
}

async function addFileToZip(zip, file) {
  try {
    const response = await fetch(file.url)
    if (!response.ok) {
      createErrorToast('Não foi possível baixar os arquivos. Por favor, tente novamente.')
      return
    }
    const blob = await response.blob()
    zip.file(file.filename, blob)
  }
  catch {
    createErrorToast('Não foi possível baixar os arquivos. Por favor, tente novamente.')
  }
}

async function downloadFiles() {
  const sections = installationData?.value?.sections
  if (!sections) {
    createErrorToast('Não foi possível baixar os arquivos. Por favor, tente novamente.')
    return
  }

  const files = sections.map(section => section.documents).flat()
  const zip = new JSZip()
  for (const file of files)
    await addFileToZip(zip, file)

  const content = await zip.generateAsync({ type: 'blob' })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(content)
  link.download = 'instalacao.zip'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

function redirectFormalization() {
  if (financing.value.person_type === 'PF')
    return router.push(`/customer/${id}`)

  return router.push(`/company/${id}`)
}

function monitorAmpera() {
  const url = `https://plataforma-ampera.solfacil.com.br/usinas/detalhes/${installationData.value.plant_id}`
  window.open(url, '_blank')
}

const installationReproved = computed(() => Boolean(installationData?.value?.sections?.filter(section => (section.type === 'installation' && section?.status?.slug === 'reproved'))?.length))
const invertersReproved = computed(() => Boolean(installationData?.value?.sections?.filter(section => (section.type === 'inverters' && section?.status?.slug === 'reproved'))?.length))
const amperaReproved = computed(() => Boolean(installationData?.value?.sections?.filter(section => (section.type === 'ampera' && section?.status?.slug === 'reproved'))?.length))
</script>

<template>
  <main>
    <NavigationHeaderBar v-if="!loading" stage="installation" :title="installationData.project_name" :status="installationData?.status?.slug" />
    <div class="installation-page">
      <div v-if="!showEmptyState" class="container">
        <div v-if="loading">
          <div class="loader-container">
            <span class="loader-project" />
          </div>
        </div>
        <div
          v-else
          class="status w-full"
        >
          <InstallationStepMessage
            v-if="!loading"
            :status-installation="statusInstallation"
          />

          <div
            v-if="!isStatusSuccess"
            class="buttons"
          >
            <SolButton
              v-if="installationData.has_ampera"
              id="helpButton"
              :variant="installationData.has_minimal_projects && !installationData.has_ampera_activated ? 'tertiary' : 'secondary'"
              size="large"
              @click="openHelp()"
            >
              {{ t('installationPage.helpButton') }}
              <template #icon:right>
                <IOpenInNew class="icon" />
              </template>
            </SolButton>

            <SolButton
              v-if="installationData.has_ampera && installationData.has_minimal_projects && !isStatusSuccess"
              id="manualButton"
              variant="secondary"
              size="large"
              :disabled="(installationData.has_ampera && installationData.has_minimal_projects && !isStatusWaiting) || openForm"
              @click="followManuallyOpenForm"
            >
              {{ t('installationPage.manualButton') }}
            </SolButton>
          </div>
        </div>

        <div v-if="isStatusSuccess" class="w-full">
          <SharedBannerSolfacilPlus class="mt-6" />
        </div>

        <div
          v-if="(!((isStatusSuccess) || (isStatusWaiting && installationData.has_ampera && installationData.has_minimal_projects)) || (openForm))"
          class="mt-6 md:site:mt-10 w-full"
        >
          <div v-if="!loading">
            <h2 class="text-xs font-highlight">
              {{ t('installationPage.titleAddFiles') }}
            </h2>
            <span class="text-base block mb-5 text-neutral-low-light">
              {{ isStatusWaiting || isStatusError ? t('installationPage.descriptionAvoidFailures') : t('installationPage.descriptionAddFiles') }}
            </span>

            <SolAlert
              v-if="isStatusError"
              id="error-feedback"
              :title="errorMessages.length > 0 ? 'Um ou mais arquivos foram reprovadas pelos motivos abaixo' : 'A instalação foi reprovada'"
              feedback="error"
            >
              <div v-if="errorMessages.length > 0">
                <ul>
                  <li v-for="(error, index) in errorMessages" :key="index">
                    <span>{{ error.type }}: </span>
                    <span>{{ error.description }}</span>
                  </li>
                </ul>
              </div>
              <span v-else>{{ installationData.status.description }}</span>
            </SolAlert>
            <form :class="`${loadingSendAnalysis || (isStatusError && errorMessages.length === 0) ? 'pointer-events-none' : ''}`">
              <div class="grid grid-cols-1 md:system:grid-cols-2 gap-6 mt-6">
                <div>
                  <strong class="block">{{ t('installationPage.form.installationField.label') }}</strong>
                  <SolFileUpload
                    v-if="installationReproved || isStatusWaiting"
                    id="uploadInstallation"
                    name="uploadInstallation"
                    :use-field="useField"
                    :download-menu="['preview', 'download', 'delete']"
                    accept=".jpeg, .jpg, .png, .webp, .pdf, mp4"
                    :multiple="true"
                    :placeholder="t('installationPage.form.placeholderUpload')"
                    label=""
                  />
                  <template v-else>
                    <SharedMessageStatus
                      v-for="(section, index) in getSectionsByType('installation')"
                      :key="index"
                      v-bind="getSectionData(section)"
                    />
                  </template>
                </div>

                <div>
                  <strong class="block">{{ t('installationPage.form.invertersField.label') }}</strong>
                  <SolFileUpload
                    v-if="invertersReproved || isStatusWaiting"
                    id="uploadInverters"
                    name="uploadInverters"
                    :use-field="useField"
                    :download-menu="['preview', 'download', 'delete']"
                    accept=".jpeg, .jpg, .png, .webp, .pdf, mp4"
                    :multiple="true"
                    :placeholder="t('installationPage.form.placeholderUpload')"
                    label=""
                  />
                  <template v-else>
                    <SharedMessageStatus
                      v-for="(section, index) in getSectionsByType('inverters')"
                      :key="index"
                      v-bind="getSectionData(section)"
                    />
                  </template>
                </div>
              </div>
              <div
                v-if="installationData.has_ampera"
                class="grid grid-cols-1 md:system:grid-cols-2 gap-6 mt-6"
              >
                <div>
                  <strong class="block text-3xs fonts-body-medium-bold">{{ t('installationPage.form.installedField.label') }}</strong>
                  <SolFileUpload
                    v-if="amperaReproved || isStatusWaiting"
                    id="uploadAmpera"
                    name="uploadAmpera"
                    :use-field="useField"
                    :download-menu="['preview', 'download', 'delete']"
                    accept=".jpeg, .jpg, .png, .webp, .pdf, mp4"
                    :multiple="true"
                    :placeholder="t('installationPage.form.placeholderUpload')"
                    label=""
                  />

                  <template v-else>
                    <SharedMessageStatus
                      v-for="(section, index) in getSectionsByType('ampera')"
                      :key="index"
                      v-bind="getSectionData(section)"
                    />
                  </template>
                </div>
              </div>
              <div class="grid grid-cols-1  gap-6 mt-6">
                <div>
                  <strong class="block mb-quark">{{ t('installationPage.form.additionalComments') }}</strong>
                  <SolInputTextarea
                    id="additionalComments"
                    name="additionalComments"
                    :use-field="useField"
                    :disabled="isStatusProcessing"
                    placeholder="Ex: dados de monitoramento (login e senha), casos de avaria, justificativas, etc..."
                  />
                </div>
              </div>
            </form>

            <SolDivider thickness="x-small" class="mt-10 mb-6" />

            <div class="flex justify-between">
              <SolButton
                id="downloadButton"
                variant="secondary"
                size="large"
                :disabled="isStatusWaiting"
                @click="downloadFiles()"
              >
                {{ t('installationPage.form.downloadFiles') }}
                <template #icon:left>
                  <DownloadIcon class="icon" />
                </template>
              </SolButton>
              <SolButton
                id="sendButton"
                :disabled="
                  (!installationReproved && !invertersReproved && !amperaReproved && !isStatusWaiting)
                    || Boolean(isStatusError && errorMessages.length === 0)"
                variant="primary"
                size="large"
                :loading="loadingSendAnalysis"
                @click="sendAnalysis()"
              >
                {{ t('installationPage.form.sendButton') }}
              </SolButton>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="bg-neutral-high-pure w-full rounded-lg p-10  flex items-center justify-center">
        <SolEmptyState
          id="emptyStateInstallation"
          :title="$t('emptyStateInstallation.title')"
          :subtitle="$t('emptyStateInstallation.subtitle')"
          variant="no-content"
        />
      </div>
    </div>

    <div class="mx-4 md:system:mx-12">
      <SolDivider thickness="x-small" orientation="horizontal" class="my-5 mt-2 md:system:my-0 md:system:-mt-5" />

      <div class="flex flex-col md:system:flex-row flex-col-reverse justify-between gap-5 my-4 md:system:my-6">
        <SolButton
          id="installation-back-formalization"
          size="large"
          class="w-full md:system:w-auto"
          variant="secondary"
          @click="redirectFormalization"
        >
          {{ t('installationPage.footer.formalization') }}
        </SolButton>

        <SolButton
          v-if="installationData.has_ampera && installationData.has_minimal_projects && installationData.plant_id"
          id="installation-next-ampera"
          size="large"
          class="w-full md:system:w-auto"
          :disabled="!isStatusSuccess"
          @click="monitorAmpera"
        >
          {{ t('installationPage.footer.ampera') }}
        </SolButton>
      </div>
    </div>
  </main>
</template>

<style lang="scss">
.installation-page {
  @apply flex flex-col items-start pb-micro px-micro mt-6 md:site:mt-0;
  @apply md:site:p-12;

  .container {
    @apply bg-neutral-high-pure rounded-lg p-2xs;
  }

  .status {
    @apply flex items-center justify-center flex-col border p-6 rounded-lg border-neutral-high-dark;

    .info {
      @apply flex flex-col items-center justify-center lg:site:max-w-[1000px];
    }
  }

  .title-status {
    @apply text-sm mt-4 text-center leading-8 font-highlight;
  }

  .subtitle-status {
    @apply text-center text-2xs text-neutral-low-light lg:site:max-w-[800px] font-regular leading-xs;
  }

  .buttons {
    @apply flex pt-2xs gap-4 flex-col md:site:flex-row;

    .icon {
      @apply h-6 w-6;
    }
  }

  .experience {
    @apply flex justify-end pt-6 mt-4 border-t border-neutral-high-dark;
  }

  .loader-container {
    @apply  my-4 flex flex-col justify-between w-full;
  }

  .loader-project {
    @apply h-96 rounded-lg;
    @apply top-0 right-0 bottom-0 left-0 z-50;
    background: rgba(#fff, 0.9)
      url(https://cdn.solfacil.com.br/assets/img/loading-for-legacy.gif) no-repeat
      center center;
  }
}
</style>

<route lang="yaml">
meta:
  layout: simulation
    </route>
