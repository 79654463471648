<script setup lang="ts">
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'
import UpdatePrompt from '~/components/system/UpdatePromp.vue'

useHead({
  title: 'Financiamentos',
  meta: [
    { name: 'description', content: 'Opinionated Vite Starter Template' },
    {
      name: 'theme-color',
      content: computed(() => isDark.value ? '#00aba9' : '#ffffff'),
    },
  ],
  link: [
    {
      rel: 'icon',
      type: 'image/svg+xml',
      href: computed(() => preferredDark.value ? '/favicon-dark.svg' : '/favicon.svg'),
    },
  ],
})

onMounted(async () => {
  window.addEventListener('error', (e) => {
    if ((e.message && e.message.includes('dynamically imported module')))
      window.location.reload()
  }, true)
})
</script>

<template>
  <RouterView v-if="isAuthenticated" />
  <VueQueryDevtools />
  <UpdatePrompt />
</template>
