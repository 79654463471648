function validateCPF(value: string): boolean {
  const cpf = formatCPF(value)
  if (!isValidFormat(cpf))
    return false

  const firstVerificationNumber = calculateVerificationNumber(cpf, 9)
  const secondVerificationNumber = calculateVerificationNumber(cpf, 10)

  if (firstVerificationNumber !== Number(cpf.charAt(9)) || secondVerificationNumber !== Number(cpf.charAt(10)))
    return false

  return true
}

function formatCPF(cpf: string): string {
  return cpf.replace(/[^\d]+/g, '')
}

function isValidFormat(cpf: string): boolean {
  return !(cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf))
}

function calculateVerificationNumber(cpf: string, length: number): number {
  let sum = 0

  for (let i = 0; i < length; i++)
    sum += Number(cpf.charAt(i)) * (length + 1 - i)

  const result = (sum * 10) % 11
  return result === 10 || result === 11 ? 0 : result
}

export default validateCPF
