import type { RestConnections } from '~/types'

export default {
  dev: {
    url: 'https://stg-receipt-model.solfacil.com.br',
    key: '$2a$12$ZVD3Gt7Ri7Gwfg3Bx.Ygy.VRqBXLogILKYAYqo7ucayaTne3MMxtW',
  },
  prod: {
    url: 'https://receipt-model.solfacil.com.br',
    key: '$2a$12$ZVD3Gt7Ri7Gwfg3Bx.Ygy.VRqBXLogILKYAYqo7ucayaTne3MMxtW',
  },
  stg: {
    url: 'https://stg-receipt-model.solfacil.com.br',
    key: '$2a$12$ZVD3Gt7Ri7Gwfg3Bx.Ygy.VRqBXLogILKYAYqo7ucayaTne3MMxtW',
  },
} as RestConnections
