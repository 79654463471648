<script setup lang="ts">
import { toRaw } from 'vue'
import { useToast } from '@solfacil/girassol'
import SimulatorService from '~/services/simulator/Simulator'
import type { User } from '~/utils/auth/User'
import IMaterialSymbolsShareOutline from '~icons/material-symbols/share-outline'
import IMaterialSymbolsDownload from '~icons/material-symbols/download'
const props = defineProps<{
  commission: any
  gracePeriod: any
  isCombo: boolean | undefined
}>()

const { getAddonsApplieds, getSelectedInstallment } = useResumeStore()

const { t } = useI18n()
const { createErrorToast, createSuccessToast } = useToast()
const router = useRouter()
const { rest } = useConnections()
const user: User = useAppStorage().get('user')
const loadingPdf = ref<boolean>(false)
const pdfVersionFlag = ref<boolean>(false)

onMounted(() => {
  createOptions()
})

watch(() => props.isCombo, () => { createOptions() })

const optionSimulation = ref<Array<{ name: string; value: boolean; label: string }>>([])

const enablePdf = ref(true)

async function enablePdfDownload() {
  const enablePdfFlag: boolean = await useFlag('enable-pdf-download-button', { partner_id: user?.parceiro?.id })
  enablePdf.value = enablePdfFlag
}

onMounted(async () => {
  pdfVersionFlag.value = !await useFlag('pdf-v-2-is-enabled', { partner_id: user?.parceiro?.id })
  await enablePdfDownload()
})

function indexerKinderPdfAdapter(indexer_kind: string) {
  if (indexer_kind === 'PRE_FIXADO')
    return 'PRE'
  return 'POST'
}

function createOptions() {
  if (props.isCombo) {
    optionSimulation.value = [{
      name: 'combo',
      value: true,
      label: 'Com Combo Fácil - com desconto na parcela',
    }]
  }
  else {
    optionSimulation.value = [{
      name: 'combo',
      value: false,
      label: 'Sem Combo Fácil - sem desconto na parcela',
    }]
  }
}

const isAdmin = computed(() => {
  return user?.perfil?.toLocaleLowerCase() === 'admin'
})

const modal = ref(false)
const downloadOption = ref<string | null>(null)

function setDownloadOption(value: 'whatsapp' | 'salvar') {
  downloadOption.value = value
  shareLink()
}

const simulatorService = new SimulatorService(useApi('simulator'))

async function getResumeOfInstallmentsFile() {
  const { gracePeriod, commission } = props

  const commissionValue = toRaw(commission)
  const gracePeriodValue = toRaw(gracePeriod)
  const installments = getSelectedInstallment()?.installments as number
  const indexer_kind = getSelectedInstallment()?.indexer_kind as string

  if (pdfVersionFlag.value) {
    return await simulatorService.get_resume_of_installments_file(gracePeriodValue,
      commissionValue,
      props.isCombo,
      router.currentRoute.value.params.id as string,
      user.parceiro.nivelSolfacilMais,
      !!(getAddonsApplieds()?.find(item => item.product_slug === 'Ampera')))
  }

  return await simulatorService.get_resume_of_installments_file_v2(gracePeriodValue,
    commissionValue,
    props.isCombo,
    router.currentRoute.value.params.id as string,
    user.parceiro.nivelSolfacilMais,
    !!(getAddonsApplieds()?.find(item => item.product_slug === 'Ampera')),
    !!(getAddonsApplieds()?.find(item => item.product_slug === 'Prestamista')),
    !!(getAddonsApplieds()?.find(item => item.product_slug === 'Riscos Diversos')),
    installments,
    indexerKinderPdfAdapter(indexer_kind),
  )
}

async function getResumeOfInstallmentsFileUrl() {
  const { gracePeriod, commission } = props

  const commissionValue = toRaw(commission)
  const gracePeriodValue = toRaw(gracePeriod)
  const has_ampera = !!(getAddonsApplieds()?.find(item => item.product_slug === 'Ampera'))
  const has_income_loss_insurance = !!(getAddonsApplieds()?.find(item => item.product_slug === 'Prestamista'))
  const has_equipment_insurance = !!(getAddonsApplieds()?.find(item => item.product_slug === 'Riscos Diversos'))
  const installments = getSelectedInstallment()?.installments as number
  const indexer_kind = getSelectedInstallment()?.indexer_kind as string

  if (pdfVersionFlag.value)
    return `/simulation/${router.currentRoute.value.params.id}/whatsapp-pdf?grace_period=${gracePeriodValue}&commission=${commissionValue}&with_combo=${props.isCombo}&partner_level=${user.parceiro.nivelSolfacilMais}&is_ampera=${has_ampera}`

  return `/simulation/${router.currentRoute.value.params.id}/pdf/v2?grace_period=${gracePeriodValue}&commission=${commissionValue}&with_combo=${props.isCombo}&partner_level=${user.parceiro.nivelSolfacilMais}&is_ampera=${has_ampera}&income_loss_insurance=${has_income_loss_insurance}&equipment_insurance=${has_equipment_insurance}&selected_installment=${installments}&indexer_kind=${indexerKinderPdfAdapter(indexer_kind)}`
}

async function shareLink() {
  loadingPdf.value = true
  try {
    let response = null as any
    if (downloadOption.value === 'salvar' || pdfVersionFlag.value === true)
      response = await getResumeOfInstallmentsFile()

    if (response || pdfVersionFlag.value === false) {
      const env_url = rest.simulator.url

      if (downloadOption.value === 'whatsapp') {
        const newTab = window.open()
        const route = await getResumeOfInstallmentsFileUrl()
        const url = encodeURIComponent(`${env_url}${route}`)
        newTab?.location.replace(`https://wa.me/?text=${url}`)
        return
      }
      const blob = new Blob([response], { type: 'application/pdf' })

      const blobUrl = window.URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = blobUrl
      downloadLink.download = 'orcamento-solfacil.pdf'

      downloadLink.click()

      window.URL.revokeObjectURL(blobUrl)
      createSuccessToast('Salvo com sucesso')
    }
  }
  catch {
    if (downloadOption.value === 'whatsapp') {
      createErrorToast('Ocorreu um erro ao tentar enviar a simulação via WhatsApp')
      return
    }
    createErrorToast('Ocorreu um erro ao tentar baixar o PDF.')
  }
  finally {
    loadingPdf.value = false
    modal.value = false
  }
}

const data = [
  {
    text: t('app.whatsapp'),
    active: false,
    external: true,
    action: () => setDownloadOption('whatsapp'),
  },
  {
    text: t('app.save'),
    active: false,
    external: true,
    action: () => setDownloadOption('salvar'),
  },
]
const windowWidth = ref(window.innerWidth)
function getMenuPosition() {
  const mdSize = 1024
  return windowWidth.value < mdSize ? 'right' : 'top'
}
</script>

<template>
  <div class="share-container">
    <SolMenu id="just-test" :position="getMenuPosition()" class="flex justify-end z-20">
      <template #label>
        <SolButton
          id="primary-right-icon"
          :loading="loadingPdf"
          :disabled="isAdmin || !enablePdf"
          size="large"
          variant="secondary"
          class="share-button-large"
          :title="isAdmin ? 'Administrator não tem permissão para compartilhamento' : 'Pdf desabilitado no momento'"
        >
          <template #icon:left>
            <IMaterialSymbolsShareOutline class="share-icon" />
          </template>
          <span>
            {{ t('app.share') }}
          </span>
        </SolButton>
        <SolButton
          id="primary-right-icon"
          :loading="loadingPdf"
          :disabled="isAdmin"
          size="medium"
          variant="secondary"
          class="share-button-medium !h-[56px] !w-[56px]"
          :title="isAdmin ? 'Administrator não tem permissão para compartilhamento' : ''"
        >
          <template #icon:left>
            <IMaterialSymbolsShareOutline class="share-icon" />
          </template>
        </SolButton>
      </template>
      <nav v-if="!loadingPdf" id="navbarShare">
        <ul role="menubar">
          <ItemsMenuItems
            v-for="({ text, active, external, action }, index) in data"
            :id="text"
            :key="index"
            :text="text"
            :action="action"
            :active="active"
            :external="external"
            class="menu-spacer"
          >
            <template #icon:WhatsApp>
              <img src="/icons/whatsapp.svg" class="mx-nano">
            </template>
            <template #icon:Salvar>
              <IMaterialSymbolsDownload class="mx-nano" />
            </template>
          </ItemsMenuItems>
        </ul>
      </nav>
    </SolMenu>
  </div>
</template>

<style lang="scss" scoped>
.share-container{
  @apply self-end;

  .menu-spacer{
    @apply pr-xs text-neutral-low-medium;
  }
  .share-button-large{
    @apply hidden;
    @apply md:site:flex;
  }

  .share-button-medium{
    @apply flex;
    @apply md:site:hidden;
  }
  .share-icon{
      @apply h-6;
    }
  .btn-selectable-left{
    @apply border border-brand-primary-light rounded-l-md;
    @apply p-nano cursor-pointer font-bold;
  }

  .btn-selectable-right{
    @apply border border-brand-primary-light rounded-r-md;
    @apply p-nano cursor-pointer font-bold;
  }
  .btn-selected{
    @apply bg-brand-secondary-pure border-brand-secondary-pure;

    .title {
      @apply text-neutral-high-pure;
    }

  }
}
.buttons {
  @apply flex;
}
</style>
