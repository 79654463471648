import type { $Fetch } from 'ohmyfetch'

export default class BaseService {
  private _api: $Fetch

  constructor(api: $Fetch) {
    this._api = api
  }

  get api(): $Fetch {
    return this._api
  }
}
