import type { RestConnections } from '~/types'

export default {
  hmla2: {
    url: 'https://hmla2-core.solfacil.com.br/v1/',
    key: '308b638d-344c-4ca8-a635-deef00cb0a03',
  },
  hmla: {
    url: 'https://hmla-core.solfacil.com.br/v1/',
    key: '308b638d-344c-4ca8-a635-deef00cb0a03',
  },
  dev: {
    url: 'https://stg-core.solfacil.com.br/v1/',
    key: 'bceb6409-e04f-42ed-b4ab-bc2de618dce9',
  },
  prod: {
    url: 'https://api.solfacil.com.br/v1/',
    key: 'bceb6409-e04f-42ed-b4ab-bc2de618dce9',
  },
  stg: {
    url: 'https://stg-core.solfacil.com.br/v1/',
    key: 'bceb6409-e04f-42ed-b4ab-bc2de618dce9',
  },
} as RestConnections
