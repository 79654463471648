import type { RouteLocationNormalized } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'
import generatedRoutes from 'virtual:generated-pages'
import { setupLayouts } from 'virtual:generated-layouts'

const routes = setupLayouts(generatedRoutes)
const history = createWebHistory('/')
const router = createRouter({ history, routes })

const CALLBACK_URI = '/auth/callback'

router.beforeResolve((to, _from, next) => {
  if (shouldRedirect(to))
    redirectToKeycloak()
  else
    next()
})

router.beforeEach((to, _from, next) => {
  if (to.path === CALLBACK_URI)
    next()
  else if (shouldRedirectToCallback(to))
    redirectToCallback(to)
  else
    next()
})

function shouldRedirect(to: RouteLocationNormalized) {
  return !to.meta.public && !useKeycloak().isAuthenticated()
}

function redirectToKeycloak() {
  useKeycloak().redirect()
}

function shouldRedirectToCallback(to: RouteLocationNormalized) {
  return to.query.code && to.query.session_state
}

function redirectToCallback(to: RouteLocationNormalized) {
  router.push({ path: CALLBACK_URI, query: { ...to.query, origin: to.path } })
}

export { router, routes, history }
