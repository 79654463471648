<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import { useField, useForm } from 'vee-validate'

import dayjs from 'dayjs'
import zod from 'zod'
import { useToast } from '@solfacil/girassol'
import CompanyRegisterService from '~/services/company-register/CompanyRegister'
import CustomerRegisterService from '~/services/customer-register/CustomerRegister'
import type { CompanyFormModel, TypesErrorsCompanyDocuments, UpdateFormCompany } from '~/utils/company-register/CompanyRegister'
import type { StageFormalization } from '~/utils/customer-register/CustomerRegister'
import { Formalization } from '~/types/enum'
import IconLock from '~icons/material-symbols/lock'
import IconInfo from '~icons/material-symbols/info-outline'
import type { FormalizationStatus } from '~/utils/financing-flow/Financing'

const props = defineProps<{
  companyForm: CompanyFormModel
  position: number
  errorsCompanyDocuments: TypesErrorsCompanyDocuments
  statusDocument: string
  stage: StageFormalization
  stageReproved: string
  statusProject: FormalizationStatus
  triggerRefetch: () => Promise<void>
}>()

const { t } = useI18n()
const { track } = useMixpanel()

const debouncedUpdate = ref()
const loadingEmail = ref(false)
const firstRender = ref<string>()
const blocked = ref(true)

const radiosCountryside = [
  {
    name: 'countryside',
    value: true,
    label: `${t('formAddress.yesCountryside')}`,
  },
  {
    name: 'countryside',
    value: false,
    label: `${t('formAddress.noCountryside')}`,
  },
]

const energyBillIsTheSameOfClientOptions = [
  {
    name: 'energy_bill_is_the_same_of_client',
    value: true,
    label: `${t('formAddress.yesCountryside')}`,
  },
  {
    name: 'energy_bill_is_the_same_of_client',
    value: false,
    label: `${t('formAddress.noCountryside')}`,
  },
]

const majorityIsOnlyLegalRepresentantOptions = [
  {
    name: 'majorityIsOnlyLegalRepresentant',
    value: true,
    label: `${t('formAddress.yesCountryside')}`,
  },
  {
    name: 'majorityIsOnlyLegalRepresentant',
    value: false,
    label: `${t('formAddress.noCountryside')}`,
  },

]

const hasOnlyOneLegalRepresentantOptions = [
  {
    name: 'hasOnlyOneLegalRepresentant',
    value: true,
    label: `${t('formAddress.yesCountryside')}`,
  },
  {
    name: 'hasOnlyOneLegalRepresentant',
    value: false,
    label: `${t('formAddress.noCountryside')}`,
  },
]

onMounted(() => {
  checkStageReproved()
  refreshValidationCep()
})

function formatDate(date: string) {
  const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/
  if (!date)
    return
  if (date.length === 10)
    return date.replace(regex, '$3-$2-$1')
}

function formatDateToForm(date?: string) {
  if (!date)
    return
  if (date.length === 10)
    return dayjs(date).format('DD/MM/YYYY')
}

function getCityAndStateId(cityOrStateId?: number) {
  if (!cityOrStateId)
    return
  return cityOrStateId
}

const firstLegalRepresentant = {
  address: {
    postal_code: props.companyForm?.legal_representants[0]?.address_form?.postal_code || '',
    street: props.companyForm?.legal_representants[0]?.address_form?.street || '',
    street_number: props.companyForm?.legal_representants[0]?.address_form?.street_number?.toString() || '',
    additional_info: props.companyForm?.legal_representants[0]?.address_form?.additional_info || '',
    neighborhood: props.companyForm?.legal_representants[0]?.address_form?.neighborhood || '',
    state: {
      name: props.companyForm?.legal_representants[0]?.address_form?.state?.name || '',
      value: props.companyForm?.legal_representants[0]?.address_form?.state_id || null,
    },
    city: {
      name: props.companyForm?.legal_representants[0]?.address_form?.city?.name || '',
      value: props.companyForm?.legal_representants[0]?.address_form?.city_id || null,
    },
  },
  person: {
    email: props.companyForm?.legal_representants[0]?.person_form?.email || '',
    full_name: props.companyForm?.legal_representants[0]?.person_form?.full_name || '',
    cpf: props.companyForm?.legal_representants[0]?.person_form?.cpf || '',
    phone: props.companyForm?.legal_representants[0]?.person_form?.phone || '',
    birthdate: formatDateToForm(props.companyForm?.legal_representants[0]?.person_form?.birthdate) || '',
  },
}

const secondLegalRepresentant = {
  address: {
    postal_code: props.companyForm?.legal_representants[1]?.address_form?.postal_code || '',
    street: props.companyForm?.legal_representants[1]?.address_form?.street || '',
    street_number: props.companyForm?.legal_representants[1]?.address_form?.street_number?.toString() || '',
    additional_info: props.companyForm?.legal_representants[1]?.address_form?.additional_info || '',
    neighborhood: props.companyForm?.legal_representants[1]?.address_form?.neighborhood || '',
    state: {
      name: props.companyForm?.legal_representants[1]?.address_form?.state?.name || '',
      value: props.companyForm?.legal_representants[1]?.address_form?.state_id || null,
    },
    city: {
      name: props.companyForm?.legal_representants[1]?.address_form?.city?.name || '',
      value: props.companyForm?.legal_representants[1]?.address_form?.city_id || null,
    },
  },
  person: {
    email: props.companyForm?.legal_representants[1]?.person_form?.email || '',
    full_name: props.companyForm?.legal_representants[1]?.person_form?.full_name || '',
    cpf: props.companyForm?.legal_representants[1]?.person_form?.cpf || '',
    phone: props.companyForm?.legal_representants[1]?.person_form?.phone || '',
    birthdate: formatDateToForm(props.companyForm?.legal_representants[1]?.person_form?.birthdate) || '',
  },
}

function companyFormPartialAdapter(newValues): UpdateFormCompany {
  return {
    company_form: {
      document: newValues.company_form.document,
      company_name: newValues.company_form.company_name,
      business_phone: newValues.company_form.business_phone,
      business_email: newValues.company_form.business_email,
    },
    installation_address_form: {
      postal_code: newValues.installation_address_form.postal_code.replace('-', ''),
      street: newValues.installation_address_form.street,
      street_number: parseInt(newValues.installation_address_form.street_number),
      additional_info: newValues.installation_address_form.additional_info,
      neighborhood: newValues.installation_address_form.neighborhood,
      state_id: newValues.installation_address_form.state.value || null,
      city_id: newValues.installation_address_form.city.value || null,
      rural_area: !!newValues.installation_address_form.rural_area,
    },
    majority_partner_address_form: {
      postal_code: newValues.majority_partner_address_form.postal_code.replace('-', ''),
      street: newValues.majority_partner_address_form.street,
      street_number: parseInt(newValues.majority_partner_address_form.street_number),
      additional_info: newValues.majority_partner_address_form.additional_info,
      neighborhood: newValues.majority_partner_address_form.neighborhood,
      state_id: newValues.majority_partner_address_form.state.value || null,
      city_id: newValues.majority_partner_address_form.city.value || null,
      rural_area: false,
    },
    majority_partner_person_form: {
      email: newValues.majority_partner_person_form.email,
      full_name: newValues.majority_partner_person_form.full_name,
      cpf: newValues.majority_partner_person_form.cpf,
      phone: newValues.majority_partner_person_form.phone,
      birthdate: formatDate(newValues.majority_partner_person_form.birthdate),
    },
    electricity_bill_document: newValues?.electricity_bill_document,
    majority_partner_is_legal_representant: newValues?.majority_partner_is_legal_representant,
    project_id: props.companyForm?.project_id,
    legal_representants: !newValues?.majority_partner_is_legal_representant
      ? [{
          address: {
            ...newValues.first_legal_representant?.address,
            street_number: parseInt(newValues?.first_legal_representant?.address?.street_number),
            postal_code: newValues.first_legal_representant?.address?.postal_code.replace('-', ''),
            state_id: getCityAndStateId(newValues.first_legal_representant?.address?.state?.value) || null,
            city_id: getCityAndStateId(newValues.first_legal_representant?.address?.city?.value) || null,
            rural_area: false,
          },
          person: {
            ...newValues.first_legal_representant?.person,
            birthdate: formatDate(newValues?.first_legal_representant?.person.birthdate),
          },

        }, !newValues.has_more_than_one_legal_representant
          ? {
              address: {
                ...newValues.second_legal_representant?.address,
                street_number: parseInt(newValues?.second_legal_representant?.address?.street_number),
                postal_code: newValues.second_legal_representant?.address?.postal_code.replace('-', ''),
                state_id: getCityAndStateId(newValues.second_legal_representant?.address?.state?.value) || null,
                city_id: getCityAndStateId(newValues.second_legal_representant?.address?.city?.value) || null,
                rural_area: false,
              },
              person: {
                ...newValues.second_legal_representant?.person,
                birthdate: formatDate(newValues?.second_legal_representant?.person.birthdate),
              },

            }
          : null].filter(Boolean)
      : [],
  }
}

const initialValues = {
  company_form: {
    document: props.companyForm?.company_form?.document || '',
    company_name: props.companyForm?.company_form?.company_name || '',
    business_phone: props.companyForm?.company_form?.business_phone || '',
    business_email: props.companyForm?.company_form?.business_email || '',
  },
  installation_address_form: {
    postal_code: props.companyForm?.installation_address_form?.postal_code || '',
    street: props.companyForm?.installation_address_form?.street || '',
    street_number: props.companyForm?.installation_address_form?.street_number?.toString() || '',
    additional_info: props.companyForm?.installation_address_form?.additional_info || '',
    neighborhood: props.companyForm?.installation_address_form?.neighborhood || '',
    state: {
      name: props.companyForm?.installation_address_form?.state?.name || '',
      value: parseInt(props.companyForm?.installation_address_form?.state_id) || null,
    },
    city: {
      name: props.companyForm?.installation_address_form?.city?.name || '',
      value: parseInt(props.companyForm?.installation_address_form?.city_id) || null,
    },
    rural_area: props.companyForm?.installation_address_form?.rural_area || false,
  },
  majority_partner_address_form: {
    postal_code: props.companyForm?.majority_partner_address_form?.postal_code || '',
    street: props.companyForm?.majority_partner_address_form?.street || '',
    street_number: props.companyForm?.majority_partner_address_form?.street_number?.toString() || '',
    additional_info: props.companyForm?.majority_partner_address_form?.additional_info || '',
    neighborhood: props.companyForm?.majority_partner_address_form?.neighborhood || '',
    state: {
      name: props.companyForm?.majority_partner_address_form?.state?.name || '',
      value: parseInt(props.companyForm?.majority_partner_address_form?.state_id) || null,
    },
    city: {
      name: props.companyForm?.majority_partner_address_form?.city?.name || '',
      value: parseInt(props.companyForm?.majority_partner_address_form?.city_id) || null,
    },
    rural_area: props.companyForm?.majority_partner_address_form?.rural_area || false,
  },
  majority_partner_person_form: {
    email: props.companyForm?.majority_partner_person_form?.email || '',
    full_name: props.companyForm?.majority_partner_person_form?.full_name || '',
    cpf: props.companyForm?.majority_partner_person_form?.cpf || '',
    phone: props.companyForm?.majority_partner_person_form?.phone || '',
    birthdate: formatDateToForm(props.companyForm?.majority_partner_person_form?.birthdate) || '',
  },
  electricity_bill_document: props.companyForm?.electricity_bill_document || '',
  energy_bill_is_the_same_of_client: !!props.companyForm?.electricity_bill_document,
  has_more_than_one_legal_representant: !(props.companyForm?.legal_representants?.length > 1),
  majority_partner_is_legal_representant: !!props.companyForm?.majority_partner_is_legal_representant,
  first_legal_representant: firstLegalRepresentant,
  second_legal_representant: secondLegalRepresentant,
}

const validationSchema = toTypedSchema(zod.object({
  company_form: zod.object({
    document: zod.string().min(18, t('form.invalid_document')).max(18, t('form.invalid_document')),
    company_name: zod.string().min(1, { message: t('form.required') }),
    business_phone: zod.string().min(14, t('form.invalid_phone')).max(16, t('form.invalid_phone')),
    business_email: zod.string().email({ message: t('form.invalid_email') }).min(1, t('form.required')),
  }),
  installation_address_form: zod.object({
    postal_code: zod.string().min(1, { message: t('form.required') }),
    street: zod.string().min(1, { message: t('form.required') }),
    street_number: zod.string().min(1, { message: t('form.required') }),
    additional_info: zod.string().optional(),
    neighborhood: zod.string().min(1, { message: t('form.required') }),
    state: zod.object({
      name: zod.string().min(1, t('form.required')),
      value: zod.number().min(1, t('form.required')),
    }),
    city: zod.object({
      name: zod.string().min(1, t('form.required')),
      value: zod.number().min(1, t('form.required')),
    }),
    rural_area: zod.boolean(),
  }),

  majority_partner_address_form: zod.object({
    postal_code: zod.string().min(1, { message: t('form.required') }),
    street: zod.string().min(1, { message: t('form.required') }),
    street_number: zod.string().min(1, { message: t('form.required') }),
    additional_info: zod.string().optional(),
    neighborhood: zod.string().min(1, { message: t('form.required') }),
    state: zod.object({
      name: zod.string().min(1, t('form.required')),
      value: zod.number().min(1, t('form.required')),
    }),
    city: zod.object({
      name: zod.string().min(1, t('form.required')),
      value: zod.number().min(1, t('form.required')),
    }),
  }),

  majority_partner_person_form: zod.object({
    email: zod.string().email({ message: t('form.invalid_email') }).min(1, t('form.required')),
    full_name: zod.string().min(1, { message: t('form.required') }),
    cpf: zod.string().min(1, { message: t('form.required') }),
    phone: zod.string().min(16, t('form.invalid_phone')).max(16, t('form.invalid_phone')),
    birthdate: zod.string().min(1, { message: t('form.required') }),
  }),

  electricity_bill_document: zod.string().nullable(),
  energy_bill_is_the_same_of_client: zod.boolean(),
  majority_partner_is_legal_representant: zod.boolean(),
  has_more_than_one_legal_representant: zod.boolean().nullable().or(zod.undefined()),

  first_legal_representant: zod.object({
    person: zod.object({
      email: zod.string().email({ message: t('form.invalid_email') }).min(1, t('form.required')),
      full_name: zod.string().min(1, { message: t('form.required') }),
      cpf: zod.string().min(14, t('form.invalid_document')).max(14, t('form.invalid_document')),
      phone: zod.string().min(16, t('form.invalid_phone')).max(16, t('form.invalid_phone')),
      birthdate: zod.string().min(1, { message: t('form.required') }),
    }),
    address: zod.object({
      postal_code: zod.string().min(1, { message: t('form.required') }),
      street: zod.string().min(1, { message: t('form.required') }),
      street_number: zod.string().min(1, { message: t('form.required') }),
      additional_info: zod.string().optional(),
      neighborhood: zod.string().min(1, { message: t('form.required') }),
      state: zod.object({
        name: zod.string().min(1, t('form.required')),
        value: zod.number().min(1, t('form.required')),
      }),
      city: zod.object({
        name: zod.string().min(1, t('form.required')),
        value: zod.number().min(1, t('form.required')),
      }),
    }),
  }).nullable().or(zod.undefined()),
  second_legal_representant: zod.object({
    person: zod.object({
      email: zod.string().email({ message: t('form.invalid_email') }).min(1, t('form.required')),
      full_name: zod.string().min(1, { message: t('form.required') }),
      cpf: zod.string().min(14, t('form.invalid_document')).max(14, t('form.invalid_document')),
      phone: zod.string().min(16, t('form.invalid_phone')).max(16, t('form.invalid_phone')),
      birthdate: zod.string().min(1, { message: t('form.required') }),
    }),
    address: zod.object({
      postal_code: zod.string().min(1, { message: t('form.required') }),
      street: zod.string().min(1, { message: t('form.required') }),
      street_number: zod.string().min(1, { message: t('form.required') }),
      additional_info: zod.string().optional(),
      neighborhood: zod.string().min(1, { message: t('form.required') }),
      state: zod.object({
        name: zod.string().min(1, t('form.required')),
        value: zod.number().min(1, t('form.required')),
      }),
      city: zod.object({
        name: zod.string().min(1, t('form.required')),
        value: zod.number().min(1, t('form.required')),
      }),
    }),
  }).nullable().or(zod.undefined()),
}),
)

const customerService = new CustomerRegisterService(useApi('customerRegister'))
const companyRegistrationservice = new CompanyRegisterService(useApi('companyRegister'))

const { values, setValues, setFieldError, validate, errors } = useForm({
  validationSchema,
  initialValues,
})

const isApprovedOrReprovedOrUnderAnalysis = ['approved', 'reproved', 'under_analysis'].includes(props.statusProject.registration)

function refreshValidationCep() {
  if (!isApprovedOrReprovedOrUnderAnalysis) {
    if (values.installation_address_form?.postal_code)
      fetchAddress('installation_address_form', values?.installation_address_form?.postal_code)

    if (values.majority_partner_address_form?.postal_code)
      fetchAddress('majority_partner_address_form', values?.majority_partner_address_form?.postal_code)

    if (values.first_legal_representant?.address?.postal_code)
      fetchAddress('first_legal_representant.address', values?.first_legal_representant?.address?.postal_code)

    if (values.second_legal_representant?.address?.postal_code)
      fetchAddress('second_legal_representant.address', values?.second_legal_representant?.address?.postal_code)
  }
}

const loadingCep = ref(false)

type ValidationSchemaKeys =
  | 'installation_address_form'
  | 'majority_partner_address_form'
  | 'first_legal_representant.address'
  | 'second_legal_representant.address'

async function fetchAddress(field: ValidationSchemaKeys, postal_code?: string) {
  try {
    loadingCep.value = true
    const { data } = await customerService.get_address(postal_code || '')

    if (field.includes('.')) {
      return setValues({
        [field.split('.')[0]]: {
          address: {
            street: data?.street === '' ? values[field.split('.')[0]].address.street : data?.street,
            neighborhood: data?.neighborhood === '' ? values[field.split('.')[0]].address.neighborhood : data?.neighborhood,
            state: {
              name: data?.state?.name,
              value: Number(data?.state?.combo_id),
            },
            city: {
              name: data?.city.name,
              value: Number(data?.city?.combo_id),
            },
          },
        },

      }, false)
    }
    return setValues({
      [field]: {
        street: data?.street === '' ? values[field].street : data?.street,
        neighborhood: data?.neighborhood === '' ? values[field].neighborhood : data?.neighborhood,
        state: {
          name: data?.state.name,
          value: Number(data?.state.combo_id),
        },
        city: {
          name: data?.city.name,
          value: Number(data?.city.combo_id),
        },
      },

    }, false)
  }
  catch {
    setFieldError(`${field}.postal_code`, t('form.cepNotFound'))
  }
  finally {
    loadingCep.value = false
  }
}

function clearLegalRepresentants() {
  if (values.majority_partner_is_legal_representant) {
    return setValues({
      first_legal_representant: null,
      second_legal_representant: null,
    }, false)
  }
  else {
    setValues({
      first_legal_representant: values.first_legal_representant ? values.first_legal_representant : firstLegalRepresentant,
    }, false)
  }

  if (!values.has_more_than_one_legal_representant) {
    setValues({
      second_legal_representant: values.second_legal_representant ? values.second_legal_representant : secondLegalRepresentant,
    }, false)
  }
  else {
    setValues({
      second_legal_representant: null,
    }, false)
  }
}

watch(values, async (newValues) => {
  clearLegalRepresentants()
  if (!firstRender.value)
    return firstRender.value = JSON.stringify(newValues)

  if (firstRender.value === JSON.stringify(newValues))
    return firstRender.value = JSON.stringify(newValues)

  clearTimeout(debouncedUpdate.value)

  debouncedUpdate.value = setTimeout(async () => {
    try {
      const parsedValues = companyFormPartialAdapter(newValues)

      const response = await companyRegistrationservice.update_form_partial(parsedValues)
      track('formalizing_button_finish', { trigger: 'Clique no botão Avançar confirmando todo fluxo PJ', ...response })

      if (response?.data?.invalid_fields?.includes('email'))
        return setFieldError('majority_partner_person_form.email', t('form.invalid_email'))
    }
    catch {

    }
    finally {
      loadingCep.value = false
    }
  }, 1000)
})

const EnumTags: any = {
  under_analysis: {
    id: 'clientDataAnalysis',
    text: 'Em análise',
    variant: 'fill',
    size: 'medium',
    type: 'informative',
  },
  approved: {
    id: 'clientDataApproved',
    text: 'Aprovado',
    variant: 'fill',
    size: 'medium',
    type: 'positive',
  },
  reproved: {
    id: 'clientDataReproved',
    text: 'Reprovado',
    variant: 'fill',
    size: 'medium',
    type: 'negative',
  },
  pending: {
    id: 'clientDataPending',
    text: 'Em andamento',
    variant: 'fill',
    size: 'medium',
    type: 'warning',
  },
  pendency: {
    id: 'clientDataPendency',
    text: 'Pendenciado',
    variant: 'fill',
    size: 'medium',
    type: 'warning',
  },
}

const {
  createErrorToast,
} = useToast()

const isNotPending = computed(() => props.statusProject.registration !== 'pending' && props.statusProject.documentation !== 'pending')

const loadingConfirmCompanyInformation = ref(false)

async function confirmCompanyInformation() {
  const formIsValid = await validate()
  if (!formIsValid.valid)
    return false

  try {
    loadingConfirmCompanyInformation.value = true
    const parsedValues = companyFormPartialAdapter(values)

    const response = await companyRegistrationservice.registration_confirm(parsedValues)

    if (response?.data?.invalid_fields?.includes('email'))
      return setFieldError('majority_partner_person_form.email', t('form.invalid_email'))

    await props.triggerRefetch()
  }
  catch {
    createErrorToast(t('form.error'))
  }
  finally {
    loadingConfirmCompanyInformation.value = false
  }
}

const disabledFieldsAndButtons = computed(() => {
  return props.statusProject.registration !== 'pending'
})

watch(() => props.errorsCompanyDocuments, (newValue) => {
  if (isNotPending.value && !props.errorsCompanyDocuments.installation_document_matches_bill) {
    setValues({
      energy_bill_is_the_same_of_client: true,
    }, false)
    setTimeout(() => {
      setFieldError('electricity_bill_document', t('electricityBill.errorMessageInstallationDocumentMatchesBill'))
    })
  }

  if (isNotPending.value && !props.errorsCompanyDocuments.incorporation_document_matches_document) {
    setTimeout(() => {
      setFieldError('company_form.document', t('electricityBill.errorMessageInstallationDocumentMatchesBillCompany'))
    })
  }

  if (isNotPending.value && !props.errorsCompanyDocuments.identity_matches_document) {
    setTimeout(() => {
      setFieldError('majority_partner_person_form.cpf', t('formDocument.errorMessageInstallationDocumentMatches'))
      setFieldError('first_legal_representant.person.cpf', t('formDocument.errorMessageInstallationDocumentMatches'))
      setFieldError('second_legal_representant.person.cpf', t('formDocument.errorMessageInstallationDocumentMatches'))
    })
  }
})

const disabledAddress = computed(() => {
  return (!values.installation_address_form?.postal_code || disabledFieldsAndButtons.value)
    && props.errorsCompanyDocuments.installation_address_matches_bill
})

const disabledButton = computed(() => {
  return disabledFieldsAndButtons.value
    && !props.errorsCompanyDocuments.installation_address_matches_bill
    && props.errorsCompanyDocuments.legal_representant_missing
    && !props.errorsCompanyDocuments.installation_document_matches_bill
    && !props.errorsCompanyDocuments.identity_matches_document
    && !props.errorsCompanyDocuments.incorporation_document_matches_document
})

function fieldShouldBeValidated(field: number) {
  return field > 0
}

function validateQuantityCharactersCep(field: number) {
  return field === 9
}

function checkStageReproved() {
  if (props.stageReproved === '')
    blocked.value = false
  else
    blocked.value = props.stageReproved !== Formalization.REGISTRATION
}

const checkStatusProject = computed(() => {
  return EnumTags[props.statusProject.registration]
})
</script>

<template>
  <CustomerElementAccordion
    :id="`accordion_${Formalization.REGISTRATION}`"
    :title="t('customer.client_data')"
    :position="String(position)"
    :tag="blocked ? undefined : checkStatusProject"
    :blocked="blocked"
    :open-collapse="!blocked && stage === Formalization.REGISTRATION"
    class="-mt-6"
  >
    <template #icon>
      <IconLock v-if="blocked" />
    </template>

    <div>
      <h2 class="mt-2">
        {{ t('customer.client_data_subtitle') }}
      </h2>
    </div>
    <div class="form-company-data">
      <form>
        <!-- Company data -->
        <!-- Basics Information -->
        <div>
          <h3 class="font-highlight  text-neutral-low-pure text-[16px] md:system:text-[20px]">
            {{ t('company.information') }}
          </h3>
        </div>

        <div class="grid grid-cols-1 md:system:grid-cols-1 gap-6 mt-6">
          <SolInputText
            id="name"
            class="col-span-2 md:system:col-span-1"
            name="company_form.company_name"
            :use-field="useField"
            :label="`${t('company.name')}*`"
            placeholder="EMPRESA LTDA"
            :disabled="disabledFieldsAndButtons"
          />
        </div>
        <div class="grid grid-cols-1 md:system:grid-cols-3 gap-6 mt-6">
          <SolInputText
            id="CNPJ"
            class="col-span-2 md:system:col-span-1"
            name="company_form.document"
            :use-field="useField"
            :label="`${t('company.document')}*`"
            :mask="{ preset: 'CNPJ' }"
            :disabled="true"
            placeholder="00.000.000/0000-00"
          />
          <SolInputText
            id="phone"
            class="col-span-2 md:system:col-span-1"
            name="company_form.business_phone"
            :mask="['(##) ####-####', '(##) # ####-####']"
            :label="`${t('company.phone')}*`"
            placeholder="(00) 0 0000-0000"
            :disabled="disabledFieldsAndButtons"
            :use-field="useField"
          >
            <template #icon:left>
              <span>+55</span>
            </template>
          </SolInputText>
          <SolInputText
            id="email-field"
            name="company_form.business_email"
            type="company_form.business_email"
            :label="`${t('form.email')}*`"
            :use-field="useField"
            placeholder="cliente@email.com"
            :is-loading="loadingEmail"
            :is-success="!!(!errors['company_form.business_email']) && fieldShouldBeValidated(values.company_form?.business_email?.length || 0) && !loadingEmail"
            :success-text="t('form.validatedEmail')"
            :disabled="disabledFieldsAndButtons"
            class="col-span-2 md:system:col-span-1"
          />
        </div>
        <div class="mt-6">
          <h3 class="font-highlight  text-neutral-low-pure text-[16px] md:system:text-[20px]">
            {{ t('formAddress.addressInstallation') }}
          </h3>
        </div>

        <SolAlert
          v-if="isNotPending && statusDocument === 'failed' && !errorsCompanyDocuments.installation_address_matches_bill"
          id="informative-feedback-receipt-model"
          class="my-6"
          :title="t('company.alertDocumentTitle')"
          feedback="warning"
        >
          {{ t('company.alertDocumentMessage') }}
        </SolAlert>

        <div class="grid grid-cols-1 md:system:grid-cols-5 gap-6 mt-6">
          <div class="col-span-2 md:system:col-span-1">
            <div class="ml-9 -mb-4">
              <SimulationTooltip position="right" :text="t('form.cepInfo')" class="tooltip z-100">
                <IconInfo class="cursor-pointer" />
              </SimulationTooltip>
            </div>
            <SolInputText
              id="cep-field"
              name="installation_address_form.postal_code"
              :use-field="useField"
              mask="#####-###"
              :label="t('formAddress.cep')"
              placeholder="00000-000"
              :is-loading="loadingCep"
              :is-success="!errors['installation_address_form.postal_code'] && !loadingCep && fieldShouldBeValidated(values.installation_address_form?.postal_code?.length || 0) && validateQuantityCharactersCep(values.installation_address_form?.postal_code?.length || 0)"
              :success-text="t('form.validatedCep')"
              :disabled="disabledButton"
              @blur="fetchAddress('installation_address_form', values?.installation_address_form?.postal_code)"
            />
          </div>
          <SolInputText
            id="street-field"
            name="installation_address_form.street"
            class="col-span-2"
            :use-field="useField"
            :label="`${t('formAddress.street')}`"
            :disabled="disabledAddress && disabledButton"
          />
          <SolInputText
            id="number-field"
            name="installation_address_form.street_number"
            class="col-span-1"
            :use-field="useField"
            :label="`${t('formAddress.number')}`"
            :disabled="disabledAddress && disabledButton"
          />
          <SolInputText
            id="complement-field"
            name="installation_address_form.additional_info"
            class="col-span-1"
            :use-field="useField"
            :label="`${t('formAddress.complement')}`"
            :placeholder="t('formAddress.complementPlaceholder')"
            :disabled="disabledAddress && disabledButton"
          />
        </div>
        <div class="grid grid-cols-1 md:system:grid-cols-5 gap-6 mt-6 mb-6">
          <SolInputText
            id="neighborhood-field"
            name="installation_address_form.neighborhood"
            class="col-span-2"
            :use-field="useField"
            :label="`${t('formAddress.neighborhood')}`"
            :disabled="disabledAddress && disabledButton"
          />
          <SolInputText
            id="city-field"
            name="installation_address_form.city.name"
            class="col-span-2"
            :label="`${t('formAddress.city')}`"
            :use-field="useField"
            :disabled="true"
          />
          <SolInputText
            id="state-field"
            name="installation_address_form.state.name"
            class="col-span-2 md:system:col-span-1"
            :label="`${t('formAddress.state')}`"
            :use-field="useField"
            :disabled="true"
          />
        </div>
        <!-- Close Company data -->

        <!-- Countryside options -->
        <div>
          <span class="font-bold text-3xs text-brand-primary-pure">{{ t('formAddress.countryside') }}</span>
          <SolRadioGroup
            id="rural_area"
            class="mb-2"
            title=""
            name="installation_address_form.rural_area"
            :use-field="useField"
            direction="row"
            :radios="radiosCountryside"
            :disabled="disabledFieldsAndButtons"
          />
        </div>

        <!-- Bill account -->
        <div>
          <span class="w-full h-[1px] flex bg-neutral-high-medium my-xs" />

          <div>
            <h3 class="font-highlight  text-neutral-low-pure text-[16px] md:system:text-[20px]">
              {{ t('company.energy_bill') }}
            </h3>
            <span class="text-3xs ">{{ t('company.energy_bill_subtitle') }}</span>
          </div>
          <SolRadioGroup
            id="energy_bill_is_the_same_of_client"
            title=""
            name="energy_bill_is_the_same_of_client"
            :use-field="useField"
            direction="row"
            :radios="energyBillIsTheSameOfClientOptions"
            :disabled="disabledFieldsAndButtons"
          />
          <div v-if="values.energy_bill_is_the_same_of_client" class="mt-4xs grid grid-cols-1 md:system:grid-cols-4">
            <SolInputText
              id="electricity_bill_document"
              name="electricity_bill_document"
              :mask="{ preset: 'CPF_CNPJ' }"
              :use-field="useField"
              placeholder="Informe o CPF/CNPJ que consta na conta"
              :disabled="disabledButton"
              class="mb-2 col-span-2 md:system:col-span-1"
            />
          </div>

          <span class="w-full h-[1px] flex bg-neutral-high-medium my-xs" />
        </div>
        <!-- Close Bill account -->

        <!-- Majority Partner -->
        <div>
          <h3 class="fonts-heading-h4 md:system:fonts-heading-h3 text-neutral-low-dark;">
            {{ t('company.majority_section') }}
          </h3>
          <span class="text-neutral-low-light text-2xs">{{ t('company.majority_subtitles') }}</span>
        </div>

        <div>
          <div class="grid grid-cols-1 md:system:grid-cols-5 gap-6 mt-6">
            <SolInputText
              id="name"
              name="majority_partner_person_form.full_name"
              class="col-span-3"
              :label="`${t('form.name')}*`"
              :use-field="useField"
              :disabled="disabledFieldsAndButtons"
            />
            <SolInputText
              id="CPF_Majority"
              name="majority_partner_person_form.cpf"
              class="col-span-1"
              :use-field="useField"
              :label="`${t('form.cpf')}*`"
              :mask="{ preset: 'CPF' }"
              :disabled="!Boolean(errorsCompanyDocuments.identity_matches_document)"
              placeholder="000.000.000-00"
            />
            <SolInputText
              id="birthdate"
              name="majority_partner_person_form.birthdate"
              class="col-span-2 md:system:col-span-1"
              :label="`${t('form.birthdate')}*`"
              :use-field="useField"
              mask="##/##/####"
              placeholder="00/00/0000"
              :disabled="disabledFieldsAndButtons"
            />
          </div>

          <div class="grid grid-cols-1 md:system:grid-cols-3 gap-6 mt-6">
            <SolInputText
              id="personal-email"
              name="majority_partner_person_form.email"
              type="email"
              class="col-span-2"
              :is-loading="loadingEmail"
              :is-success="!!(!errors['majority_partner_person_form.email']) && fieldShouldBeValidated(values.majority_partner_person_form?.email?.length || 0) && !loadingEmail"
              :label="`${t('form.email')} pessoal*`"
              :use-field="useField"
              placeholder="cliente@email.com"
              :success-text="t('form.validatedEmail')"
              :disabled="disabledFieldsAndButtons"
            />
            <SolInputText
              id="phone"
              class="col-span-2 md:system:col-span-1"
              name="majority_partner_person_form.phone"
              :mask="{ preset: 'MobilePhoneDDD' }"
              :label="`${t('form.phone')}*`"
              placeholder="(00) 0 0000-0000"
              :use-field="useField"
              :disabled="disabledFieldsAndButtons"
            >
              <template #icon:left>
                <span>+55</span>
              </template>
            </SolInputText>
          </div>

          <div>
            <h3 class="font-highlight  text-neutral-low-pure text-[16px] md:system:text-[20px] pt-xs">
              {{ t('customer.address_data_title') }}
            </h3>
          </div>

          <div class="grid grid-cols-1 md:system:grid-cols-5 gap-6 mt-6">
            <div class="col-span-2 md:system:col-span-1">
              <div class="ml-9 -mb-4">
                <SimulationTooltip position="right" :text="t('form.cepInfo')" class="tooltip z-100">
                  <IconInfo class="cursor-pointer" />
                </SimulationTooltip>
              </div>
              <SolInputText
                id="cep-field"
                name="majority_partner_address_form.postal_code"
                :use-field="useField"
                mask="#####-###"
                :label="t('formAddress.cep')"
                placeholder="00000-000"
                :is-loading="loadingCep"
                :is-success="!errors['majority_partner_address_form.postal_code'] && !loadingCep && fieldShouldBeValidated(values.majority_partner_address_form?.postal_code?.length || 0) && validateQuantityCharactersCep(values.majority_partner_address_form?.postal_code?.length || 0)"
                :success-text="t('form.validatedCep')"
                :disabled="disabledFieldsAndButtons"
                @blur="fetchAddress('majority_partner_address_form', values?.majority_partner_address_form?.postal_code)"
              />
            </div>
            <SolInputText
              id="street-field"
              name="majority_partner_address_form.street"
              class="col-span-2"
              :use-field="useField"
              :label="`${t('formAddress.street')}`"
              :disabled="!values.majority_partner_address_form?.postal_code || disabledFieldsAndButtons"
            />
            <SolInputText
              id="number-field"
              name="majority_partner_address_form.street_number"
              class="col-span-1"
              :use-field="useField"
              :label="`${t('formAddress.number')}`"
              :disabled="!values.majority_partner_address_form?.postal_code || disabledFieldsAndButtons"
            />
            <SolInputText
              id="complement-field"
              name="majority_partner_address_form.additional_info"
              class="col-span-1"
              :use-field="useField"
              :label="`${t('formAddress.complement')}`"
              :placeholder="t('formAddress.complementPlaceholder')"
              :disabled="!values.majority_partner_address_form?.postal_code || disabledFieldsAndButtons"
            />
          </div>

          <div class="grid grid-cols-1 md:system:grid-cols-5 gap-6 mt-6 mb-6">
            <SolInputText
              id="neighborhood-field"
              name="majority_partner_address_form.neighborhood"
              class="col-span-2"
              :use-field="useField"
              :label="`${t('formAddress.neighborhood')}`"
              :disabled="!values.majority_partner_address_form?.postal_code || disabledFieldsAndButtons"
            />
            <SolInputText
              id="city-field"
              name="majority_partner_address_form.city.name"
              class="col-span-2"
              :label="`${t('formAddress.city')}`"
              :use-field="useField"
              :disabled="true"
            />
            <SolInputText
              id="state-field"
              name="majority_partner_address_form.state.name"
              class="col-span-2 md:system:col-span-1"
              :label="`${t('formAddress.state')}`"
              :use-field="useField"
              :disabled="true"
            />
          </div>
        </div>
        <!-- Close Majority Partner -->

        <div>
          <span class="font-bold text-3xs text-brand-primary-pure">{{ t('company.majority_is_the_same_of_the_legal') }}</span>

          <SolRadioGroup
            id="majority_partner_is_legal_representant"
            class="mb-2"
            title=""
            name="majority_partner_is_legal_representant"
            :use-field="useField"
            direction="row"
            :radios="majorityIsOnlyLegalRepresentantOptions"
            :disabled="disabledFieldsAndButtons"
          />
        </div>

        <div>
          <span class="font-bold text-3xs text-brand-primary-pure">{{ t('company.has_more_than_one_legal_representant') }}</span>
          <SolRadioGroup
            id="has_more_than_one_legal_representant"
            class="mb-2"
            title=""
            name="has_more_than_one_legal_representant"
            :use-field="useField"
            direction="row"
            :radios="hasOnlyOneLegalRepresentantOptions"
            :disabled="disabledFieldsAndButtons"
          />
        </div>

        <!-- Legal representant -->
        <div v-if="!values.majority_partner_is_legal_representant">
          <div class="border-t border-neutral-high-medium mt-10 pt-10">
            <h3 class="fonts-heading-h4 md:system:fonts-heading-h3 text-neutral-low-dark;">
              {{ t('company.legal_representant_section') }}
            </h3>
            <span class="text-neutral-low-light text-2xs">{{ t('company.legal_representant_subtitle') }}</span>
          </div>

          <SolAlert
            v-if="errorsCompanyDocuments.legal_representant_missing"
            id="informative-feedback-receipt-model"
            class="my-6"
            :title="t('company.alertRepresentantLegalMissingTitle')"
            feedback="warning"
          >
            {{ t('company.alertRepresentantLegalMissingMessage') }}
          </SolAlert>

          <div class="grid grid-cols-1 md:system:grid-cols-5 gap-6 mt-6">
            <SolInputText
              id="name"
              name="first_legal_representant.person.full_name"
              class="col-span-3"
              :label="`${t('form.name')}*`"
              :use-field="useField"
              :disabled="disabledFieldsAndButtons && !errorsCompanyDocuments.legal_representant_missing"
            />
            <SolInputText
              id="CPF_First_Legal"
              name="first_legal_representant.person.cpf"
              class="col-span-1"
              :use-field="useField"
              :label="`${t('form.cpf')}*`"
              :mask="{ preset: 'CPF' }"
              placeholder="000.000.000-00"
              :disabled="disabledFieldsAndButtons && !errorsCompanyDocuments.legal_representant_missing && errorsCompanyDocuments.identity_matches_document"
            />
            <SolInputText
              id="birthdate"
              name="first_legal_representant.person.birthdate"
              class="col-span-2 md:system:col-span-1"
              :label="`${t('form.birthdate')}*`"
              :use-field="useField"
              mask="##/##/####"
              placeholder="00/00/0000"
              :disabled="disabledFieldsAndButtons && !errorsCompanyDocuments.legal_representant_missing"
            />
          </div>

          <div class="grid grid-cols-1 md:system:grid-cols-3 gap-6 mt-6">
            <SolInputText
              id="personal-email"
              name="first_legal_representant.person.email"
              type="email"
              class="col-span-2"
              :is-loading="loadingEmail"
              :is-success="!!(!errors['first_legal_representant.person.email']) && fieldShouldBeValidated(values.first_legal_representant?.person?.email?.length || 0) && !loadingEmail"
              :label="`${t('form.email')} pessoal*`"
              :use-field="useField"
              placeholder="cliente@email.com"
              :success-text="t('form.validatedEmail')"
              :disabled="disabledFieldsAndButtons && !errorsCompanyDocuments.legal_representant_missing"
            />
            <SolInputText
              id="phone"
              class="col-span-2 md:system:col-span-1"
              name="first_legal_representant.person.phone"
              :mask="{ preset: 'MobilePhoneDDD' }"
              :label="`${t('form.phone')}*`"
              placeholder="(00) 0 0000-0000"
              :use-field="useField"
              :disabled="disabledFieldsAndButtons && !errorsCompanyDocuments.legal_representant_missing"
            >
              <template #icon:left>
                <span>+55</span>
              </template>
            </SolInputText>
          </div>

          <div>
            <h3 class="font-highlight  text-neutral-low-pure text-[16px] md:system:text-[20px] pt-xs">
              {{ t('customer.address_data_title') }}
            </h3>
          </div>

          <div class="grid grid-cols-1 md:system:grid-cols-5 gap-6 mt-6">
            <div class="col-span-2 md:system:col-span-1">
              <div class="ml-9 -mb-4">
                <SimulationTooltip position="right" :text="t('form.cepInfo')" class="tooltip z-100">
                  <IconInfo class="cursor-pointer" />
                </SimulationTooltip>
              </div>
              <SolInputText
                id="cep-field"
                name="first_legal_representant.address.postal_code"
                :use-field="useField"
                mask="#####-###"
                :label="t('formAddress.cep')"
                placeholder="00000-000"
                :is-loading="loadingCep"
                :is-success="!errors['first_legal_representant.address.postal_code'] && !loadingCep && fieldShouldBeValidated(values.first_legal_representant?.address?.postal_code?.length || 0) && validateQuantityCharactersCep(values.first_legal_representant?.address?.postal_code?.length || 0)"
                :success-text="t('form.validatedCep')"
                :disabled="disabledFieldsAndButtons && !errorsCompanyDocuments.legal_representant_missing"
                @blur="fetchAddress('first_legal_representant.address', values?.first_legal_representant?.address?.postal_code)"
              />
            </div>
            <SolInputText
              id="street-field"
              name="first_legal_representant.address.street"
              class="col-span-2"
              :use-field="useField"
              :label="`${t('formAddress.street')}`"
              :disabled="(!values.first_legal_representant?.address?.postal_code || disabledFieldsAndButtons) && !errorsCompanyDocuments.legal_representant_missing"
            />
            <SolInputText
              id="number-field"
              name="first_legal_representant.address.street_number"
              class="col-span-1"
              :use-field="useField"
              :label="`${t('formAddress.number')}`"
              :disabled="(!values.first_legal_representant?.address?.postal_code || disabledFieldsAndButtons) && !errorsCompanyDocuments.legal_representant_missing"
            />
            <SolInputText
              id="complement-field"
              name="first_legal_representant.address.additional_info"
              class="col-span-1"
              :use-field="useField"
              :label="`${t('formAddress.complement')}`"
              :placeholder="t('formAddress.complementPlaceholder')"
              :disabled="(!values.first_legal_representant?.address?.postal_code || disabledFieldsAndButtons) && !errorsCompanyDocuments.legal_representant_missing"
            />
          </div>
          <div class="grid grid-cols-1 md:system:grid-cols-5 gap-6 mt-6 mb-6">
            <SolInputText
              id="neighborhood-field"
              name="first_legal_representant.address.neighborhood"
              class="col-span-2"
              :use-field="useField"
              :label="`${t('formAddress.neighborhood')}`"
              :disabled="(!values.first_legal_representant?.address?.postal_code || disabledFieldsAndButtons) && !errorsCompanyDocuments.legal_representant_missing"
            />
            <SolInputText
              id="city-field"
              name="first_legal_representant.address.city.name"
              class="col-span-2"
              :label="`${t('formAddress.city')}`"
              :use-field="useField"
              :disabled="true"
            />
            <SolInputText
              id="state-field"
              name="first_legal_representant.address.state.name"
              class="col-span-2 md:system:col-span-1"
              :label="`${t('formAddress.state')}`"
              :use-field="useField"
              :disabled="true"
            />
          </div>
        </div>
        <!-- Close Legal representant -->

        <!-- Second Legal representant -->
        <div v-if="!values.majority_partner_is_legal_representant && !values.has_more_than_one_legal_representant">
          <div class="mt-10">
            <h3 class="fonts-heading-h4 md:system:fonts-heading-h3 text-neutral-low-dark;">
              {{ t('company.second_legal_representant_section') }}
            </h3>
          </div>

          <SolAlert
            v-if="errorsCompanyDocuments.legal_representant_missing"
            id="informative-feedback-receipt-model"
            class="my-6"
            :title="t('company.alertRepresentantLegalMissingTitle')"
            feedback="warning"
          >
            {{ t('company.alertRepresentantLegalMissingMessage') }}
          </SolAlert>

          <div class="grid grid-cols-1 md:system:grid-cols-5 gap-6 mt-6">
            <SolInputText
              id="name"
              name="second_legal_representant.person.full_name"
              class="col-span-3"
              :label="`${t('form.name')}*`"
              :use-field="useField"
              :disabled="disabledFieldsAndButtons && !errorsCompanyDocuments.legal_representant_missing"
            />
            <SolInputText
              id="CPF_Second_Legal"
              name="second_legal_representant.person.cpf"
              class="col-span-1"
              :use-field="useField"
              :label="`${t('form.cpf')}*`"
              :mask="{ preset: 'CPF' }"
              placeholder="000.000.000-00"
              :disabled="disabledFieldsAndButtons && !errorsCompanyDocuments.legal_representant_missing && errorsCompanyDocuments.identity_matches_document"
            />
            <SolInputText
              id="birthdate"
              name="second_legal_representant.person.birthdate"
              class="col-span-2 md:system:col-span-1"
              :label="`${t('form.birthdate')}*`"
              :use-field="useField"
              mask="##/##/####"
              placeholder="00/00/0000"
              :disabled="disabledFieldsAndButtons && !errorsCompanyDocuments.legal_representant_missing"
            />
          </div>

          <div class="grid grid-cols-1 md:system:grid-cols-3 gap-6 mt-6">
            <SolInputText
              id="personal-email"
              name="second_legal_representant.person.email"
              type="email"
              class="col-span-2"
              :is-loading="loadingEmail"
              :is-success="!!(!errors?.['second_legal_representant.person.email']) && fieldShouldBeValidated(values.second_legal_representant?.person?.email?.length || 0) && !loadingEmail"
              :label="`${t('form.email')} pessoal*`"
              :use-field="useField"
              placeholder="cliente@email.com"
              :success-text="t('form.validatedEmail')"
              :disabled="disabledFieldsAndButtons && !errorsCompanyDocuments.legal_representant_missing"
            />
            <SolInputText
              id="phone"
              class="col-span-2 md:system:col-span-1"
              name="second_legal_representant.person.phone"
              :mask="{ preset: 'MobilePhoneDDD' }"
              :label="`${t('form.phone')}*`"
              placeholder="(00) 0 0000-0000"
              :use-field="useField"
              :disabled="disabledFieldsAndButtons && !errorsCompanyDocuments.legal_representant_missing"
            >
              <template #icon:left>
                <span>+55</span>
              </template>
            </SolInputText>
          </div>

          <div>
            <h3 class="font-highlight  text-neutral-low-pure text-[16px] md:system:text-[20px] pt-xs">
              {{ t('customer.address_data_title') }}
            </h3>
          </div>

          <div class="grid grid-cols-1 md:system:grid-cols-5 gap-6 mt-6">
            <div class="col-span-2 md:system:col-span-1">
              <div class="ml-9 -mb-4">
                <SimulationTooltip position="right" :text="t('form.cepInfo')" class="tooltip z-100">
                  <IconInfo class="cursor-pointer" />
                </SimulationTooltip>
              </div>
              <SolInputText
                id="cep-field"
                name="second_legal_representant.address.postal_code"
                :use-field="useField"
                mask="#####-###"
                :label="t('formAddress.cep')"
                placeholder="00000-000"
                :is-loading="loadingCep"
                :is-success="!errors['second_legal_representant.address.postal_code'] && !loadingCep && fieldShouldBeValidated(values.second_legal_representant?.address?.postal_code?.length || 0) && validateQuantityCharactersCep(values.second_legal_representant?.address?.postal_code?.length || 0)"
                :success-text="t('form.validatedCep')"
                :disabled="disabledFieldsAndButtons && !errorsCompanyDocuments.legal_representant_missing"
                @blur="fetchAddress('second_legal_representant.address', values?.second_legal_representant?.address?.postal_code)"
              />
            </div>
            <SolInputText
              id="street-field"
              name="second_legal_representant.address.street"
              class="col-span-2"
              :use-field="useField"
              :label="`${t('formAddress.street')}`"
              :disabled="(!values.second_legal_representant?.address?.postal_code || disabledFieldsAndButtons) && !errorsCompanyDocuments.legal_representant_missing"
            />
            <SolInputText
              id="number-field"
              name="second_legal_representant.address.street_number"
              class="col-span-1"
              :use-field="useField"
              :label="`${t('formAddress.number')}`"
              :disabled="(!values.second_legal_representant?.address?.postal_code || disabledFieldsAndButtons) && !errorsCompanyDocuments.legal_representant_missing"
            />
            <SolInputText
              id="complement-field"
              name="second_legal_representant.address.additional_info"
              class="col-span-1"
              :use-field="useField"
              :label="`${t('formAddress.complement')}`"
              :placeholder="t('formAddress.complementPlaceholder')"
              :disabled="(!values.second_legal_representant?.address?.postal_code || disabledFieldsAndButtons) && !errorsCompanyDocuments.legal_representant_missing"
            />
          </div>
          <div class="grid grid-cols-1 md:system:grid-cols-5 gap-6 mt-6 mb-6">
            <SolInputText
              id="neighborhood-field"
              name="second_legal_representant.address.neighborhood"
              class="col-span-2"
              :use-field="useField"
              :label="`${t('formAddress.neighborhood')}`"
              :disabled="(!values.second_legal_representant?.address?.postal_code || disabledFieldsAndButtons) && !errorsCompanyDocuments.legal_representant_missing"
            />
            <SolInputText
              id="city-field"
              name="second_legal_representant.address.city.name"
              class="col-span-2"
              :label="`${t('formAddress.city')}`"
              :use-field="useField"
              :disabled="true"
            />
            <SolInputText
              id="state-field"
              name="second_legal_representant.address.state.name"
              class="col-span-2 md:system:col-span-1"
              :label="`${t('formAddress.state')}`"
              :use-field="useField"
              :disabled="true"
            />
          </div>
        </div>
        <!-- Close Second Legal representant -->

        <span class="w-full h-[1px] flex bg-neutral-high-medium my-xs" />

        <div class="flex justify-end">
          <SolButton
            id="customer_confirm"
            :loading="loadingConfirmCompanyInformation"
            size="large"
            :disabled="disabledButton"
            @click="confirmCompanyInformation"
          >
            {{ t('app.send') }}
          </SolButton>
        </div>
      </form>
    </div>
  </CustomerElementAccordion>
</template>

<style lang="scss">
.form-company-data{
  @apply mt-sm mb-0;

  .sol-input-core{
    &.-left-icon {
      @apply pl-xl;
    }
  }

  .error-message {
    @apply text-feedback-negative-pure py-nano;
    @apply fonts-subtitle-small;
  }

}

[data-tooltip].tooltip:after {
  width: 250px;
  white-space: break-spaces;
}
</style>
