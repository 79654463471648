import mixpanel from 'mixpanel-browser'

const { rest } = useConnections()

mixpanel.init(rest.mixpanel.key, { debug: true })

const config = import.meta.env

/**
 * Track an event in Mixpanel.
 * @param eventName The name of the event to track.
 * @param properties Optional properties to include with the event.
 */
export function trackEvent(eventName: string, properties?: Record<string, any>) {
  if (config.MODE === 'test')
    return

  mixpanel.track(eventName, properties)
}

/**
 * Set a user profile in Mixpanel.
 * @param userId The ID of the user.
 * @param properties Optional properties to include with the user profile force commit.
 */
export function identifyUser(userId: string, properties?: Record<string, any>) {
  if (config.MODE === 'test')
    return

  mixpanel.identify(userId)
  if (properties)
    mixpanel.people.set(properties)
}
