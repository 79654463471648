<script setup lang="ts">
interface Props {
  messages?: string[]
}

const { messages } = defineProps<Props>()
const messageIndex = ref(0)
let interval: ReturnType<typeof setInterval>

onMounted(() => {
  if (messages) {
    interval = setInterval(() => {
      const nextMessageIndex = messageIndex.value + 1
      const messagesLength = messages.length - 1

      messageIndex.value = nextMessageIndex > messagesLength ? 0 : nextMessageIndex
    }, 5000)
  }
})

onUnmounted(() => {
  clearInterval(interval)
})
</script>

<template>
  <div class="loader-wrapper flex flex-col justify-center">
    <span class="spinner" />
    <span v-if="messages" class="message">{{ messages[messageIndex] }}</span>
  </div>
</template>

<style scoped lang="scss">
.loader-wrapper {
  @apply w-52;

  @screen sm:system {
    @apply w-full;
  }

  .spinner {
    @apply h-24;
    background: rgba(#fff, 0.9) url(https://cdn.solfacil.com.br/assets/img/loading-for-legacy.gif) no-repeat center center;
  }

  .message {
    @apply text-center text-neutral-low-light;
  }
}
</style>
