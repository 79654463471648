import type { RestConnections } from '~/types'

export default {
  hmla2: {
    key: '646d10382a256b129562e331',
  },
  hmla: {
    key: '646d10382a256b129562e331',
  },
  dev: {
    key: '646d10382a256b129562e331',
  },
  prod: {
    key: '646d10382a256b129562e332',
  },
  stg: {
    key: '646d10382a256b129562e331',
  },
} as RestConnections
