import type { RestConnections } from '~/types'

export default {
  dev: {
    url: 'https://stg-solfacil-plus-api.solfacil.com.br/api/',
    key: '77a991a1-9086-4e88-a7db-0143c940cb96',
  },
  prod: {
    url: 'https://solfacil-plus-api.solfacil.com.br/api/',
  },
  stg: {
    url: 'https://stg-solfacil-plus-api.solfacil.com.br/api/',
  },
  hmla: {
    url: 'https://hmla-solfacil-plus-api.solfacil.com.br/api/',
  },
  hmla2: {
    url: 'https://hmla-solfacil-plus-api.solfacil.com.br/api/',
  },
  hmla3: {
    url: 'https://hmla-solfacil-plus-api.solfacil.com.br/api/',
  },
  hmla4: {
    url: 'https://hmla-solfacil-plus-api.solfacil.com.br/api/',
  },
  hmlb: {
    url: 'https://hmla-solfacil-plus-api.solfacil.com.br/api/',
  },
  hmlb2: {
    url: 'https://hmla-solfacil-plus-api.solfacil.com.br/api/',
  },
  hmlc: {
    url: 'https://hmla-solfacil-plus-api.solfacil.com.br/api/',
  },
  hmlc2: {
    url: 'https://hmla-solfacil-plus-api.solfacil.com.br/api/',
  },
  hmld: {
    url: 'https://hmla-solfacil-plus-api.solfacil.com.br/api/',
  },
  hmld2: {
    url: 'https://hmla-solfacil-plus-api.solfacil.com.br/api/',
  },
  hmlf: {
    url: 'https://hmla-solfacil-plus-api.solfacil.com.br/api/',
  },
  hmlf2: {
    url: 'https://hmla-solfacil-plus-api.solfacil.com.br/api/',
  },
  hmlg: {
    url: 'https://hmla-solfacil-plus-api.solfacil.com.br/api/',
  },
  hmlg2: {
    url: 'https://hmla-solfacil-plus-api.solfacil.com.br/api/',
  },
  hmlz: {
    url: 'https://hmla-solfacil-plus-api.solfacil.com.br/api/',
  },
} as RestConnections
