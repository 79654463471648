import { defineStore } from 'pinia'

interface StoreToolsState {
  id: string
}

export const useStoreToolsFinancing = defineStore('store-tools', {
  state: (): StoreToolsState => ({
    id: '',
  }),
  actions: {
    setShoppingCartId(value: string) {
      this.id = value
    },
  },
  getters: {
    idValue: state => state.id,
  },
})
