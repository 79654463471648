<script setup lang="ts">
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { SolAlert } from '@solfacil/girassol'

const {
  needRefresh,
  offlineReady,
  updateServiceWorker,
} = useRegisterSW()

async function close() {
  offlineReady.value = false
  needRefresh.value = false
}
</script>

<template>
  <div class="notification">
    <SolAlert
      v-if="needRefresh"
      id="refresh"
      feedback="success"
      text="Nova atualização disponivel!"
      action="Recarregar página"
      close-button
      @click="updateServiceWorker()"
      @close="close()"
    />

    <SolAlert
      v-if="offlineReady"
      id="offline"
      feedback="success"
      text="Aplicação pronta para uso offline!"
      close-button
      @close="close()"
    />
  </div>
</template>

<style scoped>
.notification {
  position: fixed !important;
  right: 20px;
  top: 20px;
  width: fit-content;
  z-index: 99;
}
</style>
