import type { App } from 'vue'
import type { AvailableConnection } from '~/types'

export const ENVIRONMENT = 'environment'
const ENV_PARAM = 'env'

export default {
  install: (_app: App, options?: AvailableConnection) => {
    const isProduction = import.meta.env.MODE === 'production'
    const isStaging = import.meta.env.MODE === 'staging'

    const queries = new URLSearchParams(decodeURIComponent(getParams()))

    const environment = obtainEnvironment(queries, isProduction, isStaging, options)

    localStorage.setItem(ENVIRONMENT, environment)
  },
}

function getParams(): string {
  return location.search
}

function obtainEnvironment(
  queries: URLSearchParams,
  isProduction: boolean,
  isStaging: boolean,
  options?: AvailableConnection,
): AvailableConnection {
  let environment = getEnvironmentType(queries, options)
  if (isProduction)
    environment = options || 'prod'

  if (isStaging && environment === 'dev')
    environment = options || 'stg'

  return environment
}

function getEnvironmentType(queries: URLSearchParams, options?: AvailableConnection): AvailableConnection {
  return queries.get(ENV_PARAM) as AvailableConnection ?? (options || 'dev')
}
