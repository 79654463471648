<script setup lang="ts">
const props = defineProps<{
  blockFields: boolean
}>()

function handleClick(event: MouseEvent) {
  if (props.blockFields)
    event.stopPropagation()
}
</script>

<template>
  <div :class="{ disabled: props.blockFields }" @click="handleClick">
    <slot><div /></slot>
  </div>
</template>

<style scoped lang="scss">
.disabled{
  @apply opacity-50 pointer-events-none cursor-not-allowed;
}
</style>
