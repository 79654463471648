<script lang="ts" setup>
import type { OptionsFilters } from '~/utils/financing-flow/Financing'
import IconClear from '~icons/material-symbols/close'

defineProps<{
  optionsFiltersList: OptionsFilters
}>()

const emit = defineEmits<(e: 'sendFilters', v: { [key: string]: string }) => void>()
const { t } = useI18n()

const selectedFilters = ref<{ [key: string]: string }>({
  statusField: '',
  stepsField: '',
})

function updateSelectedFilter(filterName: string, value: string) {
  selectedFilters.value[filterName] = value
  emit('sendFilters', selectedFilters.value)
}

function clearFilters() {
  Object.keys(selectedFilters.value).forEach((key) => {
    selectedFilters.value[key] = ''
  })
  emit('sendFilters', selectedFilters.value)
}
</script>

<template>
  <div class="shared-filters h-[52px] relative w-full">
    <p class="text-3xs leading-4 mb-2 md:system:mb-0">
      {{ optionsFiltersList.title }}
    </p>
    <div class="flex gap-2">
      <div
        v-for="filter in optionsFiltersList.filters"
        :key="filter.id"
        class="w-full md:system:w-auto"
      >
        <SolSelect
          v-if="filter"
          :id="filter.id"
          :class="filter.class"
          :placeholder="filter.placeholder"
          :size="filter.size"
          :name="filter.name"
          :label="filter.label"
          :options="filter.options"
          :selected="selectedFilters[filter.name]"
          @update:selected="value => updateSelectedFilter(filter.name, value)"
        />
      </div>
      <button
        v-if="selectedFilters.statusField || selectedFilters.stepsField"
        class="text-micro font-bold flex gap-1 items-center absolute right-0 top-0 md:system:static"
        @click="clearFilters"
      >
        <IconClear /> {{ selectedFilters.statusField && selectedFilters.stepsField ? t('financing.filters.clear', 0) : t('financing.filters.clear', 1) }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.shared-filters {
  :deep(.select-options-container) {
    @apply w-full md:system:w-auto md:system:min-w-56;
  }

  :deep(#select-toggle-status-field span) {
    @apply whitespace-nowrap;
  }
}
</style>
