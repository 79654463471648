import BaseService from '../BaseService'
import type SolfacilPlus from '~/utils/solfacilPlus/solfacilPlus'
import type { ResponseModel } from '~/utils/connections/rest/RestConnections'

export default class SolfacilPlusService extends BaseService {
  async getPartnerSolfacilPlusInfo(partnerId: number): Promise<ResponseModel<SolfacilPlus>> {
    try {
      const response = await super.api<ResponseModel<SolfacilPlus>>(`/partner/${partnerId}`, {
        method: 'GET',
      })
      return response
    }
    catch (e) {
      return Promise.reject(e)
    }
  }
}
