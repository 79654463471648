import type { OptionsForm } from '~/utils/installation/Installation'

export const getHardwareListLabels = (item: OptionsForm): string => {
  if (item.power && item.brand)
    return `${item.brand} - ${item.name} - ${item.power}W`

  if (item.power)
    return `${item.name} - ${item.power}W`

  return `${item.name}`
}
