<script setup lang="ts">
import { CurrencyDisplay, useCurrencyInput } from 'vue-currency-input'
type Size = 'small' | 'medium'
type ModelValue = string | number
interface InputCore {
  id: string
  modelValue: ModelValue
  error?: boolean
  size?: Size
  hasFeedbackIcon?: boolean
}

const props = defineProps<{
  id: string
  required?: boolean
  modelValue?: number
  name: string
  disabled: boolean
  label?: string
  error?: string
  size?: InputCore['size']
}>()

const emit
  = defineEmits<{
    (e: 'update:modelValue', event: number): void
  }>()

const { inputRef, numberValue } = useCurrencyInput({
  currency: 'BRL',
  locale: 'pt-BR',
  currencyDisplay: CurrencyDisplay.hidden,
  hideCurrencySymbolOnFocus: true,
  hideGroupingSeparatorOnFocus: false,
  hideNegligibleDecimalDigitsOnFocus: false,
  autoDecimalDigits: true,
  useGrouping: true,
  accountingSign: false,
}, false)

watch(numberValue, (value) => {
  if (value)
    emit('update:modelValue', value)
})
</script>

<template>
  <div
    :id="`input-system-power-${id}`"
    :data-testid="`input-system-power-${id}`"
    :class="[`${$props.class}`, { '-disabled': disabled }]"
    class="sol-input-icon-core"
  >
    <label
      v-if="label"
      :for="`input-system-power-input-${id}`"
      :data-testid="`input-system-power-label-${id}`"
      class="label"
      :class="`-${size}`"
      :data-required="required"
    >
      {{ label }}
    </label>
    <div class="container-input" :class="`-${size}`">
      <input
        ref="inputRef"
        :disabled="disabled"
        class="input-system-power input"
        :class="[`-${size}`, { '-error': error }]"
        :name="props.name"
        type="text"
      >
    </div>
    <span
      v-if="error"
      :id="`input-system-power-describe-${id}`"
      :data-testid="`input-system-power-describe-${id}`"
      class="message -error"
    >
      {{ error }}
    </span>
  </div>
</template>

<style lang="scss" scoped>
.input-system-power {
  @apply relative;
  @apply w-full;
  @apply border-a-xs border-neutral-high-medium rounded-md;
  @apply bg-neutral-high-pure text-neutral-low-medium;
  @apply cursor-text appearance-none outline-none;
  &.-small {
    @apply h-xs px-micro;
    @apply fonts-body-medium-regular;
    &.-feedback-icon {
      @apply pr-[36px];
    }
  }
  &.-medium {
    @apply h-md px-4xs;
    @apply fonts-body-large-regular;
    &.-feedback-icon {
      @apply pr-md;
    }
  }
  &:focus-visible {
    @apply border-neutral-low-dark;
  }
  &:not(:placeholder-shown):invalid,
  &.-error {
    @apply border-feedback-negative-pure;
  }
  &:disabled {
    @apply bg-neutral-high-light;
    @apply border-neutral-high-medium;
    @apply cursor-default;
  }
  &::placeholder {
    @apply text-neutral-high-dark;
  }
  &[type="search"]:placeholder-shown::-webkit-search-cancel-button {
    @apply hidden;
  }
  &[type="search"]::-webkit-search-cancel-button {
    @apply appearance-none;
    @apply inline-block;
    @apply m-0 ml-nano;
    @apply icon-size-small;
    @apply opacity;
    margin-top: 2px;
    background:
      url('data:image/svg+xml;utf8,<svg%20%20xmlns=%27http://www.w3.org/2000/svg%27%20viewBox="0%200%2024%2024"><path%20fill="black"%20d="m12%2013.4l-4.9%204.9q-.275.275-.7.275q-.425%200-.7-.275q-.275-.275-.275-.7q0-.425.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7q0-.425.275-.7q.275-.275.7-.275q.425%200%20.7.275l4.9%204.9l4.9-4.9q.275-.275.7-.275q.425%200%20.7.275q.275.275.275.7q0%20.425-.275.7L13.4%2012l4.9%204.9q.275.275.275.7q0%20.425-.275.7q-.275.275-.7.275q-.425%200-.7-.275Z"/></svg>');
  }
}

.sol-input-icon-core {
  @apply flex flex-col;
  > .label {
    @apply relative;
    @apply mb-quark;
    @apply text-left;
    &.-small {
      @apply fonts-body-small-bold;
      @apply text-neutral-low-dark;
    }
    &.-medium {
      @apply fonts-body-medium-bold;
      @apply text-neutral-low-dark;
    }
    &[data-required='true']::after {
      @apply top-3/10 right-0;
      content: '*';
    }
  }
  > .container-input {
    @apply relative;
    > .icon-position {
      @apply absolute;
      top: 25%;
      &.-medium {
        @apply right-4xs;
        @apply h-2xs w-2xs;
      }
      &.-small {
        @apply right-micro;
        @apply h-4xs w-4xs;
        top: 22%;
      }
    }
    > .icon-position > * {
      width: inherit;
      height: inherit;
    }
  }
  > .container-input {
    &::after {
      @apply absolute;
      @apply pointer-events-none;
      @apply z-2;
      @apply text-neutral-low-light;
      content: 'kWp';
    }
    &.-medium {
      &::after {
        @apply fonts-body-large-regular px-micro bg-neutral-high-light flex justify-center align-middle rounded-r-md border-neutral-high-medium border-l-xs;
        margin: 1px 1px 0 0;
        height: calc(100% - 2px);
        top: 0;
        right: 0;
        align-items: center;
      }
      > .input {
        @apply pr-lg;
        padding-top: 2px;
      }
    }
    &.-small {
      &::after {
        @apply top-3/10 left-2;
        @apply fonts-body-medium-regular;

      }
      > .input {
        @apply pl-lg;
      }
    }
  }
  > .message {
    @apply mt-quark;
    @apply fonts-body-small-regular text-left;
    &.-helper {
      @apply text-neutral-low-light;
    }
    &.-loading {
      @apply text-feedback-informative-pure;
    }
    &.-success {
      @apply text-feedback-positive-pure;
    }
    &.-error {
      @apply text-feedback-negative-pure;
    }
  }
  &.-disabled {
    > .label,
    > .message {
      @apply text-neutral-high-dark;
    }
    > .container-input > .icon-position {
      @apply text-neutral-high-light;
    }
  }
}
</style>
