export function conventionalNameFormat(name: string) {
  const splittedName = name.toLowerCase().split(' ')

  const exceptions = new Set(['de', 'da', 'do', 'dos', 'das', 'e', 'ou', 'para', 'com'])

  return splittedName.map((part: string) => {
    if (exceptions.has(part))
      return part

    return part.charAt(0).toUpperCase() + part.slice(1)
  }).join(' ')
}
