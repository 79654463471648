import type {
  AvailableConnection,
  Connections,
  GraphQLConnection,
  GraphQLConnections,
  KeycloakConnection,
  KeycloakConnections,
  NormalizedConnections,
  RestConnection,
  RestConnections,
} from '~/types'
import connections from '~/utils/connections'

export function useConnections(): NormalizedConnections {
  const environment = useEnvironment()

  const rest = generateConnections(environment, connections.rest) as NormalizedConnections['rest']
  const keycloak = obtainConnection(environment, connections.keycloak) as KeycloakConnection

  return {
    rest,
    keycloak,
  }
}

function generateConnections(environment: AvailableConnection, connections: Connections['rest']): NormalizedConnections['rest'] {
  const values: NormalizedConnections['rest'] = {}

  for (const key in connections) {
    const graphqlConnections = connections[key]
    Object.assign(values, { [key]: obtainConnection(environment, graphqlConnections) })
  }

  return values
}

function obtainConnection(environment: AvailableConnection, connections: RestConnections | GraphQLConnections | KeycloakConnections): RestConnection | GraphQLConnection | KeycloakConnection {
  return connections[environment] || connections.dev
}
