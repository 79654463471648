import type { RestConnections } from '~/types'

export default {
  hmla: {
    url: 'https://hmla-customer-register-service.solfacil.com.br',
    key: '77a991a1-9086-4e88-a7db-0143c940cb96',
  },
  dev: {
    url: 'https://stg-contract-formalization.solfacil.com.br',
    key: '$2a$12$ZVD3Gt7Ri7Gwfg3Bx.Ygy.VRqBXLogILKYAYqo7ucayaTne3MMxtW',
  },
  prod: {
    url: 'https://contract-formalization.solfacil.com.br',
    key: '$2a$12$ZVD3Gt7Ri7Gwfg3Bx.Ygy.VRqBXLogILKYAYqo7ucayaTne3MMxtW',
  },
  stg: {
    url: 'https://stg-contract-formalization.solfacil.com.br',
    key: '$2a$12$ZVD3Gt7Ri7Gwfg3Bx.Ygy.VRqBXLogILKYAYqo7ucayaTne3MMxtW',
  },
} as RestConnections
