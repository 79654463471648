<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import { SolInputText, SolSelect } from '@solfacil/girassol'
import { useField, useForm } from 'vee-validate'
import { z } from 'zod'
import useProject from '~/store/projects/useProject'
import useBnplSummary from '~/store/bnpl/useBnplSummary'
import Loading from '~/components/loading/index.vue'
import currencyUtils from '~/utils/currency'
import IconInfo from '~icons/material-symbols/info-outline'
import IconCheck from '~icons/material-symbols/check-circle-rounded'
import IconShare from '~icons/material-symbols/share-outline'
import IconOpenInNew from '~icons/material-symbols/open-in-new'
import useBnplInstallments from '~/store/bnpl/useBnplInstallments'
import type { SimulatorV3 } from '~/services/simulator-v3/simulator.types'
import InstallmentList from '~/components/simulation/element/InstallmentList.vue'

const { track } = useMixpanel()
const { t } = useI18n()
const router = useRouter()

const id = router.currentRoute.value.params.id as string

const installmentOption = ref<SimulatorV3.Condition['type']>('CUSTOMER_NOT_PAYS_FEE')

const PLURAL = 1
const SINGULAR = 0
const valueOfProject = ref(0) as Ref<number>
const conditionType = ref<SimulatorV3.ConditionType>('CUSTOMER_PAYS_FEE')
const kitValue = ref(0) as Ref<number>
const serviceValue = ref(0) as Ref<number>

const {
  data: project,
  isLoading: isLoadingProject,
  error: errorProject,
  isError: isErrorProject,
  isFetching: isFetchingProject,
} = useProject(id)

const {
  data: installments,
  isLoading: isLoadingBnplInstallment,
  error: errorBnplInstallment,
  isError: isErrorBnplInstallment,
  isFetching: isFetchingBnplInstallment,
} = useBnplInstallments(id)

const {
  data: summary,
  isLoading: isLoadingBnplSummary,
  error: errorBnplSummary,
  isError: isErrorBnplSummary,
  isFetching: isFetchingBnplSummary,
} = useBnplSummary(id)

const handleAccordionChange = (isOpen: boolean) => {
  const app = document.querySelector('#app main div')

  if (isOpen)
    app?.classList.add('sol-overlay-core')
  else
    app?.classList.remove('sol-overlay-core')
}

watchEffect(async () => {
  if (!isLoadingBnplInstallment.value) {
    await nextTick()
    const accordion = document.getElementById('accordion-accordion-bnpl-mobile')

    if (accordion) {
      const observer = new MutationObserver((mutationsList) => {
        for (const mutation of mutationsList) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'open') {
            const isOpen = accordion.getAttribute('open') !== null
            handleAccordionChange(isOpen)
          }
        }
      })
      observer.observe(accordion, { attributes: true })
    }
  }
})

// Zod
const validationSchema = toTypedSchema(z.object({
  installments: z.object({
    name: z.string().min(1, t('form.required')),
    value: z.number().min(1, t('form.required')),
  }),
  number_order: z.string().min(1, t('form.required')),
}))

const initialValues = computed(() => ({
  installments: { name: 'Selecione', value: 0 },
  number_order: '',
}))

const { values, setValues, setFieldError, validate, errors, handleSubmit } = useForm({
  validationSchema,
  initialValues: initialValues.value,
})

function getQuantityOfInstallment(max: number) {
  const installments: { name: string; value: number }[] = []
  for (let i = 1; i <= max; i++)
    installments.push({ name: `${i}x`, value: i })
  return installments
}

const quantityOfInstallment = computed (() => getQuantityOfInstallment(project.value?.bnpl.max_installments || 1))

watch(() => installments.value, () => {
  if (installments.value) {
    valueOfProject.value = installments.value.values.project_value
    kitValue.value = installments.value?.values.estimated_kit_value
    serviceValue.value = installments.value?.values.service_value
    setValues({
      installments: {
        name: `${installments.value.values.installments || 1}x`,
        value: installments.value.values.installments || 1,
      },
    })
  }
})

const getInitialDataInstallments = () => {
  if (installments.value) {
    valueOfProject.value = installments.value?.values.project_value
    kitValue.value = installments.value?.values.estimated_kit_value
    serviceValue.value = installments.value?.values.service_value
    setValues({
      installments: {
        name: `${installments.value.values.installments || 1}x`,
        value: installments.value.values.installments || 1,
      },
    })
  }
}

onMounted(() => {
  getInitialDataInstallments()
  track('simulation-bnpl-detail_page-view', { trigger: 'Page view - Tela de parcelas BNPL' })
})

const backPage = () => {
  // TODO: Lógica para botão voltar
  track('simulation-detail_button_voltar', { trigger: 'Clique no botão voltar' })
}

const nextFormalization = () => {
  // TODO: lógica para botão formalizar
  const payload = {
    projectValue: valueOfProject.value,
    installments: values?.installments?.value,
    kitValue: kitValue.value,
    serviceValue: serviceValue.value,
    conditionType: conditionType.value,
  }
  track('simulation-detail_button_seguir-para-formalizacao', { trigger: 'Clique no botão de Seguir para formalização', payload })
}
</script>

<template>
  <div class="simulation-bnpl">
    <NavigationHeaderBar
      :id="project?.id"
      stage="bnpl"
      :title="project?.project_name"
      :bnpl-status="project?.status"
    />
    <div class="container grid grid-cols-1 gap-micro mt-micro">
      <!-- loading state -->
      <template v-if="isLoadingBnplInstallment">
        <div class="grid gap-6 grid-cols-1 md:system:gris-cols-2">
          <div class="rounded-lg bg-neutral-high-pure min-h-40 flex items-center justify-center">
            <Loading />
          </div>
        </div>
      </template>
      <!-- success state -->
      <template v-else>
        <div class="grid gap-6 grid-cols-1 md:system:gris-cols-2">
          <div class="grid grid-cols-1 md:system:grid-cols-[8fr,4fr] lg:system:grid-cols-[9fr,3fr] w-full gap-6 relative">
            <!-- Installment -->
            <main class="block w-full rounded-lg bg-neutral-high-pure p-3 md:system:p-8">
              <div class="mb-4">
                <div class="mb-1">
                  <span class="fonts-body-large-bold text-neutral-low-dark">
                    {{ t('simulation.bnpl.installment_title', PLURAL) }}
                  </span>
                </div>
                <div class="mb-4">
                  <p class="fonts-body-medium-regular text-neutral-low-light">
                    {{ t('simulation.bnpl.conditions_installment') }}
                  </p>
                </div>
              </div>

              <form class="mb-6">
                <div class="grid grid-cols-1 gap-4 md:system:grid-cols-2 lg:system:grid-cols-3">
                  <InputMoneyInput
                    id="project_value"
                    v-model="valueOfProject"
                    name="project_value"
                    size="medium"
                    :label="t('simulation.bnpl.project_value')"
                    type="text"
                  />
                  <SolSelect
                    id="installments"
                    name="installments"
                    :label="t('simulation.bnpl.installment_title', SINGULAR)"
                    :use-field="useField"
                    :options="quantityOfInstallment"
                  />
                  <div>
                    <div class="flex justify-left flex-start flex-row ">
                      <div class="sol-input-text-core relative">
                        <label class="label -medium">
                          {{ t('simulation.bnpl.due_date') }}
                        </label>

                        <div class="h-[20px] w-[20px] absolute top-[-2px] right-[-25px] flex justify-center items-end">
                          <SimulationTooltip position="right" :text="t('simulation.bnpl.invoice_due_date')">
                            <IconInfo class="cursor-pointer" />
                          </SimulationTooltip>
                        </div>
                      </div>
                    </div>

                    <SolSelect
                      id="due_date"
                      name="due_date"
                      :selected="{ name: 'Todo dia 1', value: 1 }"
                      :options="[{ name: 'Todo dia 1', value: 1 }, { name: 'Todo dia 15', value: 15 }]"
                    />
                  </div>
                  <InputMoneyInput
                    id="kit_value"
                    v-model="kitValue"
                    size="medium"
                    name="kit_value"
                    :label="t('simulation.bnpl.kit_value')"
                    :helper-text="t('simulation.bnpl.kit_value_helper')"
                  />
                  <SolInputText
                    id="number_order"
                    name="number_order"
                    size="medium"
                    :use-field="useField"
                    :label="t('simulation.bnpl.number_order')"
                  />

                  <InputMoneyInput
                    id="service_value"
                    v-model="serviceValue"
                    size="medium"
                    name="service_value"
                    :label="t('simulation.bnpl.service_value')"
                    :helper-text="t('simulation.bnpl.service_value_helper')"
                  />
                </div>
              </form>

              <!-- Installments options -->
              <div class="grid grid-cols-1 gap-4 md:system:grid-cols-2">
                <template v-for="condition in installments?.conditions" :key="condition.id">
                  <div class="grid gap-2">
                    <template v-if="condition.type.includes('CUSTOMER_PAYS')">
                      <span class="fonts-body-medium-regular text-neutral-low-dark">
                        {{ t('simulation.bnpl.installments.customer_pays.title').split("*")[0] }}
                        <strong>
                          {{ t('simulation.bnpl.installments.customer_pays.title').split("*")[1] }}
                        </strong>
                      </span>
                      <span class="fonts-body-small-regular text-neutral-low-light max-lines-2">
                        {{ t("simulation.bnpl.installments.customer_pays.description") }}
                      </span>
                    </template>
                    <template v-else>
                      <span class="fonts-body-medium-regular text-neutral-low-dark">
                        {{ t('simulation.bnpl.installments.customer_not_pays.title').split("*")[0] }}
                        <strong>
                          {{ t('simulation.bnpl.installments.customer_not_pays.title').split("*")[1] }}
                        </strong>
                      </span>
                      <span class="fonts-body-small-regular text-neutral-low-light max-lines-2">
                        {{ t('simulation.bnpl.installments.customer_not_pays.description') }}
                      </span>
                    </template>

                    <div
                      class=" border-neutral-high-medium border-[2px] rounded-16px min-h-40 p-4 grid gap-2 relative cursor-pointer"
                      :class="{ 'border-brand-secondary-pure': installmentOption === condition.type }"
                      @click="() => installmentOption = condition.type"
                    >
                      <div class="absolute top-4 right-4 h-auto w-auto">
                        <IconCheck
                          v-if="installmentOption === condition.type"
                          class="h-[20px] w-[20px] text-brand-secondary-pure"
                        />

                        <div v-else class="h-[18px] w-[18px] border-[2px] rounded-full border-neutral-high-medium" />
                      </div>

                      <div class="grid">
                        <span class="fonts-body-small-regular text-neutral-low-dark">
                          {{ t('simulation.bnpl.installment_amount') }}
                        </span>
                        <span class="fonts-body-medium-regular text-neutral-low-light">
                          {{ currencyUtils.formatBRL(condition.installment_value) }}
                        </span>
                      </div>

                      <div class="grid">
                        <span class="fonts-body-small-regular text-neutral-low-dark">
                          {{ t('simulation.bnpl.initial_amount') }}
                        </span>
                        <span class="fonts-body-medium-regular text-neutral-low-light">
                          {{ currencyUtils.formatBRL(condition.down_payment_value) }}
                        </span>
                      </div>

                      <div class="grid">
                        <span class="fonts-body-small-regular text-neutral-low-dark">
                          {{ t('simulation.bnpl.receive_amount') }}
                        </span>
                        <span class="fonts-body-medium-regular text-neutral-low-light">
                          {{ currencyUtils.formatBRL(condition.project_value_calculated) }}
                        </span>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </main>

            <!-- Summary -->
            <section class="md:system:sticky top-4 right-0 h-max z-100">
              <SimulationBnplSummary
                :loading="isLoadingBnplSummary" :data="summary || null"
                class="hidden md:system:block"
              />

              <SolAccordion
                id="accordion-bnpl-mobile"
                class="w-full fixed left-0 bottom-0 bg-neutral-high-pure rounded-t-lg shadow-moderate z-20 md:system:hidden"
              >
                <template #title>
                  <div class="flex items-center">
                    <div class="fonts-heading-h2 text-neutral-low-dark">
                      {{ t('simulation.bnpl.id.summary.title.summary') }}
                    </div>
                    <div class="flex text-neutral-low-light fonts-body-x-large-regular">
                      <div class="mx-2">
                        |
                      </div>
                      <div>{{ t('simulation.bnpl.id.summary.title.service') }}</div>
                    </div>
                  </div>
                </template>

                <SimulationBnplSummary :loading="isLoadingBnplSummary" :data="summary || null" />
              </SolAccordion>
            </section>

            <footer>
              <div class="flex flex-col items-center justify-between mt-4xs md:system:flex-row flex-col-reverse gap-5">
                <div class="w-full justify-center flex items-center">
                  <SolButton
                    id="back"
                    class="w-full  m-0 md:system:justify-start"
                    variant="tertiary"
                    size="large"
                    @click="backPage()"
                  >
                    {{ t('buttons.back') }}
                  </SolButton>
                </div>
                <div class="flex flex-col w-full justify-end md:system:flex-row flex-col-reverse gap-5">
                  <SolButton
                    id="customer-next-installation"
                    size="large"
                    class="w-full md:system:w-auto"
                    @click="nextFormalization()"
                  >
                    <template #icon:right>
                      <IconOpenInNew class="icon" />
                    </template>
                    <span>
                      {{ t('simulation.return_formalization') }}
                    </span>
                  </SolButton>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.simulation-bnpl {
  :deep(.container-input) {
    @apply m-0;
  }
  :deep(.header .steps-container) {
    @screen md:system{
      @apply w-[25%];
    }
  }
}
</style>

<route lang="yaml">
meta:
  layout: simulation
</route>
