export type FileStatusType = 'waiting' | 'processing' | 'error' | 'success'
export type DocumentStatusType = 'approved' | 'reproved' | 'pending'
export type typeSection = 'installation' | 'inverters' | 'ampera'

export interface DocumentType {
  id: number
  type: string
  file_url: string
  filename: string
  file_type: string
  status: {
    id: number
    slug: string
    description: string
  }
}

interface Status {
  slug: DocumentStatusType
  created_at: string
  id: number
  description: string
  updated_at: string
  canceled_at: string | null
}

interface DocumentSection {
  type: string
  filename: string
  section_id: string
  status_id: number
  created_at: string
  updated_at: string
  filetype: string
  id: string
  url: string
  canceled_at: string | null
  status: Status
}

export interface SectionDocument {
  type: typeSection
  installation_id: string
  created_at: string
  updated_at: string
  id: string
  status_id: number
  canceled_at: string | null
  status: Status
  documents: DocumentSection[]
}

export interface ResponseInstallation {
  id: string
  project_name: string
  project_id: string
  plant_url: string
  plant_id: string
  concluded_at: string
  has_ampera: boolean
  has_ampera_activated: boolean
  has_minimal_projects: boolean
  sections: SectionDocument[]
  status: {
    id: number
    slug: FileStatusType
    description: string
  }
  documents: DocumentType[]
  comment: string
}

export interface StatusInstallation {
  img?: {
    name?: string
    alt?: string
    title?: string
  }
  title?: string
  subtitle?: string
}

export interface Options {
  name: string
  value: string
}

// Hardware
export interface OptionsForm {
  name: string
  combo_id: string
  power?: number
  brand?: string
  id?: number
}

export interface OptionsModel {
  id: number
  created_at: string
  canceled_at: string | null
  name: string
  updated_at: string
  slug?: string
}

export enum InverterTypeEnum {
  STRING = 'string',
  MICROINVERTER = 'microinverter',
}

export interface HardwareCombos {
  inverters: OptionsForm[]
  microinverters: OptionsForm[]
  modules: OptionsForm[]
  phases: OptionsForm[]
  distributors: OptionsForm[]
}

export interface HardwareCombosSelect {
  inverters: Options[]
  microinverters: Options[]
  modules: Options[]
  phase: Options[]
  distributor: Options[]
}

export enum HardwareMirrorStatus {
  PENDING = 'pending',
  REPROVED = 'reproved',
  APPROVED = 'approved',
}

export interface HardwareComboMirrorMetadata {
  document_url: string
  status: HardwareMirrorStatus
  reason: string | null
}

export interface HardwareCombosModel {
  id: string
  is_combo: boolean
  is_showcase: boolean | null
  project_value: number
  project_id: string
  distributor_id: string
  phase_id: string
  created_at: string
  updated_at: string
  inverter_type: InverterTypeEnum
  microinverters: Equipment[] | null
  inverters: Equipment[]
  modules: Equipment[]
  distributor: OptionsModel
  phase: OptionsModel
  combo_metadata: HardwareBodyDraft | null
  mirror_metadata: HardwareComboMirrorMetadata | null
}

export interface MinMAX {
  min: number
  max: number
}

export interface PowerRange {
  price_per_watt: MinMAX
  overload: MinMAX
  max_percentage_of_service_with_combo: MinMAX
}

export interface Equipment {
  quantity: number
  name: string
  id: number
}

export interface EquipmentSend {
  quantity: number
  power?: string
  id: number
}

export interface HardwareBodySave {
  is_combo: boolean
  is_showcase: boolean
  id?: string
  combo_price?: number
  inverter_type?: string
  project_id: string
  distributor_id?: number | null
  phase_id?: number | null
  inverters?: EquipmentSend[]
  modules?: EquipmentSend[]
  shopping_cart_id?: string
  combo_metadata?: HardwareBodyDraft | null
}

export interface ResponseHardwareSave {
  overload_is_valid: boolean
  price_per_watt_is_valid: boolean
  percentage_of_service_with_combo_is_valid: boolean
}

export interface EquipmentDraft {
  quantity: number
  power: number
  sku: string
  brand: string
  model: string
}

export interface HardwareBodyDraft {
  project_id: string
  sku: string
  power: number
  price: number
  inverters: EquipmentDraft[]
  modules: EquipmentDraft[]
}

export interface HardwareMirrorUpload {
  id: string
  url: string
}

export interface HardwareMirrorStatusResponse {
  status: HardwareMirrorStatus
}
