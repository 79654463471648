import type { RestConnections } from '~/types'

export default {
  hmla2: {
    key: '3e43ae15a2fb2208ced4d24ee931f5cc',
  },
  hmla: {
    key: '3e43ae15a2fb2208ced4d24ee931f5cc',
  },
  dev: {
    key: '3e43ae15a2fb2208ced4d24ee931f5cc',
  },
  stg: {
    key: '3e43ae15a2fb2208ced4d24ee931f5cc',
  },
  prod: {
    key: '42e3c408d2827e34419e6acfeacf645e',
  },
} as RestConnections
