import { $fetch } from 'ohmyfetch'
import CoreServices from '~/services/CoreServices'
import type { KeycloakConnection } from '~/types'
import type { AuthToken } from '~/utils/auth/AuthToken'
import type { User } from '~/utils/auth/User'

const { track } = useMixpanel()

const REFRESH_TOKEN_DELAY = 1000 * 60 * 1 // 1 min
const REFRESH_TOKEN_TIMEOUT = 1000 * 10 // 10 sec
let origin = ''
let refreshPolling: NodeJS.Timer | string | number | undefined

export function useKeycloak(redirect_uri: string | null = null) {
  const access_token = useCookie<string>().get('access_token')
  const { keycloak } = useConnections()
  origin = redirect_uri || location.pathname
  return {
    isAuthenticated() {
      if (import.meta.env.VITE_ENABLE_KEYCLOAK === 'false')
        return true

      return !!access_token
    },
    redirect: () => {
      executeRedirect(keycloak)
    },
    login: async (code: string) => {
      await executeAuthenticate(keycloak, code)
      track('login', { trigger: 'Clique no botão ENTRAR' })
    },
    logout: async () => {
      executeLogout(keycloak)
      track('logout', { trigger: 'Clica no botão SAIR no menu do usuário' })
    },
  }
}

async function executeAuthenticate(keycloak: KeycloakConnection, code: string): Promise<void> {
  const response = await executeRequestAccessToken(
    keycloak,
    code,
  )
  saveTokens(response)
  await getUserMe()
  pollingRefreshToken(keycloak)
}

async function executeRequestAccessToken(keycloak: KeycloakConnection, code: string): Promise<AuthToken> {
  const accessTokenURL = `${keycloak.url}/realms/${keycloak.realm}/protocol/openid-connect/token`

  const searchParams = new URLSearchParams([
    ['grant_type', 'authorization_code'],
    ['code', code],
    ['redirect_uri', `${location.origin}${origin}`],
    ['client_id', keycloak.clientId],
  ])

  return await $fetch<AuthToken>(accessTokenURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': '*/*',
    },
    body: searchParams,
    onResponseError(_) {
      executeRedirect(keycloak)
    },
  })
}

function pollingRefreshToken(keycloak: KeycloakConnection) {
  setTimeout(() => {
    refreshPolling = setInterval(
      () => executeRequestRefreshToken(keycloak),
      REFRESH_TOKEN_DELAY,
    )
  }, REFRESH_TOKEN_TIMEOUT)
}

function saveTokens(token: AuthToken): void {
  useCookie<string>().set('access_token', token.access_token, token.expires_in)
  useCookie<string>().set('refresh_token', token.refresh_token, token.refresh_expires_in)
}

async function getUserMe() {
  const coreService = new CoreServices(useApi('core'))
  const response: User = await coreService.auth.me()
  useAppStorage().set('user', response)
}

async function executeRequestRefreshToken(keycloak: KeycloakConnection) {
  const refresh_token = useCookie<string>().get('refresh_token')
  const accessTokenURL = `${keycloak.url}/realms/${keycloak.realm}/protocol/openid-connect/token`

  const searchParams = new URLSearchParams([
    ['grant_type', 'refresh_token'],
    ['refresh_token', refresh_token],
    ['client_id', keycloak.clientId],
  ])

  const response = await $fetch<AuthToken>(accessTokenURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': '*/*',
    },
    body: searchParams,
  })

  saveTokens(response)
  await getUserMe()
}

async function executeLogout(keycloak: KeycloakConnection) {
  const refresh_token = useCookie<string>().get('refresh_token')
  const logoutKeycloakURL = `${keycloak.url}/realms/${keycloak.realm}/protocol/openid-connect/logout`

  const searchParams = new URLSearchParams([
    ['client_id', keycloak.clientId],
    ['refresh_token', refresh_token],
  ])
  await $fetch
    .raw(logoutKeycloakURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': '*/*',
      },
      body: searchParams,
    })
    .finally(async () => {
      clearInterval(refreshPolling! as any)
      useCookie().remove('access_token')
      executeRedirect(keycloak)
    })
}

function executeRedirect(keycloak: KeycloakConnection) {
  const params = {
    response_type: 'code',
    client_id: keycloak.clientId,
    scope: 'openid profile email',
    redirect_uri: `${location.origin}${origin}`,
  }

  const authorizationURL = `${keycloak.url}/realms/${
    keycloak.realm
  }/protocol/openid-connect/auth?${buildQuery(params)}`

  location.href = authorizationURL
}

function buildQuery(object: any) {
  const str: string[] = []
  for (const property in object) {
    if (Object.hasOwn(object, property))
      str.push(`${encodeURIComponent(property)}=${encodeURIComponent(object[property])}`)
  }
  return str.join('&')
}
