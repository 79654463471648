// import _http from '../simulator.http'

import type { SimulatorV3 } from '../simulator.types'
import promises from '~/utils/promises'

export default {
  /**
   * Get summary from a BNPL simulation
   */
  getSummary: async (_id: SimulatorV3.GetSummaryRequest) => {
    await promises.wait(2_000)

    return {
      installment: {
        prohibited: 4599.00,
        installments: 6,
        value: 4343.00,
        percentage: 1.24,
      },
      enterprise: {
        total: 30658.00,
        kit: 15000.00,
        tax: 658.00,
        value: 15000.00,
      },
      client: {
        total: 30658.00,
        prohibited: 4599.00,
        installments: 6,
        installment: 4343.00,
      },
    } as SimulatorV3.GetSummaryResponse
  },

  /**
   * Get installments from a BNPL simulation
   */
  getInstallments: async (_id: SimulatorV3.GetInstallmentsRequest) => {
    await promises.wait(2_000)

    return {
      conditions: [
        {
          discount_rate_value: 5,
          discounted_installer_value: 100,
          down_payment: 200,
          down_payment_value: 200,
          estimated_kit_value_calculated: 1500,
          extra_service_value: 50,
          installment_value: 300,
          partner_value: 1000,
          project_value_calculated: 2000,
          service_value_calculated: 500,
          total_discounted_value: 100,
          total_installment_payment: 1800,
          type: 'CUSTOMER_PAYS_FEE',
        },
        {
          discount_rate_value: 15,
          discounted_installer_value: 1100,
          down_payment: 1200,
          down_payment_value: 1200,
          estimated_kit_value_calculated: 11500,
          extra_service_value: 150,
          installment_value: 1300,
          partner_value: 11000,
          project_value_calculated: 2000,
          service_value_calculated: 500,
          total_discounted_value: 100,
          total_installment_payment: 1800,
          type: 'CUSTOMER_NOT_PAYS_FEE',
        },
      ],
      created_at: '2024-07-30T19:29:04.639Z',
      formalized: true,
      id: '12345',
      partner_id: '67890',
      person_type: 'PF',
      selected_condition: {
        discount_rate_value: 5,
        discounted_installer_value: 100,
        down_payment: 200,
        down_payment_value: 200,
        estimated_kit_value_calculated: 1500,
        extra_service_value: 50,
        installment_value: 300,
        partner_value: 1000,
        project_value_calculated: 2000,
        service_value_calculated: 500,
        total_discounted_value: 100,
        total_installment_payment: 1800,
        type: 'CUSTOMER_PAYS_FEE',
      },
      shopping_cart_id: 'abc123',
      step: 'FORMALIZATION',
      user_id: 'user123',
      values: {
        down_payment_percentage: 10,
        estimated_kit_value: 1500,
        installments: 6,
        project_value: 2000,
        service_value: 500,
      },
    } as SimulatorV3.GetInstallmentsResponse
  },
}
