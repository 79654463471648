export interface CustomerRegisterProject {
  project_name: string
  project_provider: ProviderEnum
}

export interface CustomerDataStatus {
  id: number
  created_at: string
  slug: 'under_analysis' | 'approved' | 'reproved' | 'pending'
  canceled_at: string | null
  reason: string | null
  updated_at: string
}

export interface Options {
  name: string
  combo_id: string
}

export interface Provider {
  name: string
  id: number
}

export interface OptionsModel {
  id: number
  created_at: string
  canceled_at: string | null
  name: string
  updated_at: string
}

export interface ClientDataOptions {
  nationality: Options[]
  occupation: Options[]
  gender: Options[]
}

export interface AddressOptions {
  state: Options[]
}

export enum ProviderEnum {
  SANTANDER = 'santander',
  SOLFACIL = 'solfacil',
}

export interface Address {
  postal_code: string
  street_number: string | null
  neighborhood: string
  city_id: number
  state_id: number
  created_at: string
  updated_at: string
  street: string
  additional_info: string | null
  rural_area: boolean
  address_id: string | null
  canceled_at: string | null
  city: {
    name: string
    combo_id: string
  }
  state: {
    name: string
    combo_id: string
  }
}

export interface AddressFormModel {
  street_number: number | null
  rural_area?: boolean
  neighborhood: string
  street: string
  postal_code: string
  additional_info: string
  city_id: string
  state_id: string
  city?: {
    name: string
    combo_id: string
  }
  state?: {
    name: string
    combo_id: string
  }
}

export interface CompanyFormModel {
  majority_partner_is_legal_representant: boolean
  electricity_bill_document: string
  project_id: string
  status: string
  installation_address_form: AddressFormModel
  company_form: CompanyFormRequest
  majority_partner_person_form: PersonForm
  majority_partner_address_form: AddressFormModel
  legal_representants: LegalRepresentantForm[]
}

export interface LegalRepresentant {
  address: AddressForm
  person: PersonForm
}

export interface LegalRepresentantForm {
  address_form: AddressForm
  person_form: PersonForm
}

export interface FinancingFormModel {
  project_name: string
  project_id: string
  external_id: string
  provider_id: string
  plant_address_form_id: string
  status_id: string
  updated_at: string
  id: string
  rural_area: string
  person_form_id: string
  residencial_address_form_id: string
  created_at: string
  canceled_at: string
  person_form: CompanyFormModel
  plant_address_form: AddressFormModel
  residencial_address_form: AddressFormModel
  provider: Provider
}

// Form
export interface OptionsForm {
  name: string
  value: string
}
export interface PersonForm {
  full_name: string
  birthdate?: string
  cpf: string
  phone: string
  email: string
  mother_name?: string
  nationality_id?: number | null
  occupation_id?: number | null
}

export interface CompanyFormRequest {
  company_name?: string
  document?: string
  business_phone?: string
  business_email?: string
}

export interface UpdateFormCompany {
  majority_partner_is_legal_representant: boolean | null
  electricity_bill_document: string | null
  project_id: string | null
  status?: string | null
  installation_address_form: AddressFormModel | null
  company_form: CompanyFormRequest
  majority_partner_person_form: PersonForm | null
  majority_partner_address_form: AddressFormModel | null
  legal_representants: (LegalRepresentant | null)[]
}

export interface AddressForm {
  postal_code: string
  street: string
  street_number: string | null
  additional_info: string
  neighborhood: string
  state_id: number
  city_id: number
  city: {
    name: string
    value: string
  }
  state: {
    name: string
    value: string
  }
}

export interface AddressFormRequest {
  postal_code?: string
  street?: string
  street_number?: number
  additional_info?: string
  neighborhood?: string
  state_id?: number
  city_id?: number
  rural_area?: boolean
}

export interface ResponseUpdateCompany {
  data: {
    invalid_fields?: string
  }
}
export interface ProjectData {
  distributor: OptionsForm[]
  phases: OptionsForm[]
  inverters: OptionsForm[]
  modules: OptionsForm[]
  microinverters: OptionsForm[]
}

export enum InverterTypeEnum {
  STRING = 'string',
  MICROINVERTER = 'microinverter',
}

export interface Equipment {
  quantity: number
  name: string
  id: number
}
export interface ProjectDataForm {
  distributor: OptionsForm | null
  phase: OptionsForm | null
  inverter_type: InverterTypeEnum
  inverters: Equipment[]
  modules: Equipment[]
}

export interface TagStep {
  id: string
  text: string
  variant: 'fill' | 'outline' | undefined
  size: 'medium' | 'small' | undefined
  type: 'positive' | 'warning' | 'negative' | 'informative' | 'neutral' | undefined
}

export interface ClientDataStatusEnum {
  under_analysis: TagStep
  approved: TagStep
  reproved: TagStep
  pending: TagStep
}

export interface DocumentStatusEnum {
  pending: TagStep
  pendency: TagStep
  under_analysis: TagStep
  reproved: TagStep
  approved: TagStep
}

export interface ReceiptModelStatusEnum {
  in_analysis: TagStep
  approved: TagStep
  denied: TagStep
  in_progress: TagStep
  pending_change: TagStep
}

export interface ProjectStatusEnum {
  pending: TagStep
  submitted: TagStep
  in_progress: TagStep
  kit_pending: TagStep
  approved: TagStep
}

export type CompanyDocumentTypeEnum = 'identity' | 'energy_bill' | 'incorporation_document'

export interface Document {
  id: string
  type: CompanyDocumentTypeEnum
  url: string
}

export interface DocumentModel {
  id: string
  status: boolean
  project_id: string
  installation_document_matches_bill: boolean
  identity_matches_document: boolean
  installation_address_matches_bill: boolean
  incorporation_document_matches_document: boolean
  legal_representant_missing: boolean
  reason: string
  documents: Document[]
}

export interface TypesErrorsCompanyDocuments {
  installation_document_matches_bill: boolean
  legal_representant_missing: boolean
  identity_matches_document: boolean
  installation_address_matches_bill: boolean
  incorporation_document_matches_document: boolean
}
