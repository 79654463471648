import { useLoadingStore } from '~/store/loading'

export const useLoading = () => {
  const loadingStore = useLoadingStore()

  return {
    show: () => {
      loadingStore.setLoading(true)
    },
    hide: () => {
      loadingStore.setLoading(false)
    },
  }
}
