<script setup lang="ts">
import { SolInputText } from '@solfacil/girassol'
import { useField, useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import * as zod from 'zod'
import CustomerRegisterService from '~/services/customer-register/CustomerRegister'
// PersonFormRequest, ResponseUpdatePerson
import type { Options, PersonForm, PersonFormModel, StageFormalization } from '~/utils/customer-register/CustomerRegister'
import { Formalization } from '~/types/enum'
import IconLock from '~icons/material-symbols/lock'

const props = defineProps<{
  personForm: PersonFormModel
  position: number
  stage: StageFormalization
  stageReproved: string
}>()

const { track } = useMixpanel()

const router = useRouter()

const nationalityOptions = ref()
const ocupationOptions = ref()
const genderOptions = ref()
const loadingLocal = ref(false)
const loadingModal = ref(false)
const agreeWithTermsError = ref('')
const fieldIsDisabledBasedOnConfirmation = ref(props.personForm.person_id !== null)
const blocked = ref(true)

function comboOptionsAdatper(values: Options[]) {
  return values.map((option: Options) => {
    return { name: option.name, value: Number(option.combo_id) }
  })
}
const customerService = new CustomerRegisterService(useApi('customerRegister'))

onMounted(async () => {
  checkStageReproved()

  try {
    const { data } = await customerService.get_customer_register_client_data_options()
    if (data) {
      nationalityOptions.value = comboOptionsAdatper(data?.nationality)
      ocupationOptions.value = comboOptionsAdatper(data?.occupation)
      genderOptions.value = comboOptionsAdatper(data?.gender)
    }
  }
  catch {}
})

const agreeWithTerms = ref(false)

const { t } = useI18n()

const formattedDate = new Intl.DateTimeFormat('pt-BR', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
})

const initialValues = {
  name: props.personForm.full_name || '',
  document: props.personForm.cpf || '',
  phone: props.personForm.phone?.slice(3) || '',
  email: props.personForm.email || '',
  birthdate: formattedDate.format(new Date(props.personForm.birthdate)) || '',
  nationality: {
    name: props.personForm?.nationality?.name || null,
    value: props.personForm?.nationality?.id || 0,
  },
  occupation: {
    name: props.personForm?.occupation?.name || null,
    value: props.personForm?.occupation?.id || 0,
  },
  gender: {
    name: props.personForm?.gender?.name || null,
    value: props.personForm?.gender?.id || 0,
  },
  motherName: props.personForm.mother_name || '',
}

const validationSchema = toTypedSchema(
  zod.object({
    name: zod.string().min(1, { message: t('form.required') }),
    document: zod.string().min(14, t('form.invalid_document')).max(14, t('form.invalid_document')),
    phone: zod.string().min(1, t('form.required')),
    email: zod.string().email({ message: t('form.invalid_email') }).min(1, t('form.required')),
    birthdate: zod.string().min(1, t('form.required')),
    nationality: zod.object({
      name: zod.string().min(1, t('form.required')).nullable(),
      value: zod.number().min(1, t('form.required')),
    }),
    occupation: zod.object({
      name: zod.string().min(1, t('form.required')).nullable(),
      value: zod.number().min(1, t('form.required')),
    }),
    gender: zod.object({
      name: zod.string().min(1, t('form.required')).nullable(),
      value: zod.number().min(1, t('form.required')),
    }),
    motherName: zod.string().min(1, t('form.required')),
  }),
)
// values, setFieldError
const { handleSubmit, validate, errors } = useForm({
  validationSchema,
  initialValues,
})

const modal = ref(false)

const openModal = async () => {
  const { valid } = await validate()
  if (!agreeWithTerms.value) {
    agreeWithTermsError.value = t('form.required')
    return
  }

  if (valid) {
    agreeWithTermsError.value = ''
    modal.value = true
  }
}

const closeModal = () => {
  modal.value = false
}

const createUser = handleSubmit(async (event: any) => {
  track('customer_data_button_confirm', { trigger: 'Clique no botão Confirmar dados' })

  try {
    loadingModal.value = true
    const parsedEvent: PersonForm = event

    const clearPhoneNumber = parsedEvent.phone.replace(/[\s\-()/]/g, '')

    const formattedPhoneNumber = `+55${clearPhoneNumber}`

    const datePath = parsedEvent?.birthdate?.split('/')

    if (!datePath)
      return

    const year = datePath[2]
    const month = datePath[1]
    const day = datePath[0]

    const dateParsed = new Date(parseInt(year), parseInt(month) - 1, parseInt(day) + 1)

    const parsedPersonForm = {
      ...parsedEvent,
      phone: formattedPhoneNumber,
      full_name: parsedEvent.name,
      birthdate: dateParsed?.toISOString()?.split('T')[0],
      cpf: parsedEvent.document,
      nationality_id: parsedEvent.nationality?.value,
      gender_id: parsedEvent.gender?.value,
      occupation_id: parsedEvent.occupation?.value,
      mother_name: parsedEvent.motherName,
    }

    await customerService.confirm_person_form(String(router.currentRoute.value.params.id), parsedPersonForm)
    closeModal()
  }
  catch {
    closeModal()
  }
  finally { loadingModal.value = false }
})

function checkStageReproved() {
  if (props.stageReproved === '')
    blocked.value = false
  else
    blocked.value = props.stageReproved !== Formalization.REGISTRATION
}

// const debouncedUpdate = ref()
const loadingEmail = ref(false)

/*
** TODO: Comentando, pois santander não veremos agora e devido
** refatoração de código está parte parou de funcionar
*/
// watch(values, async (newValues) => {
//   loadingEmail.value = true
//   clearTimeout(debouncedUpdate.value)

//   debouncedUpdate.value = setTimeout(async () => {
//     const personForm = newValues
//     const datePath = personForm?.birthdate?.split('/')

//     if (!datePath)
//       return

//     const year = datePath[2]
//     const month = datePath[1]
//     const day = datePath[0]

//     const dateParsed = new Date(parseInt(year), parseInt(month) - 1, parseInt(day) + 1)

//     const clearPhoneNumber = personForm.phone?.replace(/[\s\-()/]/g, '')

//     const formattedPhoneNumber = `+55${clearPhoneNumber}`

//     const parsedPersonForm: PersonFormRequest = {
//       phone: formattedPhoneNumber || '',
//       email: personForm.email || '',
//       full_name: personForm.name || '',
//       cpf: personForm.document || '',
//       birthdate: dateParsed.toISOString().split('T')[0],
//       nationality_id: personForm.nationality?.value ? personForm.nationality?.value : null,
//       gender_id: personForm.gender?.value ? personForm.gender?.value : null,
//       occupation_id: personForm.occupation?.value ? personForm.occupation?.value : null,
//       mother_name: personForm.motherName || null,
//     }

//     if (!props.personForm.person_id) {
//       const response: ResponseUpdatePerson = await customerService.update_form_partial(String(router.currentRoute.value.params.id), parsedPersonForm)

//       if (response?.data?.invalid_fields?.includes('email'))
//         setFieldError('email', t('form.invalid_email'))
//     }

//     loadingEmail.value = false
//   }, 500)
// })
</script>

<template>
  <CustomerElementAccordion
    v-if="!loadingLocal"
    :id="`accordion_${Formalization.REGISTRATION}`"
    :title="t('customer.client_data')"
    :position="String(position)"
    :blocked="blocked"
    :open-collapse="!blocked && stage === Formalization.REGISTRATION"
    class="-mt-6"
  >
    <template #icon>
      <IconLock v-if="blocked" />
    </template>

    <div>
      <p class="mt-2">
        {{ t('customer.client_data_subtitle') }}
      </p>
    </div>
    <div class="form-client-data">
      <form>
        <div class="grid grid-cols-1 md:system:grid-cols-2 gap-6 mt-6">
          <SolInputText
            id="name"
            name="name"
            :use-field="useField"
            :label="`${t('form.name')}`"
            :disabled="fieldIsDisabledBasedOnConfirmation"
            placeholder="Francisco Junior"
          />
          <SolInputText
            id="cpf"
            name="document"
            :use-field="useField"
            :label="`${t('form.cpf')} do cliente*'}`"
            :mask="{ preset: 'CPF' }"
            :disabled="true"
            placeholder="000.000.000-00"
          />
        </div>
        <div class="grid grid-cols-1 md:system:grid-cols-3 gap-6 mt-6">
          <SolInputText
            id="phone"
            name="phone"
            :mask="{ preset: 'MobilePhoneDDD' }"
            :label="`${t('form.phone')}*`"
            placeholder="(00) 0 0000 0000"
            :disabled="true"
            :use-field="useField"
          />
          <SolInputText
            id="cpf-field"
            name="email"
            type="email"
            :disabled="fieldIsDisabledBasedOnConfirmation"
            :label="`${t('form.email')}*`"
            :use-field="useField"
            placeholder="cliente@email.com"
            :is-loading="loadingEmail"
            :is-success="!!(!errors.email) && !loadingEmail"
            :success-text="t('form.validatedEmail')"
          />
          <SolInputText
            id="birthdate"
            name="birthdate"
            :label="`${t('form.birthdate')}*`"
            :use-field="useField"
            mask="##/##/####"
            :disabled="true"
            placeholder="00/00/0000"
          />
        </div>

        <div class="grid grid-cols-1 md:system:grid-cols-2 lg:system:grid-cols-4 gap-6 mt-6 mb-6">
          <SolSelect
            id="nationality-field"
            name="nationality"
            :disabled="fieldIsDisabledBasedOnConfirmation"
            :label="`${t('form.nationality')}*`"
            :use-field="useField"
            :options="nationalityOptions"
          />
          <SolSelect
            id="occupation-field"
            name="occupation"
            :disabled="fieldIsDisabledBasedOnConfirmation"
            :label="`${t('form.occupation')}*`"
            :use-field="useField"
            :options="ocupationOptions"
          />
          <SolSelect
            id="gender-field"
            name="gender"
            :disabled="fieldIsDisabledBasedOnConfirmation"
            :label="`${t('form.gender')}*`"
            :use-field="useField"
            :options="genderOptions"
          />
          <SolInputText
            id="motherName-field"
            name="motherName"
            :disabled="fieldIsDisabledBasedOnConfirmation"
            :label="`${t('form.mother_name')}*`"
            :use-field="useField"
            placeholder="Francisco Junior"
          />
        </div>

        <SolCheckbox
          id="is-admin"
          :disabled="fieldIsDisabledBasedOnConfirmation"
          name="agreeWithTerms"
          :value="true"
          :checked="agreeWithTerms || personForm.person_id"
          :label="t('customer.use_terms')"
          @change="agreeWithTerms = !agreeWithTerms"
        />
        <span class="error-message">{{ agreeWithTermsError }}</span>

        <div class="h-px bg-neutral-high-medium w-full my-sm" />
        <SolModal
          id="video-modal"
          :title="t('customer.confirm')"
          :is-open="modal"
          :action-secondary-text="t('buttons.back')"
          @close="closeModal"
          @action:secondary="closeModal"
        >
          <template #action-primary>
            <SolButton
              id="btn-simulate"
              size="medium"
              :loading="loadingModal"
              variant="primary"
              @click="createUser"
            >
              {{ t('customer.confirm') }}
            </SolButton>
          </template>
          <div>
            <span class="font-bold">{{ t('customer.attention') }}:</span> {{ t('customer.confirm_modal') }}
          </div>
        </SolModal>
        <div class="flex justify-end">
          <SolButton
            id="customer_confirm"
            :loading="false"
            size="large"
            :disabled="fieldIsDisabledBasedOnConfirmation"
            @click="openModal"
          >
            {{ t('customer.confirm') }}
          </SolButton>
        </div>
      </form>
    </div>
  </CustomerElementAccordion>
</template>

<style lang="scss" scoped>
.error-message {
  @apply text-feedback-negative-pure py-nano;
  @apply fonts-subtitle-small;
}

.form-client-data {
  @apply mt-sm mb-0;
}
</style>
