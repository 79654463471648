<script setup lang="ts">
import type { TabList } from '@solfacil/girassol/dist/types/components/tabs/types'
import { useToast } from '@solfacil/girassol'
import SimulatorService from '~/services/simulator/Simulator'
import type { Project } from '~/utils/project/Project'
import type { User } from '~/utils/auth/User'
import type { Addons, ParsedInstallment } from '~/utils/simulation/Simulation'
import type { InstallmentResponse } from '~/utils/simulation/SimulationApi'
import { useMoney } from '~/composables/money'
import filtersStoreMarketFinancing from '~/utils/filtersStoreMarketFinancing'

const props = defineProps<{
  project: Project
  defaultInstallment: number
  defaultCommission: number
  defaultCommissionRaw: number
  defaultGracePeriod: number
  disableFields: boolean
  showCommissionMoney: boolean
  simulation: any
  cet: string
  shoppingCartId: string
}>()
const { rest } = useConnections()
const { createErrorToast, createInformativeToast } = useToast()
const { getAddonsApplieds } = useAddonStore()
const { setGracePeriod, setCommission } = useResumeStore()
const showEmptyStateNotInstallments = ref(false)
const cart_id = ref()
const ENV = useEnvironment()
const user: User = useAppStorage().get('user')
const { formatMoney } = useMoney()
const CHANNEL = 'plugin-financiamento'

onMounted(async () => {
  await showComboCommission()
  setSelectedTab({ key: props.defaultInstallment, label: `${props.defaultInstallment}x`, active: true })
  cart_id.value = props.shoppingCartId
  showStoreMarketFinancing()
})

const showComboCommissionInstallment = ref()

async function showComboCommission() {
  showComboCommissionInstallment.value = await useFlag('commi-combo-installment', { partner_id: props?.project?.partner_id })
}

const inactiveFinancing = 'INACTIVE'

const { t } = useI18n()

function mountTabs(disabled?: boolean): TabList[] {
  const tabs: number[] = []
  let counter = 0

  while (counter < props.project.max_installments) {
    tabs.push(counter + 12)
    counter = counter + 12
  }

  const mountedTabs: TabList[] = tabs.map((installment, index) => {
    return {
      key: installment.toString(),
      label: `${installment}x`,
      active: index === (props.defaultInstallment / 12) - 1,
      disabled,
    }
  })

  return mountedTabs
}

// installments details
const cetTab = ref(props.cet === 'POS_FIXADO' ? 'POST' : 'PRE')

function setCetTab(type: string) {
  cetTab.value = type
  requestInstallments()
}

const isProjectDisabled = props.project?.project_status === inactiveFinancing

const tabListModel = ref(mountTabs(isProjectDisabled))
const selectedInstallment = ref<ParsedInstallment | null>(null)

const projectIsCompleted = ref<boolean>(props.project.complete)

const tabSelected = ref(props.defaultInstallment)
let tabSelecteds = `tab:${props.defaultInstallment}`

const projectValue = ref(props.project.selected_installments.financing_value)
const downPayment = ref(props.project.selected_installments.down_payment)

const simulatorService = new SimulatorService(useApi('simulator'))

const defaultListOfInstallments = ref<ParsedInstallment[] | []>([])
const listOfInstallments = ref<InstallmentResponse | null>(null)

const loadingDetails = ref(false)

const hasProjectValueError = ref(false)

const projectIsFinished = ref(false)

const loadingAddons = ref(false)
const loadingAmperaAddon = ref(false)
const loadingInsuranceAddon = ref(false)
const removeResume = ref(true)

const selectedGracePeriod = ref({ name: `${props.defaultGracePeriod} ${(props.defaultGracePeriod === 1) ? 'Mês' : 'Meses'}`, value: props.defaultGracePeriod })

function getDefaultCommissionText() {
  const commissionText = `${props.defaultCommission} %`
  return props.showCommissionMoney && props.defaultCommissionRaw !== null
    ? `${commissionText} = ${formatMoney(props.defaultCommissionRaw)}`
    : commissionText
}

const selectedCommission = ref({
  name: getDefaultCommissionText(),
  value: props.defaultCommission,
})

onBeforeMount(() => {
  setCommission({ name: getDefaultCommissionText(), value: props.defaultCommission })
  setGracePeriod({ name: `${props.defaultGracePeriod} ${(props.defaultGracePeriod === 1) ? 'Mês' : 'Meses'}`, value: props.defaultGracePeriod })
})

function setProjectValue(valueOfProject: number) {
  projectValue.value = valueOfProject
}

function setDownPayment(valueOfDownPayment: number) {
  downPayment.value = valueOfDownPayment
}

function setProjectValueError(error: boolean) {
  hasProjectValueError.value = error
}

const { setAmpera, setInsurance, addons_list, getAmperaValue, toggleCombo, setCombo } = useAddonStore()
const { setSelectedInstallment, getSelectedInstallment, getInstallmentSelectedByUserAction } = useResumeStore()

async function setSelected(value: ParsedInstallment, is_user_action?: boolean) {
  try {
    loadingAddons.value = true
    selectedInstallment.value = value
    toggleCombo(value.combo)
    setSelectedInstallment(value, is_user_action)

    if (listOfInstallments.value && value.combo) {
      if (value.is_from_suggested) {
        setCombo(listOfInstallments.value?.suggested_simulation.addons)
        toggleCombo(true)
      }
      else {
        setCombo(listOfInstallments.value?.original_simulation.addons)
        toggleCombo(true)
      }
    }

    const ampera = await requestAmpera(value.simulation_id, value.engine_simulation_id, value.installments_value)
    if (!ampera)
      setAmpera([])

    if (ampera) {
      setAmpera(ampera)

      if (getAmperaValue()[0].applied) {
        const insurance = await requestInsurance(value.simulation_id, getAmperaValue()[0].engine_simulation_id, value.installments_value + getAmperaValue()[0].installment_price)
        if (!insurance)
          setInsurance([])
        if (insurance)
          setInsurance(insurance)
        return
      }

      const insurance = await requestInsurance(value.simulation_id, value.engine_simulation_id, value.installments_value)
      if (!insurance)
        setInsurance([])
      if (insurance)
        setInsurance(insurance)
    }

    removeResume.value = false
    loadingAddons.value = false
  }
  catch {
    removeResume.value = false
    loadingAddons.value = false
  }
  finally {
    removeResume.value = false
    loadingAddons.value = false
  }
}

async function refetchInsurance() {
  try {
    loadingInsuranceAddon.value = true
    const ampera = getAmperaValue()[0]
    const selectedInstallment = getSelectedInstallment()

    if (!selectedInstallment)
      return

    if (ampera.applied) {
      const insurance = await requestInsurance(ampera.simulation_id, ampera.engine_simulation_id, selectedInstallment.installments_value + ampera.installment_price)
      if (!insurance)
        setInsurance([])
      if (insurance)
        setInsurance(insurance)
      return
    }

    const insurance = await requestInsurance(selectedInstallment.simulation_id, selectedInstallment.engine_simulation_id, selectedInstallment.installments_value)
    if (!insurance)
      setInsurance([])
    if (insurance)
      setInsurance(insurance)

    loadingInsuranceAddon.value = false
  }
  catch {
    loadingInsuranceAddon.value = false
  }
  finally {
    loadingInsuranceAddon.value = false
  }
}

function setSelectedTab(value: any) {
  tabSelecteds = `tab:${value.key}`

  tabSelected.value = value.key
  requestInstallments()
}

async function requestInsurance(simulation_id: string, engine_simulation_id: string, installment_value: number): Promise<Addons[] | null> {
  try {
    const response = await simulatorService.get_insurance(simulation_id, engine_simulation_id, installment_value)
    if (response.data)
      return response.data

    return null
  }
  catch {
    return null
  }
}

async function requestAmpera(simulation_id: string, engine_simulation_id: string, installment_value: number): Promise<Addons[] | null> {
  try {
    const response = await simulatorService.get_ampera(simulation_id, engine_simulation_id, installment_value)
    if (response.data)
      return response.data

    return null
  }
  catch {
    return null
  }
}

async function requestInstallments() {
  try {
    selectedInstallment.value = null
    loadingDetails.value = true

    const requestPayload = {
      project_id: props.project.id,
      person_type: props.project.person_type,
      document: props.project.document,
      project_value: projectValue.value,
      grace_period: selectedGracePeriod.value.value,
      commission: selectedCommission.value.value,
      down_payment: downPayment.value,
      cpf_risk: props.project.risk_class,
      project_name: props.project.project_name,
      partner_id: user?.parceiro?.id,
      user_id: user?.id,
      partner_level: user?.parceiro?.nivelSolfacilMais || '',
      approved_value: props.project.pre_approved_value,
      selected_installment: tabSelected.value,
      indexer_kind: cetTab.value,
      products_applieds: [''],
      installments: [0],
      origin_id: 2,
      is_ampera: !!(getAddonsApplieds()?.find(item => item.product_slug === 'Ampera')),
      suggestion_installment_enabled: Boolean(showComboCommissionInstallment.value),
    }

    showEmptyStateNotInstallments.value = false

    const response = await simulatorService.calculate_project(requestPayload)

    if (response?.data) {
      setCombo(response.data.original_simulation.addons)
      listOfInstallments.value = response.data
    }
  }
  catch (e: any) {
    if (e.status === 422)
      showEmptyStateNotInstallments.value = true

    listOfInstallments.value = null
  }
  finally {
    loadingDetails.value = false
  }
}

function containAmperaInAddonList(addons: Addons[]) {
  return true
}

function containAmperaAndInsuranceInAddonList(addons: Addons[]) {
  return true
}

const showComponentStoreMarketFinancing = ref(false)

async function showStoreMarketFinancing() {
  const flagStoreMarketFinancing: boolean = await useFlag('store-showcase', { partner_id: user?.parceiro?.id })
  showComponentStoreMarketFinancing.value = flagStoreMarketFinancing
}

function selectGracePeriod(value: any) {
  selectedGracePeriod.value = value
  setGracePeriod(value)
  requestInstallments()
}
function selectCommission(value: any) {
  selectedCommission.value = value
  setCommission(value)
  requestInstallments()
}

function getMessageOfCet(selectedCet: string) {
  if (selectedCet === 'POST')
    return 'As parcelas passam por reajustes mensais decorrentes do IPCA'

  return 'As parcelas são fixas e não sofrem alteração de valor durante todo o financiamento'
}

function onAddKit(data: { cartId: any; kit: any }) {
  cart_id.value = String(data.cartId)
  updateShoppingCartId(props.project.id, cart_id.value)
  createInformativeToast({
    title: t('toastKitSelected.title'),
    description: t('toastKitSelected.description'),
  })
}

function onDeleteKit() {
  cart_id.value = ''
}

function getPreselectedOptions(project: Project) {
  if (getInstallmentSelectedByUserAction())
    return getInstallmentSelectedByUserAction()?.combo ? getInstallmentSelectedByUserAction()?.is_from_suggested ? 'with_combo_comission' : 'with_combo' : 'without_combo'

  return project?.selected_installments?.is_combo ? project.selected_installments.is_from_suggested ? 'with_combo_comission' : 'with_combo' : 'without_combo'
}

async function updateShoppingCartId(projectId: string, cartId: string) {
  const payload = {
    shopping_cart_id: cartId,
  }

  try {
    await simulatorService.put_simulation_by_shopping_cart_id(projectId, payload)
  }
  catch (e) {
    createErrorToast('Não foi possível realizar a ação. Por favor, tente novamente.')
  }
  finally {
    loadingAddons.value = false
  }
}

const projectIsFormalized = !!props.project.is_formalized

function isPostFixed() {
  return props.cet && props.cet !== 'PRE_FIXADO'
}
</script>

<template>
  <section class="revision-container">
    <div class="mb-20 lg:site:mb-0">
      <div class="details-container !mt-0">
        <SolAlert
          v-if="isProjectDisabled"
          id="error-feedback"
          class="lg:site:mb-6"
          title="Financiamento inativo."
          text="Este financiamento está inativo no momento."
          feedback="error"
        />
        <div>
          <h2 class="fonts-body-x-large-bold mb-2">
            {{ t("revision.title") }}
          </h2>
          <p class="fonts-subtitle-small text-brand-primary-medium">
            {{ t("revision.subtitle") }} <span class="font-bold">{{ t("revision.subtitle_complement") }}</span>
          </p>
        </div>
        <div class="container-details">
          <SimulationElementSelectGracePeriodAndComission
            :select-commission="selectCommission"
            :max-grace-period="props.project?.max_grace_period"
            :select-grace-period="selectGracePeriod"
            :disable-fields="!loadingDetails && !isProjectDisabled"
            :loading-details="loadingDetails"
            :selected-commission="selectedCommission"
            :selected-grace-period="selectedGracePeriod"
            :project-id="props.project.id"
          />
          <SimulationElementProjectValue
            v-if="(projectValue && downPayment) >= 0"
            :set-project-value-error="setProjectValueError"
            :select-project-value="setProjectValue"
            :max-grace-period="props.project?.max_grace_period"
            :select-down-payment="setDownPayment"
            :disable-fields="loadingDetails || isProjectDisabled"
            :project="project"
            :loading-details="loadingDetails"
            :project-value-chosen="projectValue"
            :down-payment-chosen="downPayment"
            :request-installments="requestInstallments"
          />
        </div>
      </div>
      <div class="mb-2xs">
        <StoreMarketFinancing
          v-if="showComponentStoreMarketFinancing"
          title="Kits disponíveis no Combo Fácil"
          subtitle="Sugestões da nossa loja de acordo com as dimensões do seu projeto."
          :channel="CHANNEL"
          :environment="ENV"
          :class="{ 'pointer-events-none': projectIsFormalized }"
          :partner-id="user?.parceiro?.id"
          :cart-id="cart_id"
          :filters="filtersStoreMarketFinancing.createObject(`${(project.system_power)}`, project.project_state)"
          :segmentation-id="props.project.id"
          :disable-filters="projectIsFormalized"
          :disable-cart="projectIsFormalized"
          mix-panel-env="Revision"
          :mix-panel-key="rest.mixpanel.key"
          @on-add-kit="onAddKit"
          @on-delete-kit="onDeleteKit"
        />
      </div>

      <div class="details-container">
        <div class="mb-2xs gap-1">
          <div class="flex justify-between">
            <div class="mr-4xs">
              <h3 class="card-subtitle mb-nano">
                Opções de parcela
              </h3>
              <p
                v-if="isPostFixed()"
                class="fonts-subtitle-small text-neutral-low-light"
              >
                Pesquise entre nossas opções de prazo a que melhor se encaixa no perfil do seu cliente
              </p>
            </div>
            <SimulationElementDownloadList
              v-if="!projectIsCompleted"
              :commission="selectedCommission"
              :grace-period="selectedGracePeriod"
              :is-combo="getSelectedInstallment()?.combo"
            />
          </div>

          <p
            v-if="!props.cet || props.cet === 'PRE_FIXADO'"
            class="fonts-subtitle-small text-neutral-low-light"
          >
            Pesquise entre nossas opções a que melhor se encaixa no perfil do seu cliente. Todas nossas parcelas são prefixadas, ou seja, não sofrem alteração de valor durante todo o financiamento.
          </p>

          <div
            v-if="isPostFixed()"
            class="flex py-4xs"
          >
            <SimulationElementSelectOptionsOfCet
              :active="cetTab"
              :disabled="!!(props.project?.project_status === 'INACTIVE')"
              :cet="cet"
              @selected="setCetTab"
            />
          </div>
          <p
            v-if="isPostFixed()"
            class="fonts-subtitle-small text-neutral-low-light"
          >
            {{ getMessageOfCet(cetTab) }}
          </p>
        </div>
        <BlockFieldsProxy :block-fields="loadingDetails">
          <SolTabs id="simple" v-model:tabList="tabListModel" class="tabscet_parsed" @tabitem:active="setSelectedTab">
            <template #[tabSelecteds]>
              <div v-if="!loadingDetails">
                <SimulationElementInstallmentList
                  :project="project"
                  :set-selected="setSelected"
                  :loading-addons="loadingAddons || loadingInsuranceAddon"
                  :loading-details="loadingDetails"
                  :disable-fields="props.disableFields"
                  :show-empty-state-not-installments="showEmptyStateNotInstallments"
                  :is-project-disabled="isProjectDisabled"
                  :installment-list="listOfInstallments"
                  :refetch-installment="requestInstallments"
                  :has-default-installment="!defaultListOfInstallments.length"
                  :pre-selected-installment="getPreselectedOptions(props.project)"
                  :financing-value="props.project.project_value - props.project.down_payment"
                  :person-type="props.project.person_type"
                />
              </div>
              <div v-if="loadingDetails">
                <div class="loader-installment" />
              </div>
            </template>
          </SolTabs>
        </BlockFieldsProxy>
      </div>

      <div class="mb-8 lg:site:mb-2xs">
        <div v-if="loadingAmperaAddon || loadingAddons" class="my-4 card-container flex flex-col justify-between w-full">
          <span class="loader-project" />
        </div>

        <SimulationProductsAmpera
          v-if="selectedInstallment && containAmperaInAddonList(addons_list) && (!loadingAmperaAddon && !loadingAddons)"
          :refetch-insurance="refetchInsurance"
          :loading="loadingAmperaAddon" :project-is-completed="props.disableFields"
        />

        <div v-if="loadingInsuranceAddon || loadingAddons" class="my-4 card-container flex flex-col justify-between w-full">
          <span class="loader-project" />
        </div>

        <SimulationProductsInsurances
          v-if="selectedInstallment && containAmperaAndInsuranceInAddonList(addons_list) && (!loadingInsuranceAddon && !loadingAddons)"
          :loading="loadingInsuranceAddon"
          :project-is-completed="props.disableFields"
          :installment="selectedInstallment"
        />
        <div v-else-if="addons_list === null && selectedInstallment" class="empty-insurance card-container">
          <img src="/images/not-found.png" class="not-found mb-4xs">
          <h1 class="text-brand-primary-dark fonts-heading-h2 mb-4xs">
            Erro ao carregar seguros
          </h1>
          <p class="fonts-body-x-large-regular text-brand-primary-medium mb-4xs">
            Desculpe, não conseguimos exibir as opções de seguros no momento. Você pode seguir com o financiamento ou tentar novamente em alguns minutos.
          </p>
        </div>
      </div>
      <SimulationRevisionResumeButtons
        v-if="!removeResume"
        :loading-conclusion="loadingInsuranceAddon || loadingAddons"
        :disabled-fields="!disableFields"
        :shopping-cart-id="shoppingCartId"
        :cart-id-selected="cart_id"
      />
    </div>
    <SimulationElementResume
      :project-is-finished="projectIsFinished"
      :disabled-fields="props.disableFields"
      :selected-commission="selectedCommission.value"
      :selected-grace-period="selectedGracePeriod.value"
      :project="props.project" class="hidden md:system:block"
      :installment="selectedInstallment" :loading-conclusion="loadingAddons"
      :project-value="projectValue"
      :project-downpayment="downPayment"
      :is-details="false"
    />
    <SolAccordion id="resume-mobile" title="Resumo" class="accordion-mobile">
      <SimulationElementResume
        :loading-conclusion="loadingInsuranceAddon || loadingAmperaAddon"
        :project-is-finished="projectIsFinished"
        :disabled-fields="props.disableFields"
        :selected-commission="selectedCommission.value"
        :selected-grace-period="selectedGracePeriod.value"
        :project="props.project"
        class="col-span-3"
        :installment="selectedInstallment"
        :project-value="projectValue"
        :project-downpayment="downPayment"
        :is-details="false"
      />
    </SolAccordion>
  </section>
</template>

<style lang="scss">
.revision-container{
  @apply block w-full;
  @screen md:system {
    @apply grid gap-4;
    grid-template-columns: minmax(0, 1fr) 300px
  }

  .empty-insurance{
    @apply flex flex-col justify-center text-center items-center py-md;
  }
  .accordion-mobile {
    @apply fixed left-0 bottom-0 w-full text-center rounded-t-lg bg-neutral-high-pure z-20;
    box-shadow: 0 0 5px rgba(0, 0, 0, .1);
    @screen md:system {
      @apply hidden;
    }
    .resume-container {
      @apply px-4xs;
      h2 {
        @apply hidden;
      }
      h3 {
        @apply text-left;
      }
    }
  }
  .accordion-content {
    height: 60vh;
    @apply overflow-scroll;
  }
  .container-title {
    @apply fonts-heading-h3;
    color: #222222;
  }
  .sol-accordion-core>.summary {
    @apply h-18;
  }
  .sol-accordion-core>.summary>.icon {
    @apply rotate-180;
  }
  .sol-accordion-core[open]>.summary>.icon {
    @apply rotate-0;
  }
  .list-style-combo{
    li{
      @apply bg-brand-secondary-light;
    }
    .-selected {
      @apply bg-brand-secondary-light;
    }
  }

  .loader-installment{
    @apply h-24 w-full;
    @apply top-0 right-0 bottom-0 left-0 z-50;
    background: rgba(#fff, 0.9)
      url(https://cdn.solfacil.com.br/assets/img/loading-for-legacy.gif) no-repeat
      center center;
  }

  .tabs {
    @apply mb-3xl;
  }

  .list-style{
    .-selected {
      @apply bg-neutral-high-pure;
    }
  }

  .cet-select-left{
    @apply p-nano rounded-l-lg border border-brand-primary-light
  }
  .cet-select-right{
    @apply p-nano rounded-r-lg border border-brand-primary-light
  }

  .details-container {
    @apply flex justify-between w-full flex-col;
    @apply px-4xs my-6 mt-0 bg-neutral-high-pure rounded-lg;
    @screen md:site {
      @apply p-8 mt-6 mb-2xs;
    }

    .container-details{
      @apply w-full py-2xs my-0 flex flex-col;
      @screen md:site {
        @apply my-4xs;
      }
      .details{
        @apply flex justify-between py-nano border-b-neutral-high-medium border-b-1;
      }
    }
  }

  .ticker{
    @apply fonts-body-small-bold px-micro py-quark bg-brand-secondary-medium text-feedback-positive-dark rounded-full font-bold ml-auto;
    @screen md:site {
      @apply ml-0;
    }
  }
  .list-item>.list-item-row {
    @apply pt-4xs;
    @screen md:site {
      @apply pt-nano;
    }
  }
  .list-item>.list-item-row>.column-item:nth-of-type(2),
  .list-item>.list-item-row>.column-item:nth-of-type(3) {
    @apply mr-0;
    @screen md:site {
    @apply mr-4xs;
    }
  }
}
</style>
