import type { $Fetch } from 'ohmyfetch'
import AuthService from './core/AuthService'

export default class CoreServices {
  auth: AuthService

  constructor(api: $Fetch) {
    this.auth = new AuthService(api)
  }
}
