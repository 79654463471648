<script>
</script>

<template>
  <div>
    Not Found
  </div>
</template>

<route lang="yaml">
meta:
  layout: 404
</route>
