<script setup lang="ts">
const props = defineProps<{
  defaultListOfInstallments: any
  disableFields: any
}>()
const { formatMoney } = useMoney()

const headerWithCombo = {
  installments_value: { text: 'Valor inicial', width: '80px' },
  combo: { text: 'Com benefícios', hide: true, width: '120px' },
}

const positionWithCombo = {
  desktop: [
    'installments_value',
    'combo',
  ],
  mobile: [
    ['installments_value'],
    ['combo'],
  ],
}
</script>

<template>
  <SolList
    v-if="!props.disableFields"
    id="test"
    ref="list"
    aria-label="list of users"
    fallback-value="-"
    select-mode="radio"
    class="disabled-list"
    :disabled="!props.disableFields"
    :data="defaultListOfInstallments"
    :hide-menu="true"
    :headers="headerWithCombo"
    :selected="{ id: '0' }"
    :sort-positions="positionWithCombo"
    @listitem:click="() => {}"
    @listitem:selected="() => {}"
  >
    <template #header:id="{ data }">
      <strong>{{ data.key }}</strong>
    </template>

    <template #value:installment_with_benefits="{ data }">
      {{ formatMoney(data.value) }}
    </template>

    <template #column:combo="{ data }">
      <span v-if="data.value" class="ticker">Combo Fácil</span>
      <span v-else />
    </template>
  </SolList>
</template>

<style lang="scss">
.disabled-list{
   .list-item.-selected {
    @apply bg-neutral-high-medium cursor-not-allowed border-brand-primary-light opacity-50;
    .sol-radio-core>.radio:checked {
      @apply bg-brand-primary-dark border-brand-primary-light;
    }
   }
   .ticker {
    @apply  text-brand-primary-dark rounded-full;
   }
}
</style>
