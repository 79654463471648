<script setup lang="ts">
import { SolSidebar } from '@solfacil/girassol'
import type { FunctionalComponent, SVGAttributes } from 'vue'
import IconDashboard from '~icons/girassol/dashboard'
import IconMoney from '~icons/girassol/attach-money'
import IconMonetization from '~icons/girassol/monetization-on'
import IconCalculate from '~icons/girassol/calculate'
import IconPayments from '~icons/girassol/payments'
import PaymentBFFService from '~/services/paymentBFF/PaymentBFF'
import type { User } from '~/utils/auth/User'
import { Journey } from '~/types/enum'
const props = defineProps<{
  isOpenSidebar: boolean
}>()

defineEmits<{ (e: 'close'): void }>()

const openSidebar = ref(props.isOpenSidebar)
const flagNewJourney = ref()
const user: User = useAppStorage().get('user')
watch(() => props.isOpenSidebar, () => openSidebar.value = props.isOpenSidebar)

export interface Group {
  title: string

  items?: Item[]
}
export interface Item {
  title: string
  link?: string
  external?: boolean
  items?: Item[]
}
export type MenuSidebar = Group | Item

const config = import.meta.env
const router = useRouter()
const activeMenu = (routeMenu: string) => {
  return !!router.currentRoute.value.fullPath.includes(routeMenu)
}

interface Product {
  id: 'portal' | 'shop' | 'financing' | 'ampera'
  icon?: FunctionalComponent<SVGAttributes, {}>
  title: string
  action?: Function
}

const product = {
  id: 'financing',
  icon: IconMoney,
  title: 'Financiamento',
  action: () => {
    const location = flagNewJourney.value === Journey.OLD
      ? `${config.VITE_URL_LEGACY}/financiamentos`
      : '/financing/list'

    window.location.href = location
  },
} as Product

const items = ref([
  {
    icon: IconDashboard,
    name: 'Início',
    isActive: false,
    action: () => {
      const location = flagNewJourney.value === Journey.OLD
        ? `${config.VITE_URL_LEGACY}/financiamentos`
        : '/financing/list'

      window.location.href = location
    },
  },

  {
    icon: IconCalculate,
    name: 'Simulações',
    isActive: activeMenu('/simulation/list'),
    action: () => window.location.href = '/simulation/list',
  },
])

const simulationListRoute = ref(false)

watch(router.currentRoute, () => {
  const simulationRouter = router.currentRoute.value.path === '/simulation/list'
  simulationListRoute.value = simulationRouter

  if (simulationListRoute.value) {
    items.value.forEach((item) => {
      if (item.name === 'Simulações')
        item.isActive = simulationRouter
      else
        item.isActive = false
    })
  }
})

const checkPartnerAccount = async () => {
  const paymentBFFService = new PaymentBFFService(useApi('paymentBFF'))

  const hasAccount = await paymentBFFService.hasAccountManagement()

  if (hasAccount) {
    items.value.push({
      icon: IconPayments,
      name: 'Extrato Solfácil',
      isActive: activeMenu('/bank-statement'),
      action: () => window.location.href = '/bank-statement',
    })
  }
}

function showItensSidebar() {
  const perfilAdmin = ['admin', 'gerente']

  if (perfilAdmin.includes(user.perfil) || flagNewJourney.value !== Journey.OLD) {
    items.value.push({
      icon: IconMonetization,
      name: 'Financiamentos',
      isActive: activeMenu('/financing/list'),
      action: () => window.location.href = '/financing/list',
    })
  }
}

const featureFlags = async () => {
  flagNewJourney.value = await useFlag('new_journey', { partner_id: user?.parceiro?.id })

  showItensSidebar()
}
onBeforeMount(() => {
  featureFlags()
})

onMounted(() => {
  checkPartnerAccount()
})
</script>

<template>
  <div>
    <SolSidebar
      :items="items"
      :product="product"
      :is-open-mobile-sidebar="openSidebar"
      @click-menu="openSidebar = !openSidebar"
    />
  </div>
</template>
