<script setup lang="ts">
const props = defineProps({
  image: { type: String, default: '/images/conquista.svg' },
  imageAlt: { type: String, default: '' },
  title: { type: String, default: '' },
  subtitle: { type: String, default: '' },
})

const { t } = useI18n()
</script>

<template>
  <div class="banner">
    <img class="image" :src="props.image" :alt="props.imageAlt || t('bannerSolfacilPlus.img.alt')">

    <div class="text">
      <span class="title-banner">{{ title || t('bannerSolfacilPlus.title') }}</span>
      <span class="subtitle-banner">{{ subtitle || t('bannerSolfacilPlus.subtitle') }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.banner {
  background: linear-gradient(85deg, #00B569 57.29%, #9FF312 127.21%);
  @apply px-3 md:site:px-8 rounded-md text-neutral-high-pure flex gap-3 md:site:gap-6;

  .image {
    @apply md:site:ml-6 w-[44px] md:site:w-auto;
  }

  .text {
    @apply py-4;

    .title-banner {
      @apply block font-bold text-micro md:site:text-xs;
    }

    .subtitle-banner {
      @apply text-micro md:site:text-3xs;
    }
  }
}
</style>
