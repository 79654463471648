<script setup lang="ts">
import IconDoneAll from '~icons/girassol/done-all'
import IconDownload from '~icons/girassol/download'
import downloadFile from '~/utils/download'

const props = defineProps<{
  text: string
  download: {
    link: string
    name?: string
  }
  action?: {
    text: string
  }
}>()

const handleOnActionClick = () => {
  downloadFile.downloadFile(props.download.link)
}
</script>

<template>
  <div class="wrapper">
    <div class="content-wrapper">
      <IconDoneAll class="text-feedback-positive-pure w-6 h-6" />

      <span class="fonts-body-large-bold text-neutral-low-dark">
        {{ props.text }}
      </span>
    </div>

    <SolButton
      v-if="props.action"
      id="uploaded-file-action"
      variant="tertiary"
      size="small"
      @click="handleOnActionClick"
    >
      <template #icon:left>
        <IconDownload />
      </template>

      {{ props.action.text }}
    </SolButton>
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  @apply p-4;

  @apply bg-brand-secondary-light rounded-lg;

  & .content-wrapper {
    @apply flex items-center gap-2 mb-2;
  }

  @screen md:system {
    @apply flex justify-between p-6;

    & .content-wrapper {
      @apply mb-0;
    }
  }
}
</style>
