import type { KeycloakConnections } from '~/types'

export default {
  hmla2: {
    url: 'https://stg-sso.solfacil.com.br',
    realm: 'hmla2',
    clientId: 'legacy',
  },
  hmla: {
    url: 'https://stg-sso.solfacil.com.br',
    realm: 'hmla',
    clientId: 'legacy',
  },
  dev: {
    url: 'https://stg-sso.solfacil.com.br',
    realm: 'General',
    clientId: 'product-simulator',
  },
  prod: {
    url: 'https://sso.solfacil.com.br',
    realm: 'General',
    clientId: 'product-simulator',
  },
  stg: {
    url: 'https://stg-sso.solfacil.com.br',
    realm: 'General',
    clientId: 'product-simulator',
  },
} as KeycloakConnections
