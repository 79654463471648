import RestCore from './RestCore'
import RestSimulator from './RestSimulator'
import RestInstallation from './RestInstallation'
import ReceiptModel from './RestReceiptModel'
import RestLdk from './RestLdk'
import RestCustomerRegister from './RestCustomerRegister'
import RestCustomerBiometry from './RestCustomerBiometry'
import RestCompanyRegister from './RestCompanyRegister'
import RestSolfacilPlus from './RestSolfacilPlus'
import RestMixpanel from './RestMixpanel'
import RestPaymentBFF from './RestPaymentBFF'
import RestFinancingFlow from './RestFinancingFlow'

const rest = {
  core: RestCore,
  simulator: RestSimulator,
  installation: RestInstallation,
  receiptModel: ReceiptModel,
  ldk: RestLdk,
  customerRegister: RestCustomerRegister,
  customerBiometry: RestCustomerBiometry,
  companyRegister: RestCompanyRegister,
  solfacilPlus: RestSolfacilPlus,
  mixpanel: RestMixpanel,
  paymentBFF: RestPaymentBFF,
  financingFlow: RestFinancingFlow,
}

export type RestConfig = keyof typeof rest
export default rest
