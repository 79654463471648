<script setup lang="ts">
const props = defineProps<{ open: boolean }>()

const emit = defineEmits<{
  (e: 'closeModal', v: boolean)
  (e: 'newJourney', v: boolean)
}>()

const toggleModal = ref(false)

onMounted(() => {
  toggleModal.value = props.open
})

watch(() => props.open, (value) => {
  toggleModal.value = value
})

const { t } = useI18n()
const isLargeScreen = useMediaQuery('(min-width: 1024px)')

const img = {
  desktop: 'https://s3.sa-east-1.amazonaws.com/cdn.solfacil.com.br/financial-simulator/images/financing-view.svg',
  mobile: 'https://s3.sa-east-1.amazonaws.com/cdn.solfacil.com.br/financial-simulator/images/financing-view-phone.svg',
}

const benefits = [
  {
    svg: 'https://s3.sa-east-1.amazonaws.com/cdn.solfacil.com.br/financial-simulator/images/benefits-1.svg',
    alt: 'icon-mobile',
    title: t('simulation.modal.info.proposal.title'),
    description: t('simulation.modal.info.proposal.description'),
    classText: 'pl-2 sm:system:pl-6 pr-2',
    classes: 'right-0',
  },
  {
    svg: 'https://s3.sa-east-1.amazonaws.com/cdn.solfacil.com.br/financial-simulator/images/benefits-2.svg',
    alt: 'icon-speed',
    title: t('simulation.modal.info.projects.title'),
    description: t('simulation.modal.info.projects.description'),
    classText: 'pl-[1px] pr-[1px] sm:system:pl-4',
    classes: 'left-0 rounded-l-md',
  },
  {
    svg: 'https://s3.sa-east-1.amazonaws.com/cdn.solfacil.com.br/financial-simulator/images/benefits-3.svg',
    alt: 'icon-arrow-random',
    title: t('simulation.modal.info.payments.title'),
    description: t('simulation.modal.info.payments.description'),
    classText: 'pl-2 sm:system:pl-6 pr-1',
    classes: 'right-0',
  },
]

const orderIcon = (index: number) => {
  const order = index % 2 === 0 ? 1 : 0
  return order
}

const getClassForIndex = (index: number) => {
  return index % 2 === 0 ? 'rounded-r-md' : 'rounded-l-md'
}
</script>

<template>
  <SolModal
    id="modal"
    :is-open="toggleModal"
    :title="t('simulation.modal.title')"
    :action-primary-text="t('simulation.modal.button.new')"
    :action-tertiary-text="t('simulation.modal.button.old')"
    :size="{
      desktop: 'large',
      mobile: 'bottom-sheet',
    }"
    @close="toggleModal = false"
    @action:primary="emit('newJourney', true)"
    @action:tertiary="emit('newJourney', false), toggleModal = false"
  >
    <div class="content grid grid-cols-2 gap-3">
      <!-- benefits -->
      <div class="grid gap-3">
        <div
          v-for="(item, index) in benefits"
          :key="index"
          class="grid grid-cols-4 md:system:grid-cols-7 gap-1 bg-neutral-high-light rounded-md items-center sm:system:h-[84px] relative"
        >
          <div
            class="gradient col-span-1 md:system:col-span-2 h-full"
            :class="getClassForIndex(index)"
            :style="{ order: orderIcon(index) }"
          >
            <div class="flex justify-center items-center h-full">
              <img
                class="sm:system:absolute"
                :class="item.classes"
                :src="item.svg"
                :alt="item.alt"
              >
            </div>
          </div>

          <div class="items-start py-2 col-span-3 md:system:col-span-5" :class="item.classText">
            <h2 class="text p-0 font-bold text-micro sm:system:text-2xs text-neutral-low-dark leading-4 mb-1">
              {{ item.title }}
            </h2>
            <p class="text-micro sm:system:text-3xs text-neutral-low-light leading-4">
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>

      <!-- image -->
      <img
        class="md:system:w-[84%]"
        :class="{ 'rounded-lg max-w-[100px]': !isLargeScreen }"
        :src="isLargeScreen ? img.desktop : img.mobile"
        :alt="t('simulation.modal.altImg')"
      >
    </div>
  </SolModal>
</template>

<style scoped lang="scss">
  .content {
    @apply flex mb-6 justify-center md:site:justify-between items-center gap-6;
  }

  .gradient {
    background: linear-gradient(135deg, #00B569 57.29%, #90ED1B 127.21%);
  }
</style>
