import { defineStore } from 'pinia'

export const useCollapseStore = defineStore('collapse', () => {
  const isCollapse = ref(true)
  function setCollapse(collapse: boolean) {
    isCollapse.value = collapse
  }

  return { isCollapse, setCollapse }
})
