<script lang="ts">
import { defineComponent } from 'vue'

interface SignalBar {
  strength: number
}

export default defineComponent({
  props: {
    signal: {
      type: Object as () => SignalBar,
      required: true,
    },
  },
  methods: {
    barClasses(index: number) {
      return {
        '!bg-feedback-negative-pure': this.signal.strength === 1 && this.signal.strength === index,
        '!bg-feedback-warning-pure': this.signal.strength === 2 && this.signal.strength >= index,
        '!bg-brand-secondary-pure': this.signal.strength === 3 && this.signal.strength >= index,
        '!bg-brand-secondary-dark': this.signal.strength >= 4 && this.signal.strength >= index,
      }
    },
  },
})
</script>

<template>
  <div v-if="signal.strength > 0" class="signal-bar">
    <div v-for="index in 4" :key="index" class="bar" :class="barClasses(index)" />
  </div>
</template>

<style lang="scss" scoped>
.signal-bar {
  @apply flex overflow-hidden items-baseline w-[22px] gap-[2px];

  .bar {
    @apply flex-1 bg-neutral-high-medium;

    &:nth-child(1) {
      @apply h-1;
    }

    &:nth-child(2) {
      @apply h-2;
    }

    &:nth-child(3) {
      @apply h-3;
    }

    &:nth-child(4) {
      @apply h-4;
    }
  }
}
</style>
