<script setup lang="ts">
import SimulatorService from '~/services/simulator/Simulator'
import type { Project } from '~/utils/project/Project'
import type { User } from '~/utils/auth/User'
import type { RequestSimulationOpportunities } from '~/utils/simulation/Simulation'
import { Journey } from '~/types/enum'

const router = useRouter()

const simulatorService = new SimulatorService(useApi('simulator'))
const projectResponse = ref<Project | undefined>(undefined)

const user: User = useAppStorage().get('user')

const loadingProject = ref(false)
const defaultCommission = ref(0)
const defaultCommissionRaw = ref(0)
const defaultGracePeriod = ref(1)
const defaultInstallment = ref(12)
const showCommissionMoney = ref(false)
const riskRioGrandeDoSul = 9
const simulation = ref({})
const projectIsFinished = ref(false)

const { setProject } = useResumeStore()

function mountNewQuotas(project: Project) {
  defaultCommission.value = project.selected_installments.commission
  defaultCommissionRaw.value = project.selected_installments?.commission_raw || 0
  defaultGracePeriod.value = project.selected_installments.grace_period
  defaultInstallment.value = project.selected_installments.installments
  projectIsFinished.value = project.complete
}

let project: any = ref({})
let projectShoppingCartId: any = ref('')

const { setSimulationOpportunities } = useResumeStore()

async function getShoppingCartId() {
  try {
    const response = await simulatorService.get_simulation_by_shopping_cart_id(String(router.currentRoute.value.params.id))
    projectShoppingCartId = response.data
  }
  catch (e) {
    projectShoppingCartId = ''
  }
}

async function setOpportunities(project: Project) {
  const payload: RequestSimulationOpportunities = {
    partner_id: project.partner_id,
    financing_value: project.project_value - project.down_payment,
    project_type: project.person_type,
  }

  const solfacilPlusPoints = ref(false)
  if (project.partner_id)
    solfacilPlusPoints.value = await useFlag('show-solfacil-plus-points', { partner_id: project.partner_id })

  if (solfacilPlusPoints.value) {
    const response = await simulatorService.post_simulation_opportunities(payload)
    if (response.data)
      setSimulationOpportunities(response.data)
  }
}

onMounted(async () => {
  try {
    loadingProject.value = true

    showCommissionMoney.value = await useFlag('show-commission-money', { partner_id: user?.parceiro?.id })
    const { data } = await simulatorService.get_project(router.currentRoute.value.params.id)

    project = data

    if (project?.project_status === 'INACTIVE' && !project.complete)
      return router.push('/simulation/list')

    const newJourneyFlag = await useFlag('new_journey', { partner_id: user?.parceiro?.id }) as Journey

    if (newJourneyFlag === Journey.NEW || project.new_journey)
      return router.push('/financing/list')

    setOpportunities(project)

    setProject(project)

    projectResponse.value = project

    const flagStoreMarketFinancing = await useFlag('store-showcase', { partner_id: user?.parceiro?.id })
    if (flagStoreMarketFinancing)
      await getShoppingCartId()

    if (project && project.selected_installments)
      return mountNewQuotas(project)

    loadingProject.value = false
  }
  catch {
    loadingProject.value = false
  }
  finally {
    loadingProject.value = false
  }
})
</script>

<template>
  <NavigationHeaderBar stage="simulation" :title="projectResponse?.project_name" />
  <div class="container">
    <div v-if="loadingProject" class="installments-container-loader">
      <div class="loader-project" />
      <div class="loader-project hidden md:system:block" />
    </div>
    <SolAlert
      v-if="projectResponse?.decision_pre_analisys === riskRioGrandeDoSul && !loadingProject"
      id="title"
      class="mb-4xs md:site:mb-10"
      :title="$t('simulation.info_risk.RS_title')"
      feedback="warning"
    >
      <div>
        <p>{{ $t('simulation.info_risk.RS_description_conditions') }}</p>
        <ul class="list-disc list-inside">
          <li> {{ $t('simulation.info_risk.RS_description_grace') }} </li>
          <li> {{ $t('simulation.info_risk.RS_description_eligible_area') }} </li>
        </ul>
      </div>
    </SolAlert>
    <SimulationRevision
      v-if="projectResponse && !loadingProject"
      :simulation="simulation"
      :disable-fields="!projectIsFinished"
      :project="projectResponse"
      :default-installment="defaultInstallment"
      :default-grace-period="defaultGracePeriod"
      :default-commission="defaultCommission"
      :default-commission-raw="defaultCommissionRaw"
      :cet="project?.selected_installments?.cet"
      :shopping-cart-id="projectShoppingCartId"
      :show-commission-money="showCommissionMoney"
    />
    <div v-if="!projectResponse && !loadingProject" class="my-4 card-container flex flex-col justify-between w-full align-middle py-lg text-center items-center">
      <img src="/images/not-found.png" class="not-found">
      <h1 class="fonts-heading-h1">
        Ops, algo deu errado
      </h1>
      <p class="fonts-subtitle-medium">
        Ocorreu algum problema e não conseguimos trazer o resultado da sua simulação. Tente novamente mais tarde
      </p>
    </div>
  </div>
</template>

<style lang="scss">
.container {
  @apply flex flex-col items-start px-4xs pb-6 mt-6;
  @screen md:system {
    @apply p-12 mt-0;
  }
}

.installments-container-loader{
  @apply w-full block;
  @screen md:system {
    @apply grid grid-cols-6 gap-4;
    @apply grid gap-4;
    grid-template-columns: minmax(0, 1fr) 300px
  }
}

.loader-project {
  @apply h-96 rounded-lg mt-5;
  @apply top-0 right-0 bottom-0 left-0 z-50;
  background: rgba(#fff, 0.9)
    url(https://cdn.solfacil.com.br/assets/img/loading-for-legacy.gif) no-repeat
    center center;
}

.card-container{
  @apply px-12 my-6 bg-neutral-high-pure rounded-lg;

}
</style>

<route lang="yaml">
meta:
  layout: simulation
    </route>
