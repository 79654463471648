<script setup lang="ts">
const props = defineProps<{
  solfacilId: string
  documentNumber: string
  formalizedAt: string
}>()

const isLargeScreen = useMediaQuery('(min-width: 1024px)')
const { track } = useMixpanel()

const formatDate = (date: string) => {
  const [year, month, day] = date.split('-')
  return `${day}/${month}/${year}`
}

const handleOnAccordionClick = () => {
  track('solfacil-statement_accordion_lista')
}
</script>

<template>
  <div class="__accordion-list-item-wrapper">
    <div class="rounded-lg border-solid border-neutral-high-medium border-1 p-4xs">
      <div v-if="!isLargeScreen" class="grid grid-cols-2 mb-4xs">
        <div>
          <p class="item-heading">
            ID Solfácil
          </p>
          <p class="item-text">
            {{ props.solfacilId }}
          </p>
        </div>
        <div>
          <p class="item-heading">
            CPF/CNPJ
          </p>
          <p class="item-text">
            {{ props.documentNumber }}
          </p>
        </div>
      </div>

      <SolDivider v-if="!isLargeScreen" thickness="x-small" />

      <SolAccordion id="bank-statement-item" @click.stop="handleOnAccordionClick">
        <template #title>
          <div v-if="isLargeScreen">
            <p class="item-heading">
              ID Solfácil
            </p>
            <p class="item-text">
              {{ props.solfacilId }}
            </p>
          </div>
          <div v-if="isLargeScreen">
            <p class="item-heading">
              CPF/CNPJ
            </p>
            <p class="item-text">
              {{ props.documentNumber }}
            </p>
          </div>
          <div>
            <p class="item-heading">
              Formalizado em
            </p>
            <p class="item-text">
              {{ formatDate(props.formalizedAt) }}
            </p>
          </div>
        </template>

        <slot />
      </SolAccordion>
    </div>
  </div>
</template>

<style lang="scss">
.__accordion-list-item-wrapper {
  .item-heading {
    @apply text-neutral-low-light fonts-body-small-regular;
  }

  .item-text {
    @apply text-neutral-low-pure fonts-body-medium-regular;
  }

  .sol-accordion-core {
    @apply mt-4xs;
    @apply border-b-none;

    @screen md:system {
      @apply mt-0;
    }

    .summary {
      @apply p-0;

      &:hover {
        @apply bg-neutral-high-pure;
      }

      .container-title {
        @screen md:system {
          @apply flex gap-8;
        }
      }
    }

    .accordion-content {
      @apply p-0;
      @apply border-b-none;
      @apply bg-neutral-high-pure;
    }
  }

  .sol-accordion-core[open] {
    .summary {
      @apply bg-neutral-high-pure;
    }
  }
}
</style>
