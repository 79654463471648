<script setup lang="ts">
import { SolEmptyState } from '@solfacil/girassol'
import SimulatorService from '~/services/simulator/Simulator'
import type { Project } from '~/utils/project/Project'
import { conventionalNameFormat } from '~/utils/formatter/name'
import IMaterialSymbolsAdd from '~icons/material-symbols/add'
import type { OptionsFilters, SelectedFilters } from '~/utils/financing-flow/Financing'
import type { SimulationList, SimulationsListParams } from '~/utils/simulation/Simulation'
import type { ResponseModel } from '~/utils/connections/rest/RestConnections'

const { track } = useMixpanel()
const { t } = useI18n()
const { formatMoney } = useMoney()

const router = useRouter()
const isEnabledToBNPL = ref(false)
const flagBnplInterest = ref(false)
const optionsFiltersList = ref({} as OptionsFilters)
const filtersSimulationValue = ref<SelectedFilters>()
const query = ref<string>()

const FilterStatus = {
  done: t('simulation.status_filter.done'),
  pending: t('simulation.status_filter.pending'),
  reproved: t('simulation.status_filter.reproved'),
}

interface IHeader {
  [key: string]: {
    [key: string]: string
  }
}

const header: IHeader = {
  project_name: {
    text: 'Nome do projeto',
  },
  document: {
    text: 'CPF/CNPJ',
    width: '135px',
  },
  person_type: {
    text: 'Tipo',
    width: '26px',
  },
  project_value: {
    text: 'Valor do projeto',
    width: '110px',
  },
  created_at: {
    text: 'Criado em',
    width: '95px',
  },
  expiration_date: {
    text: 'Expira em',
    width: '70px',
  },
  credit_analysis_status: {
    width: '140px',
  },
}

const positions = {
  desktop: [
    'project_name',
    'document',
    'person_type',
    'project_value',
    'created_at',
    'expiration_date',
    'credit_analysis_status',
  ],
  mobile: [
    ['project_name', 'document'],
    ['person_type', 'project_value'],
    ['created_at', 'expiration_date'],
    ['credit_analysis_status'],
  ],
}

const simulatorService = new SimulatorService(useApi('simulator'))
const openModalBnpĺ = ref(false)
const loadingList = ref(false)
const projects = ref<Project[]>([])
const projectError = ref<boolean>(false)
const orderSelected = ref({ name: 'Mais recentes', value: 'desc' })
const total = ref()
const page = ref(1)
const options = [
  { name: 'Mais recentes', value: 'desc' },
  { name: 'Mais antigos', value: 'asc' },
]

const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24
const MINIMUM_DAYS_LEFT = 0
const DAYS_TO_EXPIRE = 25

onBeforeMount(async () => {
  fetchProjects()
})

function getType(status: unknown, index: number) {
  const negativeType = (projects.value[index].project_status === 'INACTIVE' && !projects.value[index].complete) || status === 'reproved'
  if (negativeType)
    return 'negative'
  if (status === 'pending')
    return 'informative'

  return 'positive'
}

function getLabel(status: unknown, index: number) {
  const nextTickPromise = new Promise<void>((resolve) => { resolve() })
  const item = document.getElementById(`listitem-simulation-list-${index}`)
  const isExpired = projects.value[index].project_status === 'INACTIVE' && !projects.value[index].complete

  if (isExpired) {
    nextTickPromise.then(() => {
      setExpiredListItem(item, true)
    })

    return 'Expirado'
  }
  else {
    setExpiredListItem(item)
  }

  if (status === 'pending')
    return 'Em processamento'

  if (status === 'reproved')
    return 'Reprovado'

  return 'Aprovado'
}

function selectOrder(value: any) {
  orderSelected.value = value
}

function setPage(value: any) {
  page.value = value
  fetchProjects()
}

function goToNewPage() {
  router.push('/simulation/new')
}

function redirectProject(project: any) {
  const isExpired = project.project_status === 'INACTIVE' && !project.complete
  if (project.credit_analysis_status === 'pending' || isExpired)
    return

  router.push(`/simulation/result/${project.id}`)
}

function formatDocument(document: string) {
  if (document.length === 11)
    return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')

  else if (document.length === 14)
    return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')

  return document
}

function formatDate(date: string) {
  return new Date(`${date}T00:00:00`).toLocaleDateString('pt-BR')
}

function listAdapter(project: Project[]): Project[] {
  return project.map((project: Project) => {
    return {
      ...(project as Project),
      project_value: formatMoney(project.project_value) as unknown as number,
      document: formatDocument(project.document),
      created_at: formatDate(project.created_at),
      expiration_date: formatDaysLeft(project.created_at),
    }
  })
}

const defaultSteps = ['formalization', 'installation', 'concluded', 'expired']

function parametersToFilter() {
  const params: SimulationsListParams = {
    page: page.value - 1,
    offset: 10,
    order: orderSelected.value.value as 'asc' | 'desc',
    steps: defaultSteps,
  }

  if (query.value)
    params.query = query.value

  if (filtersSimulationValue.value?.statusField?.value)
    params.credit_analysis_status = filtersSimulationValue.value.statusField.value

  return params
}

async function fetchProjects(searchSimulationValue?: string) {
  try {
    loadingList.value = true

    const params = parametersToFilter()
    const response = await simulatorService.get_list_of_projects({ ...params })

    if (response.data && response.total)
      handleResponseData(response)

    if (response.data === null)
      projects.value = []

    if (projectError.value)
      projectError.value = false
  }
  catch {
    projectError.value = true
  }
  finally {
    loadingList.value = false
  }
}

async function handleResponseData(response: SimulationList) {
  total.value = response.total
  projects.value = listAdapter(response.data)

  if (response.total_by_credit_analysis_status) {
    const filterStatus = await Object.keys(response.total_by_credit_analysis_status).map(key => ({
      name: `${FilterStatus[key]} (${response.total_by_credit_analysis_status[key]})`,
      value: key,
    }))

    optionsFiltersList.value = {
      title: t('financing.filters.title'),
      filters: [
        {
          id: 'status-field',
          class: 'w-full md:system:w-[180px]',
          placeholder: t('financing.filters.status'),
          size: 'small',
          name: 'statusField',
          label: '',
          options: filterStatus,
        },
      ],
    }
  }
}
function setExpiredListItem(element: HTMLElement | null, disabled = false) {
  if (element) {
    if (disabled) {
      element.style.cursor = 'default'
      element.style.pointerEvents = 'none'
    }
    else {
      element.style.cursor = 'pointer'
      element.style.pointerEvents = 'auto'
    }
  }
}
function formatDaysLeft(date: string) {
  const daysLeft = calculateDaysLeft(date)

  return t('list.days', { count: daysLeft })
}

function calculateDaysLeft(date: string) {
  const expirationDate = new Date(date)

  expirationDate.setDate(new Date(date).getDate() + DAYS_TO_EXPIRE)

  const timeDifferenceInMilliseconds = expirationDate.getTime() - new Date().getTime()
  const timeDifferenceInDays = Math.ceil(timeDifferenceInMilliseconds / MILLISECONDS_PER_DAY)

  return Math.max(MINIMUM_DAYS_LEFT, timeDifferenceInDays)
}

async function setFeatureFlags() {
  const user = useAppStorage().get('user')

  isEnabledToBNPL.value = await useFlag<boolean>('bnpl', { partner_id: user?.parceiro?.id })
  flagBnplInterest.value = await useFlag<boolean>('bnpl-interest', { partner_id: user?.parceiro?.id })
}

const refreshValueBnpl = () => {
  openModalBnpĺ.value = true
  track('simulation-list_banner_saiba-mais-bnpl', { trigger: 'Botão Saiba mais no banner bnpl na listagem de simulações' })
}

onMounted(() => {
  track('simulation-list_page-view', { trigger: 'Ver página da listagem de simulações' })

  setFeatureFlags()
})

watch(query, () => {
  page.value = 1
  fetchProjects(query.value)
})

watch(orderSelected, () => {
  page.value = 1
  fetchProjects()
})

function applyFilters(value: SelectedFilters) {
  filtersSimulationValue.value = value
  page.value = 1
  fetchProjects()
}
</script>

<template>
  <div class="container-simulation-list">
    <section class="section-title">
      <div>
        <h1 class="fonts-heading-h1">
          {{ t('simulation.list') }}
        </h1>
        <p class="text-brand-primary-medium">
          {{ t('simulation.subtitles') }}
        </p>
      </div>

      <SolButton
        id="btn-logout-collapse-sidebar"
        class="button-new-simulation"
        variant="primary"
        size="large"
        @click="goToNewPage"
      >
        <template #icon:left>
          <IMaterialSymbolsAdd class="!w-[24px] !h-[24px]" />
        </template>
        <span class="hidden sm:site:block">
          {{ t('simulation.new') }}
        </span>
      </SolButton>
    </section>

    <!-- Banner BNPL -->
    <SimulationBannerBnpl v-if="flagBnplInterest" @open="refreshValueBnpl" />

    <!-- Banner Kit + financiamento -->
    <section
      v-else
      class="banner"
    >
      <div class="flex justify-between">
        <img src="/images/combo_logo.svg">
        <img src="/images/combo_picture.svg" class="mx-md">
      </div>
      <div class="flex flex-col self-center">
        <h1 class="fonts-heading-h3 text-neutral-high-pure">
          {{ t('simulation.bannerComboFacil.title') }}
        </h1>
        <p class="fonts-subtitle-small text-neutral-high-pure">
          {{ t('simulation.bannerComboFacil.description') }}
        </p>

        <a href="https://app.solarinove.com.br/" target="_blank" class="link-banner">
          <span class="text-neutral-high-pure justify-end">
            Saiba mais
          </span>
        </a>
      </div>
    </section>

    <section
      v-if="(total ?? 0) > 0 || Boolean(query)"
      class="box-search p-6 rounded-lg bg-neutral-high-light flex flex-col md:system:flex-row gap-3"
    >
      <FinancingSearch @search-financing-value="(searchSimulationValue) => { query = searchSimulationValue; }" />
      <SharedFilters
        :options-filters-list="optionsFiltersList"
        @send-filters="applyFilters"
      />
    </section>

    <template v-if="loadingList">
      <div class="rounded-lg bg-neutral-high-pure w-full h-200 flex items-center justify-center">
        <Loading />
      </div>
    </template>

    <template v-else>
      <template v-if="projectError">
        <section class="empty-state">
          <SolEmptyState
            id="simulation-list-error"
            :title="t('simulation.errorProject.title')"
            :subtitle="t('simulation.errorProject.subtitle')"
            variant="empty-list"
          >
            <template #action>
              <div class="flex gap-2 my-xs inline-flex show-md">
                <SolButton
                  id="btn-logout-collapse-sidebar"
                  variant="secondary"
                  size="large"
                  @click="goToNewPage"
                >
                  {{ t('simulation.new') }}
                </SolButton>
                <SolButton
                  id="btn-logout-collapse-sidebar"
                  variant="primary"
                  size="large"
                  @click="fetchProjects"
                >
                  {{ t('simulation.load_again') }}
                </SolButton>
              </div>
            </template>
          </SolEmptyState>
        </section>
      </template>
      <section v-else-if="projects.length !== 0" class="list-container">
        <div class="list-container__filter">
          <span>{{ `${total ? total : 0} ${t('simulation.founded')}` }}</span>
          <div class="flex">
            <h2 class="text-brand-primary-pure font-bold self-center mr-micro">
              {{ t('financing.sort_by.label') }}
            </h2>
            <div class="w-56">
              <SolSelect
                id="select-order"
                name="select-order"
                size="small"
                :selected="orderSelected"
                :options="options"
                @update:selected="selectOrder"
              >
                <template #no-data>
                  <h2 class="text-feedback-negative-pure font-bold">
                    {{ t('financing.no_data') }}
                  </h2>
                </template>
              </SolSelect>
            </div>
          </div>
        </div>

        <SharedMessageInstallmentsProjects v-if="isEnabledToBNPL && !loadingList" event-mixpanel="simulation-list_button_ver-parcelas" />

        <SolList
          id="simulation-list"
          ref="list"
          :loading="loadingList"
          fallback-value="-"
          :data="projects"
          :hide-menu="true"
          :headers="header"
          :sort-positions="positions"
          class="min-h-20"
          @listitem:click="redirectProject"
        >
          <template #column:project_name="{ data }">
            <p class="text-property-value -left">
              <span class="property">{{ header[data.key as keyof IHeader].text }}</span>
              <stroke class="fonts-body-medium-bold truncate">
                {{ conventionalNameFormat(data.value as string) }}
              </stroke>
            </p>
          </template>
          <template #column:credit_analysis_status="{ data, columnIndex }">
            <SolTag :id="columnIndex.toString()" class="lg:site:w-full" :type="getType(data.value, columnIndex)" :text="getLabel(data.value, columnIndex)" />
          </template>
        </SolList>
        <SolPagination
          id="test"
          v-model:current="page"
          :total-pages="Math.ceil(total / 10) || 0"
          size="small"
          @update:current="setPage"
        />
      </section>
      <section v-else class="empty-state">
        <SolEmptyState
          id="simulation-not-found"
          :title=" t('simulation.notFound.title')"
          :subtitle="t('simulation.notFound.subtitle')"
          variant="empty-list"
        >
          <template #action>
            <div class="flex gap-2 inline-flex show-md">
              <SolButton
                id="btn-new-simulation"
                variant="primary"
                size="medium"
                @click="goToNewPage"
              >
                <template #icon-right>
                  <IconOpenInNew />
                </template>
                {{ t('simulation.new') }}
              </SolButton>
            </div>
          </template>
        </SolEmptyState>
      </section>
    </template>
  </div>

  <SolModal
    id="bnpl-modal"
    :is-open="openModalBnpĺ"
    :size="{
      desktop: 'medium',
      mobile: 'bottom-sheet',
    }"
    :title="t('simulation.bnpl.modal.title')"
    :action-primary-text="t('simulation.bnpl.modal.textButton')"
    @action:primary="openModalBnpĺ = false"
    @close="openModalBnpĺ = false"
  >
    <div class="flex mb-6 gap-8">
      <div class="flex flex-col items-center justify-center gap-3">
        <img src="/images/percent.svg" :alt="t('simulation.bnpl.modal.altImgOption1')">
        <span class="text-center font-highlight text-neutral-low-medium">{{ t('simulation.bnpl.modal.option1') }}</span>
      </div>
      <div class="flex flex-col items-center justify-center gap-3">
        <img src="/images/installments.svg" :alt="t('simulation.bnpl.modal.altImgOption2')">
        <span class="text-center font-highlight text-neutral-low-medium">{{ t('simulation.bnpl.modal.option2') }}</span>
      </div>
      <div class="flex flex-col items-center justify-center gap-3">
        <img src="/images/solar-plate.svg" :alt="t('simulation.bnpl.modal.altImgOption3')">
        <span class="text-center font-highlight text-neutral-low-medium">{{ t('simulation.bnpl.modal.option3') }}</span>
      </div>
    </div>
    <p class="text-center text-neutral-low-medium">
      {{ t('simulation.bnpl.modal.description') }}
    </p>
  </SolModal>
</template>

<style lang="scss">
.container-simulation-list {
  @apply bg-neutral-high-pure;
  .loader-list{
    @apply h-56 w-full;
    @apply top-0 right-0 bottom-0 left-0 z-50;
    background: rgba(#fff, 0.9)
      url(https://cdn.solfacil.com.br/assets/img/loading-for-legacy.gif) no-repeat
      center center;
  }
  @apply flex flex-col items-start px-4xs py-6 mt-0;
  @screen md:system {
    @apply p-12;
  }

  > .section-title{
    @apply flex justify-between w-full mb-md;
    >button {
      @apply p-4xs;
      text-indent: -9000px;
      @screen md:system {
        @apply w-auto mt-0 indent px-xs py-4xs;
      }
      >div {
        @apply mx-4xs;
      }
    }
  }

  .empty-state{
    @apply flex pt-4xs justify-center  w-full flex h-100;
    @screen max-md:site {
      @apply px-mega items-center h-180;
    }
  }
  > .list-container{
    @apply w-full;
    >.list-container__filter {
      @screen md:system {
        @apply flex w-full justify-between mb-4xs;
      }
      >div{
        @apply py-micro;
        @screen md:system {
          @apply py-0;
        }
      }
    }
  }
  > .banner{
    @apply h-auto w-full rounded-md mb-2xs  px-xs flex justify-between flex-wrap;
    background: linear-gradient(90deg, #BFFF00 -105.99%, #42CF45 -65.65%, #008859 134.97%);
    @screen md:system {
      @apply  mb-md;
    }
    .link-banner{
      @apply self-end;
      @apply font-bold;
      @apply mb-4xs mr-4xs;
      @screen md:system {
        @apply m-nano;
      }
    }
    >div {
      >img.mx-md {
        @apply mx-xs;
        @screen md:system {
        @apply mx-md;
        }
      }
    }
  }

  .button-new-simulation {
    @apply text-3xs;
    text-indent: 0.4rem !important;

    .icon {
      margin: 0 !important;
    }
  }

  > .box-search {
    @apply mb-2xs w-full;
  }
}

[data-tooltip].tooltip:after {
  width: 450px;
  white-space: break-spaces;
}

@media screen and (max-width: 767px) {
  [data-tooltip].tooltip:after {
    width: 300px;
  }
}
</style>

<route lang="yaml">
meta:
  layout: simulation
    </route>
