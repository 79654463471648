<script setup lang="ts">
const props = defineProps({
  active: { default: '', type: String },
  disabled: { default: false, type: Boolean },
  cet: { default: '', type: String },
})
const emit = defineEmits<{
  (e: 'selected', v: string): void
}>()

const firstOption = {
  title: 'Prefixado',
  key: 'PRE',
}
const secondOption = {
  title: 'Pós-fixado',
  key: 'POST',
}

onMounted(() => {
  let option = {
    key: '',
  }

  if (props.cet)
    option.key = props.cet
  else
    option = firstOption

  getClasses(option, true)
})

function getClasses(btnOptions: any, isMounted = false) {
  const firstOptionElement = document.getElementById('firstOption')

  if (!props.cet) {
    if (isMounted && btnOptions.key === 'PRE') {
      firstOptionElement?.classList.add(props.disabled ? 'btn-selected btn-disabled' : 'btn-selected')
      return ''
    }
    else {
      firstOptionElement?.classList.remove(props.disabled ? 'btn-selected btn-disabled' : 'btn-selected')
    }
  }

  if (props.active === '' && btnOptions.key === props.cet)
    return props.disabled ? 'btn-selected btn-disabled' : 'btn-selected'
  else if (btnOptions.key === props.active)
    return props.disabled ? 'btn-selected btn-disabled' : 'btn-selected'
  else
    return props.disabled ? 'btn-disabled' : ''
}
</script>

<template>
  <div class="buttons" :class="props.disabled ? 'disabled' : ''">
    <div
      id="firstOption"
      :key="firstOption.key"
      disable
      class="btn-selectable-left"
      :class="getClasses(firstOption)"
      @click="props.disabled ? null : emit('selected', firstOption.key)"
    >
      <div class="title">
        {{ firstOption.title }}
      </div>
    </div>
    <div
      :key="secondOption.key"
      disable
      class="btn-selectable-right"
      :class="getClasses(secondOption)"
      @click="props.disabled ? null : emit('selected', secondOption.key)"
    >
      <div class="title">
        {{ secondOption.title }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn-selectable-left{
  @apply border border-brand-primary-light rounded-l-md;
  @apply p-nano cursor-pointer font-bold;
}

.btn-selectable-right{
  @apply border border-brand-primary-light rounded-r-md;
  @apply p-nano cursor-pointer font-bold;
}
.btn-selected{
  @apply bg-brand-secondary-pure border-brand-secondary-pure;

  .title {
    @apply text-neutral-high-pure;
  }

}
.buttons {
  @apply flex;

}
.buttons.disabled {
  @apply opacity-75;
  > .btn-selected.btn-disabled {
    @apply bg-neutral-high-medium  border-brand-primary-light;
    >.title {
      @apply text-brand-primary-medium;
    }
  }
  > .btn-disabled {
    @apply cursor-not-allowed opacity-75;
  }
}
</style>
