import { useCookies } from 'vue3-cookies'

export function useCookie<T extends string | any>() {
  const { cookies } = useCookies()

  return {
    set: (name: string, value: T, expireTimes: string | number | Date | undefined = undefined): void => {
      if (typeof value === 'string')
        cookies.set(name, value, expireTimes)
      else
        cookies.set(name, JSON.stringify(value), expireTimes)
    },
    get: (name: string): T => {
      try {
        return cookies.get(name) as T
      }
      catch (e) {
        throw new Error('Cannot converter value to type definition.')
      }
    },
    remove(name: string) {
      cookies.remove(name)
    },
  }
}
