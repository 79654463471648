<script setup lang="ts">
const emit = defineEmits<(e: 'open') => void>()

const { t } = useI18n()

const openInformationsBnpl = () => {
  emit('open')
}
</script>

<template>
  <div class="banner-bnpl">
    <div class="flex justify-between w-full sm:system:w-auto items-center">
      <img src="/images/logo-solfacil_base--invert.svg" alt="Logo Solfácil">
      <img src="/images/attention.png" class="pt-4 md:system:pt-0 ml-md h-[85px] sm:system:h-[112px]" alt="Image representando a novidade">
    </div>

    <div class="flex flex-col sm:system:flex-row self-center gap-3 sm:system:gap-10 mb-4 lg:system:mb-0 w-full md:system:w-auto">
      <div>
        <h1 class="fonts-heading-h3 text-neutral-high-pure">
          {{ t('simulation.bnpl.banner.black.title') }}
        </h1>
        <p class="fonts-subtitle-small text-neutral-high-pure">
          {{ t('simulation.bnpl.banner.black.description') }}
        </p>
      </div>

      <SolButton
        id="more-informations"
        variant="secondary"
        size="small"
        :on-color="true"
        class="w-[120px] self-end sm:system:self-auto"
        @click="openInformationsBnpl"
      >
        {{ t('simulation.bnpl.banner.black.textButton') }}
      </SolButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.banner-bnpl {
  @apply h-auto w-full rounded-md mb-2xs px-xs flex flex-wrap bg-brand-primary-pure gap-4 justify-between;
  @screen md:system {
    @apply mb-md;
  }

  .link-banner {
    @apply self-end;
    @apply font-bold;
    @apply mb-4xs mr-4xs;
    @screen md:system {
      @apply m-nano;
    }
  }
  > div {
    > img.mx-md {
      @apply mx-xs;
      @screen md:system {
        @apply mx-md;
      }
    }
  }
}
</style>
