export default {

  /**
   *  Format a number to BRL currency
   *
   * @param value - The number to be formatted
   * @returns {string} BRL currency
   */
  formatBRL: (value: number | string): string => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(Number(value))
  },
}
