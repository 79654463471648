import { QueryClient } from '@tanstack/vue-query'
import type { SimulatorService } from './services/simulator-v2/simulator.types'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      retry: 2,
    },
  },
})

export const queryKeys = {
  FOO: ['FOO'],

  // user
  USER: () => ['USER'],

  // projects
  PROJECTS: (params: SimulatorService.ProjectsRequest) => ['PROJECTS', 'PAGE', params.page, 'SIZE', params.size, 'ORDER', params.order],
  PROJECT: (id: string) => ['PROJECT', id],

  // TODO Add more query keys here
  BNPL_SUMMARY: (id: string) => ['BNPL_SUMMARY', id],
  BNPL_INSTALLMENTS_MUTATION: (projectID: string) => ['BNPL_INSTALLMENTS', projectID],
  BNPL_INSTALLMENT: (id: string) => ['BNPL_INSTALLMENT', id],
}

export default queryClient

