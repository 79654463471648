import { useQuery } from '@tanstack/vue-query'
import { queryKeys } from '~/queryClient'
import simulatorV3Service from '~/services/simulator-v3/simulator.service'

export default function useBnplSummary(id: string) {
  return useQuery({
    queryKey: queryKeys.BNPL_SUMMARY(id),

    queryFn: async () => {
      const data = await simulatorV3Service.bnpl.getSummary(id)
      return data
    },

    staleTime: 1000 * 60 * 1, // 1 minute
  })
}
