<script setup lang="ts">
import type { User } from '~/utils/auth/User'
import { Journey } from '~/types/enum'
const router = useRouter()

const user: User = useAppStorage().get('user')

const fallbackRouter = async () => {
  const flagNewJourney = await useFlag('new_journey', { partner_id: user?.parceiro?.id })
  const path = flagNewJourney === Journey.OLD ? '/simulation/list' : '/financing/list'

  router.push(path)
}

onMounted(() => {
  fallbackRouter()
})
const { t } = useI18n()
</script>

<template>
  <main p="x4 y10" text="center teal-700 dark:gray-200">
    <div text-4xl>
      <div i-carbon-warning inline-block />
    </div>
    <RouterView />
    <div>
      <SolButton id="back" btn text-sm m="3 t8" @click="router.back()">
        {{ t('buttons.back') }}
      </SolButton>
    </div>
  </main>
</template>
