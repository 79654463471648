export class AuthorizationError extends Error {
  public code: number
  public cause?: Error

  constructor(message: string, code: number, cause?: Error) {
    super(message)

    this.name = 'AuthorizationError'
    this.code = code
    this.cause = cause
  }
}
