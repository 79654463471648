import BaseService from '../BaseService'
import type { User } from '~/utils/auth/User'
import type { DataResponse } from '~/types'

export default class AuthService extends BaseService {
  async me(): Promise<User> {
    try {
      const response = await super.api<DataResponse<User>>('/usuarios/me', {
        method: 'GET',
      })

      return Promise.resolve(response.data)
    }
    catch (e) {
      return Promise.reject(e)
    }
  }
}
