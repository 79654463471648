<script lang="ts" setup>
import { useToast } from '@solfacil/girassol'
import PaymentBFFService from '~/services/paymentBFF/PaymentBFF'
import IconDownload from '~icons/girassol/download'

const props = defineProps<{
  transactionId: string
}>()

const isLargeScreen = useMediaQuery('(min-width: 1024px)')
const { track } = useMixpanel()
const toast = useToast()

const loading = ref(false)

const handleOnDownloadStatementClick = async () => {
  loading.value = true

  try {
    track('solfacil-statement_button_download')

    const paymentBFFService = new PaymentBFFService(useApi('paymentBFF'))
    const fileBlob = await paymentBFFService.downloadBankStatement(props.transactionId)

    const url = window.URL.createObjectURL(fileBlob)
    const a = document.createElement('a')
    a.href = url
    a.download = `extrato-solfacil-${props.transactionId}.pdf`

    a.click()

    window.URL.revokeObjectURL(url)
  }
  catch (error) {
    toast.createErrorToast({
      title: 'Erro ao baixar comprovante',
      description: 'Não foi possível baixar o comprovante. Tente novamente mais tarde.',
    })
  }
  finally {
    loading.value = false
  }
}
</script>

<template>
  <SolButton
    :id="`transaction-id-${props.transactionId}`"
    variant="secondary"
    size="small"
    :class="{
      'w-8': !isLargeScreen,
    }"
    :loading="loading"
    @click.stop="handleOnDownloadStatementClick"
  >
    <template #icon:left>
      <IconDownload />
    </template>

    {{ isLargeScreen ? 'Baixar comprovante' : '' }}
  </SolButton>
</template>
