<script setup lang="ts">
const router = useRouter()

onMounted(async () => {
  const origin = router.currentRoute.value.query.origin as string
  const code = router.currentRoute.value.query.code as string
  await useKeycloak(origin).login(code)
  router.push({ path: origin })
})
</script>

<route lang="yaml">
meta:
  public: true
</route>
