import BaseService from '../BaseService'
import type { BiometryResponse } from '~/utils/customer-register/Biometry'

export default class CustomerBiometryService extends BaseService {
  async get_customer_biometry(id: string): Promise<BiometryResponse> {
    try {
      const response = await super.api<BiometryResponse>(`/v1/formalizations/info/${id}`, {
        method: 'GET',
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(new Error(String(e)))
    }
  }

  async post_customer_biometry(id: string, body: BiometryResponse): Promise<BiometryResponse> {
    try {
      const response = await super.api<BiometryResponse>('/v1/formalizations/', {
        method: 'POST',
        body: {
          project_id: id,
          contract_token_receipt_method: body.send_biometry_contract.method_receipt,
        },
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(new Error(String(e)))
    }
  }

  async post_customer_biometry_resend(id: string): Promise<BiometryResponse> {
    try {
      const response = await super.api<BiometryResponse>(`/v1/formalizations/resend/${id}`, {
        method: 'POST',
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(new Error(String(e)))
    }
  }

  async post_customer_biometry_cancel(id: string): Promise<BiometryResponse> {
    try {
      const response = await super.api<BiometryResponse>(`/v1/formalizations/cancel/${id}`, {
        method: 'POST',
      })

      return {
        ...response,
      }
    }
    catch (e) {
      return Promise.reject(new Error(String(e)))
    }
  }
}
