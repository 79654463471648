<script setup lang="ts">
import { SolOnboarding, useToast } from '@solfacil/girassol'
import SimulatorService from '~/services/simulator/Simulator'
import { conventionalNameFormat } from '~/utils/formatter/name'
import { useMoney } from '~/composables/money'
import type { Project } from '~/utils/project/Project'
import IconOpenInNew from '~icons/material-symbols/open-in-new'
import type { User } from '~/utils/auth/User'

const { track } = useMixpanel()
const { formatMoney } = useMoney()
const isEnabledToBNPL = ref(false)
const isEnabledToBNPLInterest = ref(false)

type ClientStatus = 'approved' | 'pending' | 'reproved' | 'pending_guarantor' | 'pending_risk_RS' | 'essential_profile'
type ClientStatusBNPL = 'approved' | 'reproved'

const {
  createErrorToast,
} = useToast()

const { t } = useI18n()

const user: User = useAppStorage().get('user')

const router = useRouter()
const goNewSimulation = () => {
  router.push('/simulation/new')
}

const nextButtonTitle = {
  approved: t('simulation.next'),
  pending: t('simulation.continue_with_value'),
  pending_guarantor: t('simulation.continue_with_value'),
  reproved: t('simulation.continue_with_value'),
}

const attentionCheckbox = ref(false)

const clientApproved = ref(true)
const modal = ref(false)

function openModal() {
  modal.value = true
}

function closeModal() {
  modal.value = false
}

const simulatorService = new SimulatorService(useApi('simulator'))

const projectResponse = ref<Project | null>(null)
const noResultMessage = ref(false)

const downPayment = ref(0)
const projectValue = ref(0)
const projectName = ref('')
const documentIsCpf = ref(true)
const denyTitleMessage = ref('')
const denyMessage = ref('Cliente reprovado')

const clientStatus = ref<ClientStatus>('approved')
const clientStatusBNPL = ref<ClientStatusBNPL>('approved')

const toggleImportantModal = ref(false)
const toggleAttentionModal = ref(false)
const toggleInterestModal = ref(false)

const analysisStatusSimulation = {
  approved: 1,
  pending: 2,
  pending_guarantor: 3,
  reproved_credit_policy: 4,
  reproved_internal_political_disagreement: 5,
  reproved_no_agreement: 6,
  reproved_credit_analysis_one: 7,
  reproved_credit_analysis_two: 8,
  pending_risk_RS: 9,
  essential_profile: 10,
}

const analysisStatusBNPL = {
  approved_without_co_obligation: 2,
  reproved_try_again_30_days: 4,
  reproved_block_list: 5,
  reproved_block_integrator: 6,
  approved_with_co_obligation: 10,
}

const analyzingSimulation = ref(false)

async function mountResponse() {
  const project_id = router.currentRoute.value.params.id
  try {
    const response = await simulatorService.get_project(project_id)
    const project: Project | undefined = response.data
    if (!project)
      return
    projectResponse.value = project

    documentIsCpf.value = project.document.length === 11
    downPayment.value = project?.down_payment
    projectValue.value = project?.project_value
    projectName.value = project?.project_name

    if (project.credit_analysis_status === 'pending') {
      analyzingSimulation.value = true
      return
    }

    if (project.project_status === 'INACTIVE' && !project.complete)
      return router.push('/simulation/list')

    if (project.risk_info.decision === analysisStatusSimulation.approved) // 1
      clientStatus.value = 'approved'

    if (project.risk_info.decision === analysisStatusSimulation.pending) // 2
      clientStatus.value = 'pending'

    if (
      project.risk_info.decision === analysisStatusSimulation.pending_guarantor
      && documentIsCpf
    ) // 3
      clientStatus.value = 'pending_guarantor'

    if (project.risk_info.decision === analysisStatusSimulation.pending_risk_RS) // 9
      clientStatus.value = 'pending_risk_RS'

    if (project.risk_info.decision === analysisStatusSimulation.essential_profile) // 10
      clientStatus.value = 'essential_profile'

    const statuses = [analysisStatusSimulation.pending_risk_RS, analysisStatusSimulation.essential_profile]

    if (
      project.risk_info.decision > analysisStatusSimulation.pending_guarantor
      && !statuses.includes(project.risk_info.decision)
    ) { // 4, 5, 6, 7 e 8
      clientStatus.value = 'reproved'

      if (project.risk_info.decision === analysisStatusSimulation.reproved_credit_policy) { // 4
        denyTitleMessage.value = 'Sentimos muito! Esse cliente foi reprovado por estar em desacordo com nossa política de crédito.'
        denyMessage.value = 'Existem 2 ações que você pode tomar: 1. Consulte os órgãos de proteção ao crédito e veja se esse CPF ou CNPJ está regular. 2. Consulte este CPF ou CNPJ novamente daqui 30 dias. Esse prazo é necessário porque pode ser que ocorram alterações nas situações dos mesmos.'
      }

      if (project.risk_info.decision === analysisStatusSimulation.reproved_internal_political_disagreement) { // 5
        denyTitleMessage.value = 'Reprova definitiva!'
        denyMessage.value = 'Infelizmente não podemos seguir com esse cliente, pois está em desacordo com a política interna. Essa não é uma reprova por motivos de crédito.'
      }

      if (project.risk_info.decision === analysisStatusSimulation.reproved_no_agreement) { // 6
        denyTitleMessage.value = 'Olá, parceiro! Atualmente você está impossibilitado de dar continuidade em novos financiamentos.'
        denyMessage.value = 'Isso pode acontecer por descumprimento de compromissos acordados entre você e a Solfácil, como: pendências nas instalações, falta de escolha do modelo de recebimento, ou outros motivos.'
      }

      if (
        project.risk_info.decision === analysisStatusSimulation.reproved_credit_analysis_one
        || project.risk_info.decision === analysisStatusSimulation.reproved_credit_analysis_two
      ) { // 7 e 8
        denyTitleMessage.value = 'Infelizmente, o cliente foi reprovado na análise de crédito'
        denyMessage.value = 'Tente novamente daqui a 30 dias'
      }

      clientApproved.value = false
    }

    if (project.risk_class === 'Z') {
      clientStatus.value = 'reproved'
      denyTitleMessage.value = 'Enviar para tela de espera (fila)'
      denyMessage.value = ''
    }

    if (project.bnpl) {
      if (project.bnpl.decision_pre_analysis === analysisStatusBNPL.approved_without_co_obligation) // 2
        clientStatusBNPL.value = 'approved'

      if (project.bnpl.decision_pre_analysis === analysisStatusBNPL.reproved_try_again_30_days) // 4
        clientStatusBNPL.value = 'reproved'

      if (project.bnpl.decision_pre_analysis === analysisStatusBNPL.reproved_block_list) // 5
        clientStatusBNPL.value = 'reproved'

      if (project.bnpl.decision_pre_analysis === analysisStatusBNPL.reproved_block_integrator) // 6
        clientStatusBNPL.value = 'reproved'

      if (project.bnpl.decision_pre_analysis === analysisStatusBNPL.approved_with_co_obligation) // 10
        clientStatusBNPL.value = 'approved'
    }
  }
  catch {
    noResultMessage.value = true
  }
}

async function setFeatureFlags() {
  const user = useAppStorage().get('user')
  isEnabledToBNPL.value = await useFlag<boolean>('bnpl', { partner_id: user?.parceiro?.id })
  isEnabledToBNPLInterest.value = await useFlag<boolean>('bnpl-interest', { partner_id: user?.parceiro?.id })
}

const shoppingCartId = ref('')
const loading = ref(false)
onMounted(async () => {
  shoppingCartId.value = String(router.currentRoute.value.query.shopping_cart_id) !== 'undefined' ? String(router.currentRoute.value.query.shopping_cart_id) : ''
  setFeatureFlags()
  await getFlagSubscriptionEnergy()
  try {
    loading.value = true
    await mountResponse()
  }
  finally {
    loading.value = false
  }

  track('simulation-result_page-view', { trigger: 'Ver página do resultado da análise de crédito da Simulação' })
})

async function updateSimulation() {
  try {
    loading.value = true

    if (!projectResponse.value?.pre_approved_value)
      return createErrorToast('Projeto sem valor pre aprovado.')

    const approveProjectData = {
      project_id: projectResponse.value.id,
    }

    const response = await simulatorService.approve_new_down_payment(approveProjectData)

    if (response)
      router.push(`/simulation/details/${response.data}${shoppingCartId.value ? `?shopping_cart_id=${shoppingCartId.value}` : ''}`)
  }
  catch (e: any) {
    createErrorToast(e?.response?._data?.details || 'Ocorreu um erro.')
  }
  finally {
    loading.value = false
  }
}

function submitResult() {
  track('simulation-result_button_seguir-financiamento', { trigger: 'Clique no botão "Seguir parcelamento"' })
  router.push(`/simulation/details/${router.currentRoute.value.params.id}${shoppingCartId.value ? `?shopping_cart_id=${shoppingCartId.value}` : ''}`)
}

function formatMonth(value: number) {
  if (!value)
    return '--'
  return value / 30
}

function formatDocument(value: string) {
  if (!value)
    return '--'

  if (value.length === 14)
    return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')

  return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

function recommendCustomerPage() {
  const url = 'https://cogecom.com.br/cogecom-solfacil/'
  window.open(url, '_blank')
}

const showSubscriptionEnergy = ref()

async function getFlagSubscriptionEnergy() {
  showSubscriptionEnergy.value = await useFlag('subscription-energy', { partner_id: user?.parceiro?.id })
}

const sliders = [
  {
    image: '/images/recommend_customer_benefits.png',
    title: '',
    content: '',
  },
  {
    image: '/images/recommend_customer_how-works.png',
    title: '',
    content: '',

  },
]

const subscriptionEnergyModal = ref(false)

function redirectBNPL() {
  resetValues()
  const isProduction = import.meta.env.MODE === 'production'
  track('simulation-result_button_seguir-parcelamento', { trigger: 'Clique sobre "seguir parcelamento"' })

  const urlProduction = `https://sso.solfacil.com.br/realms/General/protocol/openid-connect/auth?response_type=code&client_id=bnpl-parcelado&scope=openid%20profile%20email&redirect_uri=https://parcelado.solfacil.com.br/Pagamento?simulation_id=${router.currentRoute.value.params.id}`
  const urlStg = `https://stg-sso.solfacil.com.br/realms/General/protocol/openid-connect/auth?response_type=code&client_id=bnpl-parcelado&scope=openid%20profile%20email&redirect_uri=https://parcelado.solfacil.com.br/Pagamento?simulation_id=${router.currentRoute.value.params.id}`

  if (isProduction)
    window.open(urlProduction, '_blank')
  else
    window.open(urlStg, '_blank')
}

function actionKnowMore() {
  track('simulation-result_button_saber-mais', { trigger: 'Clique sobre "saber mais"' })
  toggleImportantModal.value = true
}

function actionInterest() {
  track('simulation-result_button_tenho-interesse', { trigger: 'Clique sobre "juros"' })
  toggleInterestModal.value = true
}

function openSimulationList() {
  router.push('/simulation/list')
}

function openNewSimulation() {
  router.push('/simulation/new')
}

function formattedValueInstallmentsBNPL() {
  return ((projectResponse.value?.bnpl?.pre_approved_value ?? 0) - (projectResponse.value?.bnpl?.min_entry_value ?? 0)) / (projectResponse.value?.bnpl?.max_installments ?? 0)
}

function resetValues() {
  toggleAttentionModal.value = false
  attentionCheckbox.value = false
}

const showMessage = computed(() => {
  return (projectResponse.value?.bnpl?.decision_pre_analysis === analysisStatusBNPL.approved_without_co_obligation && isEnabledToBNPL.value === false)
})

const showResultInterest = computed(() => {
  return projectResponse.value?.person_type === 'PJ' || (!isEnabledToBNPL.value && !(projectResponse.value?.bnpl?.decision_pre_analysis === analysisStatusBNPL.approved_without_co_obligation))
})

const showBNPL = computed(() => {
  return !(projectResponse.value?.person_type === 'PJ') && ((projectResponse.value?.person_type === 'PF' && (!isEnabledToBNPL.value && (projectResponse.value?.bnpl?.decision_pre_analysis === analysisStatusBNPL.approved_without_co_obligation))) || isEnabledToBNPL.value)
})

const showBanner = computed(() => {
  return projectResponse?.value?.person_type === 'PF' && isEnabledToBNPL.value && (projectResponse?.value?.bnpl?.decision_pre_analysis === analysisStatusBNPL.approved_without_co_obligation || projectResponse?.value?.bnpl?.decision_pre_analysis === analysisStatusBNPL.approved_with_co_obligation)
})

const FIRST_PART_TEXT = 1
const SECOND_PART_TEXT = 0
</script>

<template>
  <NavigationHeaderBar stage="simulation" :title="projectResponse?.project_name" />
  <div class="result_page">
    <div v-if="loading" class="container">
      <div class="my-4 card-container flex flex-col justify-between w-full">
        <span class="loader-project" />
      </div>
    </div>
    <div v-if="!loading" class="container">
      <SolModal
        id="edit-modal"
        title="Atualizar simulação"
        :is-open="modal"
        action-secondary-text="Cancelar"
        @close="closeModal"
        @action:secondary="closeModal"
      >
        <div v-if="projectResponse?.pre_approved_value" class="input-options">
          Para seguir com o financiamento é necessário uma entrada de {{ formatMoney(projectValue - projectResponse?.pre_approved_value) }}, deseja continuar?
        </div>
        <template #action-primary>
          <SolButton
            id="btn-simulate"
            size="medium"
            variant="primary"
            @click="updateSimulation()"
          >
            Aprovar nova entrada
          </SolButton>
        </template>
      </SolModal>

      <!-- result card -->
      <div v-if="projectResponse" class="result-card card-container">
        <div class="font-bold mb-3">
          {{ t('simulation.result.data.title') }}
        </div>

        <div class="subtitle grid grid-cols-2 md:system:grid-cols-4 gap-4 md:system:gap-8">
          <div>
            <div>{{ t('simulation.result.data.name') }}</div>
            <div class="text-brand-primary-pure">
              {{ conventionalNameFormat(projectResponse?.project_name) }}
            </div>
          </div>

          <div>
            <div>
              <span v-if="projectResponse?.person_type === 'PF'">
                {{ t('simulation.result.data.type.cpf') }}
              </span>
              <span v-else>
                {{ t('simulation.result.data.type.cnpj') }}
              </span>
              {{ t('simulation.result.data.client') }}
            </div>
            <div class="text-brand-primary-pure">
              {{ formatDocument(projectResponse?.document) }}
            </div>
          </div>

          <div>
            <div>{{ t('simulation.result.data.value') }}</div>
            <div class="text-brand-primary-pure">
              {{ formatMoney(projectResponse?.project_value) }}
            </div>
          </div>

          <div>
            <div>
              {{ t('simulation.result.data.power') }}
              <span class="hidden md:system:inline">
                {{ t('simulation.result.data.system') }}
              </span>
              {{ t('simulation.result.data.measure') }}
            </div>
            <div class="text-brand-primary-pure">
              {{ projectResponse?.system_power.toFixed(2) }}
            </div>
          </div>
        </div>
      </div>

      <!-- banner card -->
      <div
        v-if="showBanner"
        class="result-card card-container card-banner"
      >
        <div>
          <div class="title">
            {{ t('simulation.result.bnpl.banner.title', FIRST_PART_TEXT) }}
            <span class="text-brand-secondary-medium">
              {{ t('simulation.result.bnpl.banner.emphasis') }}:
            </span>
            {{ t('simulation.result.bnpl.banner.title', SECOND_PART_TEXT) }}
          </div>

          <div class="subtitle">
            {{ t('simulation.result.bnpl.banner.description') }}
          </div>
        </div>
      </div>

      <!-- options card end values -->
      <div v-if="projectResponse" class="w-full">
        <div>
          <!-- Messages -->
          <div
            v-if="((clientStatus === 'reproved' && clientStatusBNPL === 'reproved' && !analyzingSimulation) || analyzingSimulation) || noResultMessage"
            class="result-card card-container"
          >
            <!-- Rejected customer -->
            <div
              v-if="clientStatus === 'reproved' && clientStatusBNPL === 'reproved' && !analyzingSimulation"
              class="flex flex-col items-center"
            >
              <img
                src="/images/customer-rejected.svg"
                :alt="t('simulation.result.bnpl.customerRejected.altImg')"
                class="object-cover w-full h-full rounded-lg w-[163px]"
              >
              <h2 class="flex gap-1 items-center justify-center mt-4">
                <span class="text-[24px] font-highlight">{{ t('simulation.result.bnpl.customerRejected.title') }}</span>
              </h2>
              <p class="text-[16px] text-neutral-low-light pt-2 pb-6 text-center">
                {{ t('simulation.result.bnpl.customerRejected.description') }}
              </p>
            </div>
            <!-- Close Rejected customer -->

            <!-- Message no results -->
            <div v-if="noResultMessage">
              <SolEmptyState
                id="message-results"
                class="w-full max-w-full"
                :title="t('simulation.result.bnpl.messages.noResults.title')"
                :subtitle="t('simulation.result.bnpl.messages.noResults.description')"
                variant="empty-list"
              />
              <div class="flex flex-row justify-center gap-2">
                <SolButton
                  id="no-results"
                  variant="primary"
                  @click="mountResponse"
                >
                  {{ t('simulation.result.bnpl.messages.noResults.textButton') }}
                </SolButton>
              </div>
            </div>
            <!-- Close Message no results -->

            <!-- Message Analyzing Simulation -->
            <div v-if="analyzingSimulation">
              <SolEmptyState
                id="analyzing-simulation"
                class="w-full max-w-full"
                :title="t('simulation.result.bnpl.messages.analyzingSimulation.title')"
                :subtitle="t('simulation.result.bnpl.messages.analyzingSimulation.description')"
                variant="search"
              />

              <div class="flex flex-row justify-center gap-2">
                <SolButton
                  id="list"
                  variant="secondary"
                  @click="openSimulationList()"
                >
                  {{ t('simulation.result.bnpl.messages.analyzingSimulation.textListButton') }}
                </SolButton>
                <SolButton
                  id="new-simulation"
                  variant="primary"
                  @click="openNewSimulation()"
                >
                  {{ t('simulation.result.bnpl.messages.analyzingSimulation.textNewSimulationButton') }}
                </SolButton>
              </div>
            </div>
            <!-- Close Message Analyzing Simulation -->
          </div>

          <!-- Financing and installments -->
          <div
            v-if="!analyzingSimulation && !(clientStatus === 'reproved' && clientStatusBNPL === 'reproved') && !noResultMessage"
            class="flex flex-wrap md:system:flex-nowrap gap-0 md:system:gap-8"
          >
            <!-- Financing -->
            <div class="result-card card-container">
              <div class="w-full md:system:pt-2xs flex flex-col h-full">
                <div class="result-content h-full">
                  <div class="flex-1 flex flex-col">
                    <div>
                      <div class="title">
                        {{ t('simulation.result.financing.title') }}
                      </div>
                      <div class="subtitle mb-4xs mt-2">
                        {{ t('simulation.result.financing.subtitle') }}
                      </div>
                    </div>

                    <SolAlert
                      v-if="clientStatus === 'pending'"
                      id="message-pending-feedback"
                      class="mb-4xs md:site:mb-10"
                      title="Cliente pré-aprovado com limitação de valores"
                      feedback="warning"
                    >
                      Devido às nossas políticas de crédito, o valor pré-aprovado foi inferior ao solicitado.
                    </SolAlert>
                    <SolAlert
                      v-if="clientStatus === 'pending_guarantor' && documentIsCpf"
                      id="message-pending-guarantor-feedback"
                      class="mb-4xs
                      md:site:mb-10"
                      title="Necessário avalista"
                      feedback="warning"
                    >
                      Será necessário incluir avalista, mais para frente, para seguir com a análise deste projeto.
                    </SolAlert>

                    <SolAlert
                      v-if="clientStatus === 'pending_risk_RS'"
                      id="title"
                      class="mb-4xs md:site:mb-10"
                      :title="$t('simulation.info_risk.RS_title')"
                      feedback="warning"
                    >
                      <div>
                        <p>{{ $t('simulation.info_risk.RS_description_conditions') }}</p>
                        <ul class="list-disc list-inside">
                          <li> {{ $t('simulation.info_risk.RS_description_grace') }} </li>
                          <li> {{ $t('simulation.info_risk.RS_description_eligible_area') }} </li>
                        </ul>
                      </div>
                    </SolAlert>

                    <SolAlert
                      v-if="clientStatus === 'essential_profile'"
                      id="title"
                      class="mb-4xs md:site:mb-10"
                      :title="$t('simulation.info_risk.essential_profile_title')"
                      feedback="warning"
                    >
                      <div>
                        <p>{{ $t('simulation.info_risk.essential_profile_description') }}</p>
                      </div>
                    </SolAlert>

                    <div class="flex flex-col w-full h-full">
                      <div class="h-full">
                        <div
                          v-if="clientApproved"
                          class="approved-value-container flex-1"
                          :pending="clientStatus === 'pending' || clientStatus === 'pending_guarantor'"
                        >
                          <p>{{ t('simulation.result.financing.pre_approved_value') }}</p>
                          <span class="text-xl font-bold">{{ formatMoney(projectResponse?.pre_approved_value) }}</span>

                          <ul class="flex flex-col w-full">
                            <li class="financial-item-colored " :pending="clientStatus === 'pending' || clientStatus === 'pending_guarantor'">
                              <span class="text-3xs md:system:text-2xs">{{ t('simulation.result.financing.max_installments') }}</span>
                              <span class="text-3xs md:system:text-2xs">
                                <span class="mr-1">{{ t('simulation.result.financing.to') }}</span>
                                <span>{{ projectResponse?.max_installments }}x</span>
                              </span>
                            </li>
                            <li class="financial-item-colored" :pending="clientStatus === 'pending' || clientStatus === 'pending_guarantor'">
                              <span class="text-3xs md:system:text-2xs">
                                {{ t('simulation.result.financing.max_grace_period') }}
                              </span>
                              <span class="text-3xs md:system:text-2xs">
                                {{ t('simulation.result.financing.to') }}
                                {{ formatMonth(projectResponse?.max_grace_period) }}
                                {{ t('simulation.result.financing.months') }}
                              </span>
                            </li>

                            <li class="financial-item-colored" :pending="clientStatus === 'pending' || clientStatus === 'pending_guarantor'">
                              <span class="text-3xs md:system:text-2xs">
                                {{ t('simulation.result.financing.down_payment') }}
                              </span>
                              <span class="text-3xs md:system:text-2xs">
                                {{ formatMoney(projectResponse?.down_payment) }}
                              </span>
                            </li>

                            <li class="financial-item-colored" :pending="clientStatus === 'pending' || clientStatus === 'pending_guarantor'">
                              <span class="text-3xs md:system:text-2xs">
                                {{ t('simulation.result.financing.project_value') }}
                              </span>
                              <span class="text-3xs md:system:text-2xs">
                                {{ formatMoney(projectResponse?.project_value - projectResponse?.down_payment) }}
                              </span>
                            </li>

                            <li class="financial-item-transparent" :pending="clientStatus === 'pending' || clientStatus === 'pending_guarantor'">
                              <span class="text-3xs md:system:text-2xs">
                                {{ t('simulation.result.financing.kit') }}
                              </span>
                              <span class="text-3xs md:system:text-2xs">
                                {{ t('simulation.result.financing.store') }}
                              </span>
                            </li>
                          </ul>
                        </div>

                        <div
                          v-else
                          class="flex flex-col items-center h-full justify-center"
                        >
                          <img
                            src="/images/customer-rejected.svg"
                            :alt="t('simulation.result.bnpl.customerRejected.altImg')"
                            class="w-full rounded-lg w-[193px]"
                          >
                          <h2 class="flex gap-1 items-center justify-center mt-4">
                            <span class="text-[24px] font-highlight">{{ t('simulation.result.bnpl.customerRejected.title') }}</span>
                          </h2>
                          <p class="text-[16px] text-neutral-low-light pt-2 pb-6 text-center">
                            {{ denyMessage }}
                          </p>
                        </div>
                      </div>

                      <div
                        v-if="clientApproved"
                        class="w-full flex justify-end"
                      >
                        <SolButton
                          v-if="clientStatus === 'approved' || clientStatus === 'pending_guarantor' || clientStatus === 'essential_profile'"
                          id="follow-financing"
                          variant="primary"
                          class="mt-6"
                          @click="submitResult()"
                        >
                          {{ t('simulation.result.bnpl.buttons.textFollowFinancing') }}
                        </SolButton>

                        <SolButton
                          v-if="clientStatus === 'pending'"
                          id="go-pending"
                          class="mt-6"
                          @click="openModal()"
                        >
                          {{ nextButtonTitle[clientStatus] }}
                        </SolButton>

                        <SolButton
                          v-if="!clientApproved"
                          id="go"
                          class="mt-6"
                          @click="goNewSimulation()"
                        >
                          {{ t('simulation.new') }}
                        </SolButton>

                        <SolButton
                          v-if="clientStatus === 'pending_risk_RS'"
                          id="go"
                          class="mt-6"
                          @click="submitResult()"
                        >
                          {{ t('simulation.next') }}
                        </SolButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- BNPL -->
            <div
              v-if="isEnabledToBNPLInterest"
              class="result-card card-container flex"
              :class="`${(projectResponse.person_type === 'PJ' || !isEnabledToBNPL) ? '!align-center !justify-center' : ''}`"
            >
              <div class="w-full md:system:py-2xs flex flex-col">
                <div class="result-content">
                  <!-- Period Payment -->
                  <SimulationResultInterest
                    v-if="showResultInterest"
                    @action-interest="actionInterest()"
                  />
                  <!-- Close Period Payment -->

                  <!-- BNPL -->
                  <div
                    v-if="showBNPL"
                    class="flex-1"
                  >
                    <div class="title">
                      <div>{{ t('simulation.result.bnpl.title') }}</div>
                      <SolTag
                        id="result-bpnl"
                        class="tag-secondary m-0"
                        :text="
                          showMessage
                            ? t('simulation.result.bnpl.shortly.flag')
                            : t('simulation.result.bnpl.new')
                        "
                        variant="fill"
                        size="small"
                      />
                    </div>
                    <div class="subtitle mb-4xs mt-1">
                      <div class="h-0">
                        {{ showMessage ? t('simulation.result.bnpl.subtitleTwo') : t('simulation.result.bnpl.subtitle') }}
                      </div>
                      <div style="visibility: hidden">
                        {{ t('simulation.result.financing.subtitle') }}
                      </div>
                    </div>

                    <SolAlert
                      v-if="!(projectResponse?.bnpl?.decision_pre_analysis === analysisStatusBNPL.approved_without_co_obligation)"
                      id="restrictions-title-feedback"
                      class="mb-4"
                      :title="t('simulation.result.bnpl.alerts.responsibility.title')"
                      feedback="warning"
                      :action="t('simulation.result.bnpl.alerts.responsibility.action')"
                      @click="actionKnowMore"
                    >
                      {{ t('simulation.result.bnpl.alerts.responsibility.description') }}
                    </SolAlert>

                    <div class="flex flex-col w-full">
                      <div :class="`approved-value-container ${showMessage ? '!bg-neutral-high-light' : 'approved-value-container-bnpl'} flex-1`" :pending="clientStatus === 'pending' || clientStatus === 'pending_guarantor'">
                        <p>
                          {{ showMessage ? 'Confira as condições que seriam pré-aprovadas' : t('simulation.result.bnpl.pre_approved_value') }}
                        </p>
                        <span class="text-xl font-bold">{{ formatMoney(projectResponse?.bnpl?.pre_approved_value) }}</span>

                        <ul class="flex flex-col w-full">
                          <li class="financial-item-colored" :pending="clientStatus === 'pending' || clientStatus === 'pending_guarantor'">
                            <span class="text-3xs md:system:text-2xs">{{ t('simulation.result.bnpl.max_installments') }}</span>
                            <span class="text-3xs md:system:text-2xs">
                              <span class="mx-1">{{ t('simulation.result.bnpl.to') }}</span>
                              <span>{{ projectResponse?.bnpl?.max_installments }}x</span>
                            </span>
                          </li>
                          <li class="financial-item-colored" :pending="clientStatus === 'pending' || clientStatus === 'pending_guarantor'">
                            <span class="text-3xs md:system:text-2xs">
                              {{ t('simulation.result.bnpl.max_grace_period') }}
                            </span>
                            <span class="text-3xs md:system:text-2xs">
                              {{ t('simulation.result.bnpl.without_period') }}
                            </span>
                          </li>

                          <li class="financial-item-colored" :pending="clientStatus === 'pending' || clientStatus === 'pending_guarantor'">
                            <span class="text-3xs md:system:text-2xs">
                              {{ t('simulation.result.bnpl.down_payment') }}
                            </span>
                            <span class="text-3xs md:system:text-2xs">
                              {{ formatMoney(projectResponse?.bnpl?.min_entry_value) }}
                            </span>
                          </li>

                          <li class="financial-item-colored" :pending="clientStatus === 'pending' || clientStatus === 'pending_guarantor'">
                            <span class="text-3xs md:system:text-2xs">
                              {{ t('simulation.result.bnpl.project_value') }}
                            </span>
                            <span class="text-3xs md:system:text-2xs">
                              <span>{{ projectResponse?.bnpl?.max_installments }}x</span>
                              <span class="mx-1">{{ t('simulation.result.bnpl.of') }}</span>
                              <span>{{ formatMoney(formattedValueInstallmentsBNPL()) }}</span>
                            </span>
                          </li>

                          <li class="financial-item-transparent" :pending="clientStatus === 'pending' || clientStatus === 'pending_guarantor'">
                            <span class="text-3xs md:system:text-2xs">
                              {{ t('simulation.result.bnpl.kit') }}
                            </span>
                            <span class="text-3xs md:system:text-2xs">
                              {{ t('simulation.result.bnpl.store') }}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="projectResponse.person_type === 'PF'"
                class="w-full flex justify-end mt-6 md:system:mt-0"
              >
                <SolButton
                  v-if="
                    isEnabledToBNPL && (projectResponse?.bnpl?.decision_pre_analysis === analysisStatusBNPL.approved_without_co_obligation || projectResponse?.bnpl?.decision_pre_analysis === analysisStatusBNPL.approved_with_co_obligation)"
                  id="follow-installment"
                  variant="primary"
                  @click="projectResponse?.bnpl?.decision_pre_analysis === analysisStatusBNPL.approved_without_co_obligation ? redirectBNPL() : toggleAttentionModal = true"
                >
                  {{ t('simulation.result.bnpl.buttons.textFollowInstallment') }}
                  <template #icon:right>
                    <IconOpenInNew />
                  </template>
                </SolButton>

                <SolButton
                  v-if="(!isEnabledToBNPL && (projectResponse?.bnpl?.decision_pre_analysis === analysisStatusBNPL.approved_without_co_obligation))"
                  id="interest"
                  variant="secondary"
                  @click="actionInterest()"
                >
                  {{ t('simulation.result.bnpl.buttons.textInterest') }}
                </SolButton>
              </div>
            </div>
          </div>
          <!-- Close Financing and installments -->
        </div>

        <SolDivider thickness="x-small" />

        <div class="button-container">
          <SolButton
            id="back-button"
            variant="secondary"
            @click="openSimulationList()"
          >
            {{ t('buttons.back') }}
          </SolButton>
        </div>
      </div>
    </div>
  </div>
  <SolOnboarding
    id="subscription_energy_modal"
    :is-open="subscriptionEnergyModal"
    :sliders="sliders"
    :final-button-text="$t('subscriptionEnergy.recommend_customer')"
    @action:finish="recommendCustomerPage(), track('simulation-result_modal_indicar-cliente', { trigger: 'Clicar em indicar cliente pelo modal' })"
    @update:is-open="subscriptionEnergyModal = $event"
  />

  <!-- Modais -->
  <!-- Important -->
  <SolModal
    id="important-modal"
    :is-open="toggleImportantModal"
    :size="{
      desktop: 'medium',
      mobile: 'bottom-sheet',
    }"
    :title="t('simulation.result.bnpl.modals.important.title')"
    :action-primary-text="t('simulation.result.bnpl.modals.important.textButton')"
    @action:primary="toggleImportantModal = false"
    @close="toggleImportantModal = false"
  >
    <p> {{ t('simulation.result.bnpl.modals.important.description') }} </p>
  </SolModal>
  <!-- Close Important -->

  <!-- Attention -->
  <SolModal
    id="attention-modal"
    :is-open="toggleAttentionModal"
    :size="{
      desktop: 'medium',
      mobile: 'bottom-sheet',
    }"
    :title="t('simulation.result.bnpl.modals.attention.title')"
    :action-secondary-text="t('simulation.result.bnpl.modals.attention.textCancelButton')"
    @action:secondary="resetValues()"
    @close="resetValues()"
  >
    <p> {{ t('simulation.result.bnpl.modals.attention.description') }} </p>

    <div>
      <SolCheckbox
        id="attention-text-checkbox"
        v-model="attentionCheckbox"
        class="mt-2"
        name="attention-text-checkbox"
        :label="t('simulation.result.bnpl.modals.attention.textCheckbox')"
        value="attentionOK"
        @change="attentionCheckbox = !attentionCheckbox"
      />
    </div>

    <template #action-primary>
      <SolButton
        id="btn-financing"
        size="medium"
        variant="primary"
        :disabled="!attentionCheckbox"
        @click="redirectBNPL"
      >
        {{ t('simulation.result.bnpl.modals.attention.textFollowInstallmentButton') }}

        <template #icon:right>
          <IconOpenInNew />
        </template>
      </SolButton>
    </template>
  </SolModal>
  <!-- Close Attention -->

  <!-- Interest -->
  <SolModal
    id="interest-modal"
    :is-open="toggleInterestModal"
    :size="{
      desktop: 'medium',
      mobile: 'bottom-sheet',
    }"
    :title="t('simulation.result.bnpl.modals.interest.title')"
    :action-primary-text="t('simulation.result.bnpl.modals.interest.textButton')"
    @action:primary="toggleInterestModal = false"
    @close="toggleInterestModal = false"
  >
    <div class="flex mb-6 gap-8">
      <div class="flex flex-col items-center justify-center gap-3">
        <img src="/images/percent.svg" :alt="t('simulation.result.bnpl.modals.interest.altImgOption1')">
        <span class="text-center font-highlight text-neutral-low-medium">{{ t('simulation.result.bnpl.modals.interest.option1') }}</span>
      </div>
      <div class="flex flex-col items-center justify-center gap-3">
        <img src="/images/installments.svg" :alt="t('simulation.result.bnpl.modals.interest.altImgOption2')">
        <span class="text-center font-highlight text-neutral-low-medium">{{ t('simulation.result.bnpl.modals.interest.option2') }}</span>
      </div>
      <div class="flex flex-col items-center justify-center gap-3">
        <img src="/images/solar-plate.svg" :alt="t('simulation.result.bnpl.modals.interest.altImgOption3')">
        <span class="text-center font-highlight text-neutral-low-medium">{{ t('simulation.result.bnpl.modals.interest.option3') }}</span>
      </div>
    </div>
    <p class="text-center text-neutral-low-medium">
      {{ t('simulation.result.bnpl.modals.interest.description') }}
    </p>
  </SolModal>
  <!-- Close Interest -->
  <!-- Close Modais -->
</template>

<style lang="scss">
.result_page {
.container {
  @apply flex flex-col items-start pb-micro px-micro mt-6 md:site:mt-0;
  @apply md:site:p-12;

  .result-card {
    @apply mt-0 mb-8 py-6 flex flex-col justify-between w-full ;
  }

  .card-banner {
    background-image: url('/images/simulation-banner.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @apply px-5 py-3;

    > div {
      background-color: rgba(0, 0, 0, 0.8);
      @apply px-4 py-2 mx-1 rounded-md;

    .title {
      font-size: 20px;
      @apply flex justify-start flex-wrap gap-1 text-xs font-bold text-neutral-high-light;
    }

    .subtitle {
      font-size: 14px;
      @apply text-neutral-high-light mt-1;
    }
  }
  }

  .button-container {
    @apply flex flex-col w-full gap-4 mt-2xs justify-between;

    > .button-result {
      @apply w-full;

      @screen md:site {
        @apply self-end w-auto;
      }
    }

    @screen md:site {
      @apply flex-row;
    }

  }
  .title {
    @apply flex justify-start items-center gap-2;
    @apply fonts-body-large-bold;
    @apply text-neutral-low-dark;
  }

  .subtitle {
    @apply fonts-subtitle-small text-neutral-low-light;
  }
}
.result-content{
  @apply flex flex-wrap gap-6;
  @apply md:site:flex-nowrap;
}

#tag-result-bpnl {
  @apply bg-brand-secondary-medium;
}

.not-found{
  height: 96px;
  width: 96px;
}

.loader-project {
  @apply h-96 rounded-lg mt-5;
  @apply top-0 right-0 bottom-0 left-0 z-50;
  background: rgba(#fff, 0.9)
    url(https://cdn.solfacil.com.br/assets/img/loading-for-legacy.gif) no-repeat
    center center;
}

.financial-item {
  @apply flex justify-between py-nano border-b-neutral-high-medium border-b-1;
}

.financial-item-colored {
  @apply flex justify-between py-nano border-b-brand-secondary-pure border-b-1;
}

.financial-item-transparent {
  @apply flex justify-between py-nano border-transparent border-b-1;
}

.financial-item-colored[pending="true"] {
  @apply flex justify-between py-nano border-b-feedback-warning-dark border-b-1;
}

.financial-item-colored[pending_guarantor="true"] {
  @apply flex justify-between py-nano border-b-feedback-warning-dark border-b-1;
}

.input-options{
  @apply flex;
}

.approved-value-container{
  @apply rounded-lg py-2xs px-sm flex flex-col text-center items-center justify-center bg-feedback-positive-light text-feedback-positive-dark w-full;

}

.approved-value-container-bnpl{
  background-color: #f2ffd8
}
.approved-value-container[pending="true"]{
  @apply rounded-lg py-2xs px-sm flex flex-col text-center items-center justify-center;
  @apply bg-feedback-warning-light text-feedback-warning-dark;

}

.installments-container{
  @apply w-full;

  > .sol-accordion-core[open]>.summary{
    @apply bg-neutral-high-pure;
  }

  >.sol-accordion-core[open]>.accordion-content{
    @apply bg-neutral-high-pure;
  }
}

.card-container{
  @apply px-4xs my-6 bg-neutral-high-pure rounded-lg;

  @screen md:site {
    @apply px-12;
  }
}
.result-card--reproved {
  @apply bg-neutral-high-pure mb-2xs rounded-lg;

}

}

.sol-overlay-core {
  @apply z-99;
  .content {
    @apply h-full;

    .wrap {
      @apply h-full w-full;

      .image {
        @apply object-contain h-full mb-quark;
      }
    }

    .slider {
      @apply mb-xs;
    }
  }
}

#button-alert-action-restrictions-title-feedback {
  @apply w-[170px];
}

#empty-state-message-results,
#empty-state-analyzing-simulation {
  h1 {
    font-weight: 500;
    @apply font-highlight text-[24px];
  }
}
</style>

<route lang="yaml">
meta:
  layout: simulation
  </route>

