import type { $Fetch } from 'ohmyfetch'
import { $fetch } from 'ohmyfetch'
import type { NormalizedConnections, RestConnection } from '~/types'
import type { RestConfig } from '~/utils/connections/rest'

const UNAUTHORIZED = 401

export function useApi(config: RestConfig, loading = true) {
  const access_token = useCookie<string>().get('access_token')
  const { rest } = useConnections()
  const configs = generateFetchConfigs(rest, access_token, loading)

  return configs[config]
}

function generateFetchConfigs(connections: NormalizedConnections['rest'], access_token: string, loading: boolean): { [key: string]: $Fetch } {
  const configs: { [key: string]: $Fetch } = {}

  for (const key in connections) {
    const connection = connections[key]
    Object.assign(configs, { [key]: generateFetchConfig(connection, access_token, loading) })
  }

  return configs
}

function generateFetchConfig(connection: RestConnection, access_token: string, loading: boolean): $Fetch {
  return $fetch.create({
    baseURL: connection.url,
    headers: {
      'x-api-key': connection.key ?? '',
      'Accept': 'application/json',
      'Authorization': `Bearer ${access_token}`,
    },
    async onRequest() {
      loading && useLoading().show()
    },
    async onResponse({ response }) {
      useLoading().hide()
      if (response.status === UNAUTHORIZED) {
        await useKeycloak().logout()
        useKeycloak().redirect()
      }
    },
  })
}
