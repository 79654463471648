<script setup lang="ts">
import { SolHeader } from '@solfacil/girassol'
import type { FunctionalComponent, SVGAttributes } from 'vue'
import type { User } from '~/utils/auth/User'
import { useCollapseStore } from '~/store/collapse'
import CoreServices from '~/services/CoreServices'
import IconMoney from '~icons/girassol/attach-money'
import type solfacilPlus from '~/utils/solfacilPlus/solfacilPlus'
import SolfacilPlusService from '~/services/solfacilPlus/SolfacilPlusService'
import type { ResponseModel } from '~/utils/connections/rest/RestConnections'
import { useLevelBlackStore } from '~/store/useLevelBlackStore'
const { setLevelBlack } = useLevelBlackStore()

const store = useCollapseStore()
const route = useRoute()
const userStorage: User = useAppStorage().get('user')
const solfacilPlusServices = new SolfacilPlusService(useApi('solfacilPlus', false))

const user = {
  profile: userStorage?.perfil,
  completeName: userStorage?.nomeCompleto,
}

interface Product {
  id: 'portal' | 'shop' | 'financing' | 'ampera'
  icon?: FunctionalComponent<SVGAttributes, {}>
  title: string
  action?: Function
}

const product = {
  id: 'financing',
  icon: IconMoney,
  title: 'Financiamento',
  action: () => window.location.href = `${origin}/simulation/list`,
} as Product

async function getUserMe() {
  const coreService = new CoreServices(useApi('core'))
  const response: User = await coreService.auth.me()
  useAppStorage().set('user', response)

  return Promise.resolve()
}

interface Responsible {
  cellPhone: string
  noAccountManager: boolean
}

export interface SolfacilPlusResponse {
  level: string
  currentScore: string
  goScore: string
  nextTransitionDate: string
  hasRisk: boolean
}

export interface PartnerHeader {
  partnerId: number
  responsible: Responsible
  solfacilPlus: SolfacilPlusResponse
}

const partner = ref(
  {
    partnerId: userStorage?.parceiro?.id,
    responsible: {
      cellPhone: userStorage?.parceiro?.responsavel?.telefone,
      noAccountManager: userStorage?.parceiro?.responsavel?.semGc,
    },
    solfacilPlus: {
      level: userStorage?.parceiro?.nivelSolfacilMais,
      currentScore: '0',
      goScore: '0',
      nextTransitionDate: '-',
      hasRisk: true,
    },
  } as PartnerHeader,
)

async function getSolfacilPlus() {
  const user = useAppStorage().get('user')

  const partnerId = user?.parceiro?.id

  const response: ResponseModel<solfacilPlus> = await solfacilPlusServices.getPartnerSolfacilPlusInfo(partnerId)

  setLevelBlack(response?.data?.isLevelBlack ?? false)

  const object = {
    level: `${response?.data?.level}`,
    currentScore: `${response?.data?.isLevelBlack
      ? response?.data?.levelBlackStatus.kwGoalMonth
      : response?.data?.score.current}`,
    goScore: `${response?.data?.isLevelBlack
      ? response?.data?.levelBlackStatus.kwReachedMonth
      : response?.data?.score.goalScore}`,
    nextTransitionDate: response?.data?.nextTransitionDate || '',
    hasRisk: response?.data?.downgrade.hasRisk || false,
  }

  partner.value.solfacilPlus = object

  useAppStorage().set('solfacilPlus', object)

  return Promise.resolve()
}

async function validateUserAcceptedTerm() {
  let user = useAppStorage().get('user')

  if (
    user?.parceiro?.acceptedCurrentOperatingAgreement
    && (user.perfil !== 'gerente' && user.perfil !== 'integrador_light')
  ) return

  await getUserMe()
  await getSolfacilPlus()

  user = useAppStorage().get('user')
  if (
    !user?.parceiro?.acceptedCurrentOperatingAgreement
    && (user.perfil === 'gerente' || user.perfil === 'integrador_light')
  ) {
    window.location.href
      = `https://integrador.solfacil.com.br/term?redirect=https://financiamento.solfacil.com.br${route.fullPath}`
  }
}

onMounted(async () => {
  await validateUserAcceptedTerm()
})

const logout = () => {
  useKeycloak().logout()
}

const isOpenSidebar = ref(false)
</script>

<template>
  <main class="default-layout">
    <NavigationSidebar :is-open-sidebar="isOpenSidebar" />
    <div v-if="store.isCollapse" class="main-content">
      <SolHeader
        :user="user"
        :partner="partner"
        :product="product"
        @click-logout="logout"
        @click-menu="isOpenSidebar = !isOpenSidebar"
      />

      <RouterView />
    </div>
    <div v-else class="main-content hidden md:system:block">
      <SolHeader
        :user="user"
        :partner="partner"
        :product="product"
        @click-logout="logout"
        @click-menu="isOpenSidebar = !isOpenSidebar"
      />

      <RouterView />
    </div>
  </main>
</template>

<style lang="scss">
.main-content {
  background-color: #F0F0F0;
}
.default-engine {
  @apply m-lg;
}
.default-layout {
  @apply relative;
  @apply block max-h-screen;
  @screen md:system {
    @apply flex overflow-hidden;
  }
  > .main-content {
    @apply w-full;
    @apply overflow-auto;
    min-height: calc(100vh);
    > .page {
      @apply flex-1;
    }
  }
}
</style>
