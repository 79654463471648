<script lang="ts" setup>
import IconSearch from '~icons/girassol/search'
const emit
  = defineEmits<{
    (e: 'searchFinancingValue', v?: string): void
  }>()

const { t } = useI18n()

const searchFinancing = ref('')
const search = () => {
  emit('searchFinancingValue', searchFinancing.value)
}
</script>

<template>
  <div class="flex gap-3">
    <div class="flex gap-1 flex-col w-full md:system:w-auto">
      <SolInputText
        id="searchFinancing"
        v-model="searchFinancing"
        name="searchFinancing"
        :placeholder="t('financing.boxSearch.placeholder')"
        type="text"
        class="w-full md:site:w-[310px]"
        @keyup.enter="search"
      >
        <template #icon>
          <IconSearch />
        </template>
      </SolInputText>
    </div>
    <SolButton id="buttonSearch" size="medium" @click="search">
      {{ t('financing.boxSearch.button') }}
    </SolButton>
  </div>
</template>

