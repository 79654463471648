export enum Journey {
  OLD = 0,
  MIXED = 1,
  NEW = 2,
}

export enum Formalization {
  REGISTRATION = 'registration',
  DOCUMENTATION = 'documentation',
  RECEIPT_MODEL = 'receipt_model',
  HARDWARE = 'hardware',
  CONTRACT = 'contract',
}
